import ArrowDownSvgComponent from "@assets/svg-components/arrow-down";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";
import ClockSvgComponent from "@assets/svg-components/trips/clock";
import DateSvgComponent from "@assets/svg-components/trips/date";
import {
  formHandleDateArrayChangeByKey,
  formHandleSelectArrayChangeByKey,
  formHandleTimeArrayChangeByKey,
} from "@common/handleChange";
import { getValue } from "@utils/lodash";
import DatePicker from "react-datepicker";

function TripTimeComponent(props: any) {
  const timePickerStyles = {
    // border: 'none', // Remove border
    borderRadius: "0", // Remove border radius
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    transition: "none", // Remove transition effect
    // minWidth: "55px",
    border: "0px",
    width: "100px",
  };
  const { item, index, validator, time_key, label } = props;
  return (
    <div>
      <div className="d-flex">
        <label className="trip-label ms-0">{label}</label>
        <span className="form-label-error_red">*</span>
      </div>
      <div
        className={`d-flex justify-content-between align-items-center mb-3 ${
          validator &&
          validator.current.message(
            label,
            getValue(item, `${time_key}`, ""),
            "required"
          )
            ? "border_red"
            : "border"
        } border-radius__5 cursor-pointer trip-hotel-timepicker`}
        style={{
          position: "relative",
          display: "inline-block",
        }}
      >
        <div className="d-flex align-items-center w-100">
          <ClockSvgComponent />
          <DatePicker
            selected={
              getValue(item, `${time_key}`, "")
                ? new Date(getValue(item, `${time_key}`, ""))
                : null
            }
            // onChange={(date: any) => {
            //   formHandleTimeArrayChangeByKey(
            //     index,
            //     date,
            //     time_key,
            //     props.setItenaries
            //   );
            // }}
            onChange={(date: any) => {
              formHandleTimeArrayChangeByKey(index, date, time_key, props.setItenaries);
              // if (time_key === "checkin_time") {
              //     const checkoutDate = new Date(date);
              //     checkoutDate.setHours(checkoutDate.getHours() + 1); // Set checkout to 1 hour later
              //     formHandleTimeArrayChangeByKey(index, checkoutDate, "checkout_time", props.setItenaries);
              // }
          }}
          
            className={"ms-2"}
            autoComplete={"off"}
            placeholderText={"HH:MM"}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={10}
            timeCaption="Time"
            dateFormat="hh:mm"
            timeFormat="hh:mm"
            customInput={<input style={timePickerStyles} />}
            popperModifiers={{
              preventOverflow: {
                enabled: true,
              },
            }}
            popperPlacement="bottom-start"
            popperClassName="no-hover-border-datepicker"
          />
        </div>
        <div className="">
          {getValue(item, `${time_key}`, "") ? (
            <div
              className=""
              onClick={() =>
                formHandleSelectArrayChangeByKey(
                  index,
                  "",
                  time_key,
                  props.setItenaries
                )
              }
            >
              <CloseSmallSvgComponent />
            </div>
          ) : (
            <ArrowDownSvgComponent color={"gray"} />
          )}
        </div>
      </div>
    </div>
  );
}

export default TripTimeComponent;
