import { getValue } from "@utils/lodash";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";
import { formHandleTextArrayChangeByKey } from "@common/handleChange";
import InputField from "@common/InputRuleFeild";
import TripCityComponent from "../common/trip_city";
import TripDateComponent from "../common/trip_date";
import TripTimeComponent from "../common/trip_time";
import AddSvgComponent from "@assets/svg-components/add";
import SwapSvgComponent from "@assets/svg-components/trips/swap";
import HotelSwapSvgComponent from "@assets/svg-components/trips/hotelswap"
import { toast } from "react-toastify";


function TripCreateHotelSection(props: any) {
  const addHotelSection = () => {
    let obj = {
      checkin_date: "",
      checkin_time: "",
      checkout_date: "",
      checkout_time: "",
      city: "",
      city_name: "",
    };
    props.hotelItenaries.push(obj);
    props.setHotelItenaries([...props.hotelItenaries]);
  };
  const removeHotelSection = (index: number) => {
    let filtered =
      getValue(props, `hotelItenaries.length`, 0) > 0
        ? getValue(props, `hotelItenaries`, []).filter(
            (item: object, i: number) => index !== i
          )
        : [];
    props.setHotelItenaries(filtered);
    props.hideValidation()
  };

  return (
    // px-4 mt-4 w-100 
    <div className="trips-hotels-tab-main-container">
      {/* <div className="trips_header_bg d-flex align-items-center p-2 px-4 mt-4">
        <h6 className="header_text__18 ">Hotel</h6>
      </div> */}
      <div className=""> {/*mt-4*/}
        {getValue(props, `hotelItenaries.length`, 0) > 0 ? (
          getValue(props, `hotelItenaries`, []).map(
            (item: object, index: number) => {
              return (
                <div key={index} className="multiple-dyno-wrap">
                  <div className="d-flex align-items-center justify-content-between w-100 trip_travel_container">
                    <div className="trips_from_container d-flex align-items-center">
                      <div>
                        <div
                          className="d-flex align-items-center position-relative"
                          style={{ flexWrap: "wrap" }}
                        >
                          <TripCityComponent
                            item={item}
                            index={index}
                            validator={props.validator}
                            setItenaries={props.setHotelItenaries}
                            label={"City"}
                            city_key={"city"}
                            city_name_key={"city_name"}
                          />
                          <div className="mr-16 second_swap-checkin-date">
                            <TripDateComponent
                              validator={props.validator}
                              item={item}
                              index={index}
                              date_key={"checkin_date"}
                              setItenaries={props.setHotelItenaries}
                              label={"Checkin Date"}
                              null_date={"checkout_date"}
                            />
                          </div>
                          {/* <div className="swap_item">
                            <SwapSvgComponent />
                          </div> */}
                          <div className="mr-16">
                            <TripTimeComponent
                              label={"Checkin time"}
                              validator={props.validator}
                              item={item}
                              index={index}
                              time_key={"checkin_time"}
                              setItenaries={props.setHotelItenaries}
                            />
                          </div>

                          <div className="mr-16">
                            <TripDateComponent
                              validator={props.validator}
                              item={item}
                              index={index}
                              date_key={"checkout_date"}
                              setItenaries={props.setHotelItenaries}
                              label={"Checkout Date"}
                              minDate={
                                getValue(item, `checkin_date`, "")
                                  ? new Date(getValue(item, `checkin_date`, ""))
                                  : null
                              }
                            />
                          </div>
                          <div>
                            <TripTimeComponent
                              label={"Checkout time"}
                              validator={props.validator}
                              item={item}
                              index={index}
                              time_key={"checkout_time"}
                              setItenaries={props.setHotelItenaries}
                            />
                          </div>
                          <div className="hotel_swap">
                            <HotelSwapSvgComponent />
                          </div>
                        </div>
                        <div className="flight_description_wrap">
                          <InputField
                            inputType="TEXTAREA"
                            placeholder="Add Description (Max 200 Characters)"
                            rows={2}
                            name="description"
                            value={getValue(item, `description`, "")}
                            // onChange={(e: any) =>
                            //   formHandleTextArrayChangeByKey(
                            //     index,
                            //     e,
                            //     props.setHotelItenaries
                            //   )
                            // }
                            onChange={(e: any) => {
                              const { value } = e.target;
                              if (value.length <= 200) {
                                formHandleTextArrayChangeByKey(index, e, props.setHotelItenaries);
                              } else {
                                toast.error("Character Limit should be 200")
                              }
                            }}     
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* {index !== 0 ? null : (
                          <div
                            className="trips_add_icon mt-2"
                            onClick={addHotelSection}
                          >
                            <AddSvgComponent />
                          </div>
                        )} */}

                      <div
                        className=" mt-2"
                        onClick={() => removeHotelSection(index)}
                      >
                        <CloseSmallSvgComponent />
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )
        ) : (
          <>
            <div className="mt-5">
            <div className="trips_header_bg d-flex align-items-center p-2 px-4 mt-4">
              <h6 className="header_text__18 ">Hotel</h6>
            </div>
              <p className="text-center mt-3 small_text__14">
                No Hotels have been included. Kindly select the option below to
                add them.
              </p>
              <div
                className="d-flex justify-content-center align-items-center cursor-pointer  mt-3"
                onClick={addHotelSection}
              >
                <div className="border border-radius__5 d-flex justify-content-center p-2 px-3">
                  <AddSvgComponent size={28} color={"#408dfb"} />
                  <h6 className="header_text__18 ms-2 color_primary">
                    Add Hotels
                  </h6>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {getValue(props, `hotelItenaries.length`, 0) > 0 && (
        <div
          className="custom_field_button_container mt-3"
          onClick={addHotelSection}
        >
          <img
            className="custom_field_button_container_image"
            src={`/images/add_circle.svg`}
          />
          <h6 className="header_text__16 color_primary ms-2">
            {getValue(props, `hotelItenaries.length`, 0) > 0 &&
              "Add another hotel"}
          </h6>
        </div>
      )}
    </div>
  );
}

export default TripCreateHotelSection;
