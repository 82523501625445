import { QueryRequestHelper } from "@common/query-request-helper";
import FormBuilder from "@components/common/FormBuilder";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import {
  createBulkExpenseEntry,
  createExpenseEntry,
  deleteExpenseEntryReceipts,
  getAllExpenseEntryForm,
  getAllExpenseEntryFormEdit,
  getAllExpenseEntryFormEditExpenseType,
  getExpenseFormByExpenseType,
  updateExpenseEntry,
  uploadExpenseEntryReceipts,
} from "@services/expense-entry.service";
import { getValue, setValue } from "@utils/lodash";
import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import "../expense.scss";
import DragAndDropFileComponent from "@common/FileUpload/file-drag-drop";
import { mediaUpload } from "@services/upload.service";
import SimpleReactValidator from "simple-react-validator";
import {
  FormAppendDataRequestHelper,
  FormRequestHelper,
  checkBoolean,
} from "@components/common/FormBuilder/request-helper";
import { toast } from "react-toastify";
import {
  formatText,
  removeNullOrUndefinedProperties,
  sortJSONObjectArray,
} from "@common/text-helpers";
import DocumentImageList from "@common/FileUpload/document-image-list";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import BackSvgComponent from "@assets/svg-components/back";
import DeleteModal from "@common/popup/DeleteModal";
import { autoScanDocument } from "@services/ai.service";
import { getAllExpenseTypes } from "@services/expense-type.service";
import { getAllCurrency } from "@services/currency.service";
import Loader from "@components/common/Loader";
import UploadLoader from "@components/common/UploadLoader";
import { getAllVehicles } from "@services/vehicle.service";
import InputField from "@common/InputRuleFeild";
import { getSpecificMileageAmount } from "@services/mileage.service";
import NoAccessPage from "@components/common/NoAccess";
import CreateReportPopup from "@pages/Client/Reports/create-popup";
import {
  createExpenseReport,
  getAllExpenseFormReport,
} from "@services/expense-reports.service";
import ReportsPopup from "@components/Reports/components/report-add-popup";
import {
  convertCurrentTime,
  convertCurrentTimePerDiem,
  convertDate,
  convertTimeStringToDate,
} from "@common/date-helpers";
import { expenseReportsSubmittedSearchableDropdown } from "@components/common/CustomSearchableDropdown/SearchAPIs";
import { getLookupAPIs } from "@components/common/CustomSearchableDropdown/lookup-apis";
import SingleSelectSearchDropdownReport from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdownReport";
import {
  getAllPerDiemLocation,
  getExpensePerDiemRatesList,
  getSingleExpensePerDiemRatesList,
} from "@services/per-diem.service";
import DatePicker from "react-datepicker";
import ExpensePerDiemRateListPopup from "../Detail/components/ExpensePerDiemRateListPopup";
import ArrowDownSvgComponent from "@assets/svg-components/arrow-down";
import ExpenseSvgComponent from "@assets/svg-components/sidebar/expense";
import ProofImageList from "@common/FileUpload/proof-image-list";
import RichTextEditor from "react-rte";

interface DocumentWithProof {
  document: any;
  proof: any | null;
}


function ClientExpenseCreate(props: any) {
  let navigate = useNavigate();
  let params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  let mainQueryRequest = QueryRequestHelper(urlParams);

  const simpleValidator = useRef(new SimpleReactValidator());
  const simpleValidator1 = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = React.useState(true);
  const [form, setForm] = React.useState<any[]>([]);
  const [formInfo, setFormInfo] = useState({});
  const [expense_report_id, setExpenseReportId] = useState("");
  const [expense_type, setExpenseTypeId] = useState("");

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(urlParams).length === 0 && props.permissionAPITriggered) {
      getData(true);
      getFormData();
      fetchDropdownData();
    }
  }, [props.permissionAPITriggered]);
  useEffect(() => {
    if (Object.keys(urlParams).length > 0 && props.permissionAPITriggered) {
      getData(false);

      if (getValue(urlParams, `expense_report_id`, "")) {
        setExpenseReportId(getValue(urlParams, `expense_report_id`, ""));
      }
      if (getValue(urlParams, `expense_type_id`, "")) {
        setExpenseTypeId(getValue(urlParams, `expense_type_id`, ""));
        // getFormByExpenseType(getValue(urlParams, `expense_type_id`, ""));
      } else {
        setExpenseTypeId("");
        setForm([]);
        setFormInfo({});
      }
      getFormData();
      fetchDropdownData();
    }
  }, [window.location.href, props.permissionAPITriggered]);

  //DropDown Code
  // const dropdownRef = useRef(null);
  const [reportList, setReportList] = useState([]);
  const fetchDropdownData = async () => {
    try {
      setIsLoading(true);
      const lookupAPI = "expense_reports_submitted";
      const lookupID = "";
      let resp = await getLookupAPIs(lookupAPI, lookupID, "");
      if (resp) {
        setReportList(resp);
        setIsLoading(false);
      } else {
        setReportList([]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getData = async (status: boolean) => {
    let payload: any = {};
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(status);
      let resp;
      if (getValue(params, `id`, "")) {
        resp = await getAllExpenseEntryFormEdit(getValue(params, `id`, ""));
      } else {
        resp = await getAllExpenseEntryForm(queryRequest);
      }

      if (resp) {
        setFormInfo(getValue(resp, `data`, {}));
        setExpenseDateEdit(getValue(resp, `data.expense.expense_date`, ""));
        if (getValue(resp, `data.mileage.id`, "")) {
          getVehicleList();
        }
        if (getValue(resp, `data.per_diem.id`, "")) {
          getPerDiemLocationList();
        }
        if (getValue(params, `id`, "")) {
          setExpenseReportId(
            getValue(resp, `data.expense.expense_report.id`, "")
          );
          // setForm(getValue(resp, `data.form_layout`, []));
          let length = getValue(resp, `data.form_layout.length`, 0);
          let array = getValue(resp, `data.form_layout`, []);
          setDocuments(getValue(resp, `data.expense.receipts`, []));
          let expense = getValue(resp, `data.expense`, {});
          // let payload = {
          //   currency_code: getValue(resp, `data.expense.claimed_currency`, ""),
          // };
          // if (getValue(resp, `data.expense.claimed_currency`, "")) {
          //   let queryRequest = QueryRequestHelper(payload);
          //   let currency = await getAllCurrency(queryRequest);
          //   if (currency) {
          //     expense["currency"] = {
          //       id: getValue(currency, `data.data[${0}].id`, ""),
          //     };
          //   }
          // }
          for (let i = 0; i < length; i++) {
            FormAppendDataRequestHelper(array[i], expense);
          }
          let findExpenseType = array.find(
            (item: object) =>
              getValue(item, `field_name`, "") === "expense_type"
          );
          // setExpenseTypeId(getValue(findExpenseType, `field_value`, ""));
          if (getValue(urlParams, `expense_type_id`, "")) {
            getFormByExpenseType(getValue(urlParams, `expense_type_id`, ""));
          }
          if (getValue(findExpenseType, `field_value`, "")) {
            getFormByExpenseType(getValue(findExpenseType, `field_value`, ""));
          }
          setIsLoading(false);
        } else {
          // let builder = getValue(resp, `data.form_layout`, []).map(
          //   (item: any) => ({
          //     ...item,
          //     field_value: checkBoolean(getValue(item, `field_value`, "")),
          //   })
          // );
          // setForm(builder);
          getFormByExpenseType(
            getValue(urlParams, `expense_type_id`, "")
              ? getValue(urlParams, `expense_type_id`, "")
              : expense_type
          );
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [vehicleList, setVehicleList] = useState([]);
  const [vehicleLoading, setVehicleLoading] = useState(false);
  const [vehicle, setVehicle] = useState("");
  const [distance, setDistance] = useState("");
  const getVehicleList = async () => {
    try {
      let payload = {
        page_no: 1,
        page_size: 100,
      };
      setVehicleLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllVehicles(queryRequest);
      if (resp) {
        setVehicleList(
          getValue(resp, `data.vehicles`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `id`, ""),
            name: getValue(item, `vehicle_name`, ""),
            label: getValue(item, `vehicle_name`, ""),
          }))
        );
        setVehicleLoading(false);
      } else {
        setVehicleLoading(false);
      }
    } catch (error) {
      setVehicleLoading(false);
    }
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        let fields = JSON.parse(JSON.stringify(form));
        let payload = FormRequestHelper(fields);
        let requestPayload: any = payload;
        requestPayload["expense_report_id"] = expense_report_id;
        requestPayload["expense_type"] = parseInt(expense_type);
        if (vehicle) {
          requestPayload["mileage"] = {
            vehicle: vehicle,
            distance: parseFloat(distance),
            mileage_unit: getValue(formInfo, `mileage.mileage_unit`, ""),
          };
        }
        if (locationPerDiem) {
          requestPayload["per_diem"] = {
            name: locationPerDiem,
            start_time: convertCurrentTimePerDiem(fromTime),
            end_time: convertCurrentTimePerDiem(toTime),
            duration: duration,
          };
        }
        // requestPayload["vehicle"] = vehicle;
        // requestPayload["distance"] = parseFloat(distance);

        requestPayload["amount"] = requestPayload["personal"]
          ? parseFloat(`-${requestPayload["amount"]}`)
          : requestPayload["amount"];
        requestPayload["personal"] =
          requestPayload["personal"] === "false" ? true : false;
        requestPayload["receipt_ids"] =
          getValue(documents, `length`, 0) > 0
            ? documents.map((item: object) => getValue(item, `id`, ""))
            : [];
          
          if (getValue(documents, `length`, 0) > 0) {
          requestPayload["receipt_proofs"] = documents.reduce((proofs: any[], document: any) => {
            const documentId = getValue(document, 'id', '');
            const associatedProofIds = proofMapping[documentId] || [];
            
            // Create a proof mapping entry for each associated proof
            const documentProofs = associatedProofIds.map(proofId => ({
              receipt_id: documentId,
              proof_id: proofId
            }));
            
            return [...proofs, ...documentProofs];
          }, []);
        } else {
          requestPayload["receipt_proofs"] = [];
        }
          
        let resp;

        if (getValue(params, `id`, "")) {
          resp = await updateExpenseEntry(
            getValue(params, `id`, ""),
            removeNullOrUndefinedProperties(requestPayload)
          );
        } else {
          resp = await createExpenseEntry(
            removeNullOrUndefinedProperties(requestPayload)
          );
        }
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          if (
            !getValue(params, `id`, "") &&
            !getValue(urlParams, `expense_report_id`, "")
          ) {
            navigate(
              `/client/expenses/detail/${getValue(resp, `data.id`, "")}`
            );
          }
          if (!getValue(urlParams, `expense_report_id`, "")) {
            navigate(
              `/client/expenses/detail/${getValue(resp, `data.id`, "")}`
            );
          }
          if (getValue(urlParams, `expense_report_id`, "")) {
            navigate(
              `/client/reports/detail/${getValue(
                urlParams,
                `expense_report_id`,
                ""
              )}`
            );
          }

          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  // const handleSubmitPerDiemBulkExpense = async() => {
  //   const formValid = simpleValidator.current.allValid();
  //   if (!formValid) {
  //     simpleValidator.current.showMessages();
  //     forceUpdate(1);
  //   } else {
  //     try {
  //       setSubmitLoading(true);
  //       let fields = JSON.parse(JSON.stringify(form));
  //       let payload = FormRequestHelper(fields);
  //       let requestPayload: any = payload;
  //       requestPayload["expense_report_id"] = expense_report_id;
  //       requestPayload["expense_type"] = parseInt(expense_type);
  //       if (vehicle) {
  //         requestPayload["mileage"] = {
  //           vehicle: vehicle,
  //           distance: parseFloat(distance),
  //           mileage_unit: getValue(formInfo, `mileage.mileage_unit`, ""),
  //         };
  //       }

  //       requestPayload["amount"] = requestPayload["personal"]
  //         ? parseFloat(`-${requestPayload["amount"]}`)
  //         : requestPayload["amount"];
  //       requestPayload["personal"] =
  //         requestPayload["personal"] === "false" ? true : false;
  //       requestPayload["receipt_ids"] =
  //         getValue(documents, `length`, 0) > 0
  //           ? documents.map((item: object) => getValue(item, `id`, ""))
  //           : [];

  //       const expenseEntries = perDiemExpenseRateList.map((item:any) => ({
  //         ...requestPayload,
  //         expense_date: item.date, // Taking `date` from `perDiemExpenseRateList`
  //         currency_id: item.currency.id,  // Taking `currency_id` from response
  //         amount: item.amount,           // Taking `amount` from response
  //         per_diem: {
  //           name: locationPerDiem,             // Placeholder for Per Diem name
  //           start_time: item.start_time,   // Start time from response
  //           end_time: item.end_time,       // End time from response
  //           duration: item.duration        // Duration from response
  //         },

  //       }));

  //       requestPayload = {
  //         expense_entries: expenseEntries
  //       };

  //       console.log('Request Payload:', requestPayload);

  //       let resp = await createBulkExpenseEntry(requestPayload);

  //       if (resp) {
  //         toast.success(getValue(resp, `message`, ""));
  //         if (
  //           !getValue(params, `id`, "") &&
  //           !getValue(urlParams, `expense_report_id`, "")
  //         ) {
  //           navigate(
  //             `/client/expenses/detail/${getValue(resp, `data.id`, "")}`
  //           );
  //         }
  //         if (!getValue(urlParams, `expense_report_id`, "")) {
  //           navigate(
  //             `/client/expenses/detail/${getValue(resp, `data.id`, "")}`
  //           );
  //         }
  //         if (getValue(urlParams, `expense_report_id`, "")) {
  //           navigate(
  //             `/client/reports/detail/${getValue(
  //               urlParams,
  //               `expense_report_id`,
  //               ""
  //             )}`
  //           );
  //         }

  //         setSubmitLoading(false);
  //       } else {
  //         setSubmitLoading(false);
  //       }

  //     } catch (error) {
  //       setSubmitLoading(false);

  //     }
  //   }
  // }

  // const handleSubmitPerDiemBulkExpense = async () => {
  //   const formValid = simpleValidator.current.allValid();
  //   if (!formValid) {
  //     simpleValidator.current.showMessages();
  //     forceUpdate(1);
  //   } else {
  //     try {
  //       setSubmitLoading(true);
  //       let fields = JSON.parse(JSON.stringify(form));
  //       let payload = FormRequestHelper(fields);
  //       let requestPayload: any = payload;

  //       requestPayload["expense_type"] = parseInt(expense_type);
  //       if (vehicle) {
  //         requestPayload["mileage"] = {
  //           vehicle: vehicle,
  //           distance: parseFloat(distance),
  //           mileage_unit: getValue(formInfo, `mileage.mileage_unit`, ""),
  //         };
  //       }

  //       requestPayload["amount"] = requestPayload["personal"]
  //         ? parseFloat(`-${requestPayload["amount"]}`)
  //         : requestPayload["amount"];
  //       requestPayload["personal"] =
  //         requestPayload["personal"] === "false" ? true : false;
  //       requestPayload["receipt_ids"] =
  //         getValue(documents, `length`, 0) > 0
  //           ? documents.map((item: object) => getValue(item, `id`, ""))
  //           : [];

  //       // Add expense_report_id only if it's not empty
  //       const expenseEntries = perDiemExpenseRateList.map((item: any) => {
  //         let entry: any = {
  //           ...requestPayload,
  //           expense_date: item.date, // Taking `date` from `perDiemExpenseRateList`
  //           currency_id: item.currency.id,  // Taking `currency_id` from response
  //           amount: item.amount,           // Taking `amount` from response
  //           per_diem: {
  //             name: locationPerDiem,       // Placeholder for Per Diem name
  //             start_time: item.start_time, // Start time from response
  //             end_time: item.end_time,     // End time from response
  //             duration: item.duration      // Duration from response
  //           },
  //         };

  //         // Conditionally add expense_report_id if not empty
  //         if (expense_report_id) {
  //           entry["expense_report_id"] = expense_report_id;
  //         }

  //         return entry;
  //       });

  //       // Final request payload
  //       requestPayload = {
  //         expense_entries: expenseEntries
  //       };

  //       console.log('Request Payload:', requestPayload);

  //       let resp = await createBulkExpenseEntry(requestPayload);

  //       if (resp) {
  //         toast.success(getValue(resp, `message`, ""));
  //         if (
  //           !getValue(params, `id`, "") &&
  //           !getValue(urlParams, `expense_report_id`, "")
  //         ) {
  //           navigate(
  //             `/client/expenses/detail/${getValue(resp, `data.id`, "")}`
  //           );
  //         }
  //         if (!getValue(urlParams, `expense_report_id`, "")) {
  //           navigate(
  //             `/client/expenses/detail/${getValue(resp, `data.id`, "")}`
  //           );
  //         }
  //         if (getValue(urlParams, `expense_report_id`, "")) {
  //           navigate(
  //             `/client/reports/detail/${getValue(
  //               urlParams,
  //               `expense_report_id`,
  //               ""
  //             )}`
  //           );
  //         }

  //         setSubmitLoading(false);
  //       } else {
  //         setSubmitLoading(false);
  //       }
  //     } catch (error) {
  //       setSubmitLoading(false);
  //     }
  //   }
  // };

  const handleSubmitPerDiemBulkExpense = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoadingReport(true);
        setSubmitLoading(true);
        let fields = JSON.parse(JSON.stringify(form));
        let payload = FormRequestHelper(fields);
        let requestPayload: any = payload;

        requestPayload["expense_type"] = parseInt(expense_type);
        if (vehicle) {
          requestPayload["mileage"] = {
            vehicle: vehicle,
            distance: parseFloat(distance),
            mileage_unit: getValue(formInfo, `mileage.mileage_unit`, ""),
          };
        }

        requestPayload["amount"] = requestPayload["personal"]
          ? parseFloat(`-${requestPayload["amount"]}`)
          : requestPayload["amount"];
        requestPayload["personal"] =
          requestPayload["personal"] === "false" ? true : false;

        // Conditionally map receipt_ids only if documents exist
        const receiptIds =
          getValue(documents, `length`, 0) > 0
            ? documents.map((item: object) => getValue(item, `id`, ""))
            : [];

        const expenseEntries = perDiemExpenseRateList.map((item: any) => {
          let entry: any = {
            ...requestPayload,
            expense_date: item.date, // Taking `date` from `perDiemExpenseRateList`
            currency_id: item.currency.id, // Taking `currency_id` from response
            amount: item.amount, // Taking `amount` from response
            per_diem: {
              name: locationPerDiem, // Placeholder for Per Diem name
              start_time: item.start_time, // Start time from response
              end_time: item.end_time, // End time from response
              duration: item.duration, // Duration from response
            },
          };

          // Conditionally add expense_report_id if not empty
          if (expense_report_id) {
            entry["expense_report_id"] = expense_report_id;
          }

          // Conditionally add receipt_ids if they are not empty
          if (receiptIds.length > 0) {
            entry["receipt_ids"] = receiptIds;
          }

          return entry;
        });

        // Final request payload
        requestPayload = {
          expense_entries: expenseEntries,
        };

        let resp = await createBulkExpenseEntry(requestPayload);

        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          if (
            !getValue(params, `id`, "") &&
            !getValue(urlParams, `expense_report_id`, "")
          ) {
            navigate(
              `/client/expenses/detail/${getValue(resp, `data.id`, "")}`
            );
          } else {
            navigate(`/client/expenses`);
          }
          if (!getValue(urlParams, `expense_report_id`, "")) {
            navigate(
              `/client/expenses/detail/${getValue(resp, `data.id`, "")}`
            );
          } else {
            navigate(`/client/expenses`);
          }
          if (getValue(urlParams, `expense_report_id`, "")) {
            navigate(
              `/client/reports/detail/${getValue(
                urlParams,
                `expense_report_id`,
                ""
              )}`
            );
          } else {
            navigate(`/client/expenses`);
          }

          // navigate(
          //   `/client/expenses`
          // );

          setSubmitLoadingReport(false);
          setSubmitLoading(false);
        } else {
          setSubmitLoadingReport(false);
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoadingReport(false);
        setSubmitLoading(false);
      }
    }
  };

  const handleSubmitForm = async () => {
    if (getValue(mainInfo, `is_receipt_mandatory`, false)) {
      if (getValue(documents, `length`, 0) > 0) {
        if (getValue(perDiemExpenseRateList, `length`, 0) > 0) {
          handleSubmitPerDiemBulkExpense();
        } else {
          handleSubmit();
        }
      } else {
        toast.error("Document is mandatory");
      }
    } else {
      // handleSubmit();
      if (getValue(perDiemExpenseRateList, `length`, 0) > 0) {
        handleSubmitPerDiemBulkExpense();
      } else {
        handleSubmit();
      }
    }
  };

  const handleHideValidation = () => {
    simpleValidator.current.errorMessages = {};
    simpleValidator.current.fields = {};
    simpleValidator.current.hideMessages();
    forceUpdate(0);
  };
  /* -------------------------------------------------------------------------- */
  /*                               Upload Section old                           */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                               Upload Section                                */
  /* -------------------------------------------------------------------------- */
  const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];
  const [documents, setDocuments] = useState<any>([]);
  const [documentList, setDocumentList] = useState<any>([]);
  const [length, setLength] = useState(0);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadStaticLoading, setUploadStaticLoading] = useState(false);
  const handleUploadDocuments = async (e: any) => {
    if (getValue(params, `id`, "")) {
      let lengthOfUploadedFiles = Array.from(e).length;
      setLength(lengthOfUploadedFiles);
    }
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      try {
        setUploadStaticLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          const newDocument = getValue(resp, 'data', {});
          setDocuments((prevDocuments: any) => [
            ...prevDocuments,
            getValue(resp, `data`, {}),
          ]);
          // setDocumentsProof((prevProofs: any) => [...prevProofs, null]); // Initialize with null or empty string

          setDocumentList((prevDocuments: any) => [
            ...prevDocuments,
            getValue(resp, `data`, {}),
          ]);
          setProofMapping(prev => ({
            ...prev,
            [getValue(newDocument, 'id', '')]: []
          }));
          setCurrentProofIndex(prev => ({
            ...prev,
            [getValue(newDocument, 'id', '')]: 0
          }));

          if (getValue(params, `id`, "")) {
            setUploadLoading(true);
          }
          e = null;
          setUploadStaticLoading(false);
        } else {
          e = null;
          setUploadStaticLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadStaticLoading(false);
      }
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                               Upload Proof Section                                */
  /* -------------------------------------------------------------------------- */
  const fileTypesProof = [
    "PDF",
    "JPEG",
    "XLSX",
    "CSV",
    "XLSM",
    "XLX",
    "JPG",
    "PNG",
  ];
  const [documentsProof, setDocumentsProof] = useState<any>([]);
  const [documentListProof, setDocumentListProof] = useState<any>([]);
  const [lengthProof, setLengthProof] = useState(0);
  const [uploadLoadingProof, setUploadLoadingProof] = useState(false);
  const [uploadStaticLoadingProof, setUploadStaticLoadingProof] =
    useState(false);

    const [proofMapping, setProofMapping] = useState<{ [key: string]: string[] }>({});
    const [currentProofIndex, setCurrentProofIndex] = useState<{ [key: string]: number }>({});
  
  // const handleUploadDocumentsProof = async (e: any) => {
  //   if (getValue(params, `id`, "")) {
  //     let lengthOfUploadedFiles = Array.from(e).length;
  //     setLengthProof(lengthOfUploadedFiles);
  //   }
  //   Array.from(e).forEach(async (item: any) => {
  //     let formData = new FormData();
  //     formData.append("file", item);
  //     try {
  //       setUploadStaticLoadingProof(true);
  //       let resp = await mediaUpload(formData);
  //       if (resp) {
  //         setDocumentsProof((prevDocuments: any) => [
  //           ...prevDocuments,
  //           getValue(resp, `data`, {}),
  //         ]);
  //         setDocumentListProof((prevDocuments: any) => [
  //           ...prevDocuments,
  //           getValue(resp, `data`, {}),
  //         ]);
  //         if (getValue(params, `id`, "")) {
  //           setUploadLoadingProof(true);
  //         }
  //         e = null;
  //         setUploadStaticLoadingProof(false);
  //       } else {
  //         e = null;
  //         setUploadStaticLoadingProof(false);
  //       }
  //     } catch (error) {
  //       e = null;
  //       setUploadStaticLoadingProof(false);
  //     }
  //   });
  // };

  const handleUploadDocumentsProof = async (e: any, documentId: string) => {
    Array.from(e).forEach(async (item: any) => {
        let formData = new FormData();
        formData.append("file", item);
        try {
          setUploadStaticLoadingProof(true);
            let resp = await mediaUpload(formData);
            if (resp) {
              const newProof = getValue(resp, 'data', {});
              setDocumentsProof((prevProofs: any) => [...prevProofs, newProof]);
              // Add proof to mapping
              setProofMapping(prev => ({
                ...prev,
                [documentId]: [...(prev[documentId] || []), getValue(newProof, 'id', '')]
              }));
    
              // setDocumentsProof((prevProofs: any) => {
              //     const newProofs = [...prevProofs];
              //     newProofs[index] = getValue(resp, `data`, {});
              //     return newProofs;
              // });
              // setDocumentListProof((prevDocuments: any) => [
              //   ...prevDocuments,
              //   getValue(resp, `data`, {}),
              // ]);
              if (getValue(params, `id`, "")) {
                setUploadLoadingProof(true);
              }
              e = null;
              setUploadStaticLoadingProof(false);
            } else {
              e = null;
              setUploadStaticLoadingProof(false);
            }
        } catch (error) {
          e = null;
          setUploadStaticLoadingProof(false);
        }
    });
};

// const handleRemoveProof = (id: string, index: number) => {
//   setDocumentsProof((prevProofs: any) => {
//       const newProofs = [...prevProofs];
//       newProofs[index] = null; // or remove the proof based on your logic
//       return newProofs;
//   });
// };

const handleDeleteDocument = (id: string) => {
  if (getValue(params, 'id', '')) {
    setDeleteValue('document');
    setDeleteId(id);
    handleDelete();
  } else {
    setDocuments((prevDocuments: any) =>
      prevDocuments.filter((item: any) => getValue(item, 'id', '') !== id)
    );
    // Remove all associated proofs
    const proofIds = proofMapping[id] || [];
    setDocumentsProof((prevProofs: any) =>
      prevProofs.filter((item: any) => !proofIds.includes(getValue(item, 'id', '')))
    );
    // Clean up mapping
    const newMapping = { ...proofMapping };
    delete newMapping[id];
    setProofMapping(newMapping);
  }
};

const handleDeleteProof = (documentId: string, proofId: string) => {
  if (getValue(params, 'id', '')) {
    setDeleteValue('proof');
    setDeleteId(proofId);
    handleDelete();
  } else {
    setDocumentsProof((prevProofs: any) =>
      prevProofs.filter((item: any) => getValue(item, 'id', '') !== proofId)
    );
    // Update mapping
    setProofMapping(prev => ({
      ...prev,
      [documentId]: prev[documentId].filter((id: string) => id !== proofId)
    }));
  }
};

const handleNextProof = (documentId: string) => {
  const proofs = proofMapping[documentId] || [];
  setCurrentProofIndex(prev => ({
    ...prev,
    [documentId]: Math.min((prev[documentId] || 0) + 1, proofs.length - 1)
  }));
};

const handlePrevProof = (documentId: string) => {
  setCurrentProofIndex(prev => ({
    ...prev,
    [documentId]: Math.max((prev[documentId] || 0) - 1, 0)
  }));
};


// console.log(documentListProof, "===documentListProof===>")
  useEffect(() => {
    if (
      length > 0 &&
      getValue(documentList, `length`, 0) > 0 &&
      getValue(documentList, `length`, 0) === length
    ) {
      uploadFiles();
    } else {
      setLength(0);
    }
  }, [length > 0 && getValue(documentList, `length`, 0) === length]);

  const uploadFiles = async () => {
    try {
      let payload = {};
      let requestPayload: any = payload;
      requestPayload["receipt_ids"] =
        getValue(documents, `length`, 0) > 0
          ? documents.map((item: object) => getValue(item, `id`, ""))
          : [];
      if (getValue(documents, `length`, 0) > 0) {
        requestPayload["receipt_proofs"] = documents.reduce((proofs: any[], document: any) => {
          const documentId = getValue(document, 'id', '');
          const associatedProofIds = proofMapping[documentId] || [];
          
          // Create a proof mapping entry for each associated proof
          const documentProofs = associatedProofIds.map(proofId => ({
            receipt_id: documentId,
            proof_id: proofId
          }));
          
          return [...proofs, ...documentProofs];
        }, []);
      } else {
        requestPayload["receipt_proofs"] = [];
      }
      let resp = await uploadExpenseEntryReceipts(
        getValue(params, `id`, ""),
        removeNullOrUndefinedProperties(requestPayload)
      );
      if (resp) {
        setUploadLoading(false);
        setLength(0);
        setDocumentList([]);
        toast.success(getValue(resp, `message`, ""));
        if (getValue(params, `id`, "")) {
          getData(false);
        }
      } else {
        setLength(0);
        setDocumentList([]);
        setUploadLoading(false);
      }
    } catch (error) {
      setLength(0);
      setDocumentList([]);
      setUploadLoading(false);
    }
  };
  /* ------------------------------- Delete Section -------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  // const handleDeleteFun = (id: string, value: string) => {
  //   if (getValue(params, `id`, "")) {
  //     setDeleteValue(value);
  //     setDeleteId(id);
  //     handleDelete();
  //   } else {
  //     setDocuments((prevDocuments: any) =>
  //       prevDocuments.filter((item: any) => getValue(item, `id`, "") !== id)
  //     );
  //   }
  // };
  const handleDeleteFun = (id: string, value: string) => {
    if (getValue(params, `id`, "")) {
        setDeleteValue(value);
        setDeleteId(id);
        handleDelete();
    } else {
        // Remove the document
        setDocuments((prevDocuments: any) => {
            const updatedDocuments = prevDocuments.filter((item: any) => getValue(item, `id`, "") !== id);
            // Reset the corresponding proof
            const index = prevDocuments.findIndex((item: any) => getValue(item, `id`, "") === id);
            if (index !== -1) {
                setDocumentsProof((prevProofs: any) => {
                    const newProofs = [...prevProofs];
                    newProofs[index] = null; // Reset the proof for the removed document
                    return newProofs;
                });
            }
            return updatedDocuments;
        });
    }
};
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };
  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteExpenseEntryReceipts(
        getValue(params, `id`, ""),
        deleteId
      );
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData(false);
      }
    } catch (error) {}
  };
  const [scanLoading, setScanLoading] = useState(false);
  const handleScanDoc = async (obj: object) => {
    try {
      let docpayload = {
        document_id: getValue(obj, `id`, ""),
        hash: getValue(obj, `hash`, ""),
        filePath: getValue(obj, `url`, ""),
      };
      setScanLoading(true);
      let docqueryRequest = QueryRequestHelper(docpayload);
      let response = await autoScanDocument(docqueryRequest);
      if (response) {
        //----------- Expense Type --------------//
        let payload = {
          expense_type_name: getValue(response, `data.expense_category`, ""),
        };
        let queryRequest = QueryRequestHelper(payload);
        let expenseType = await getAllExpenseTypes(queryRequest);
        handleExpenseType(getValue(expenseType, `data.data[${0}].id`, ""));
        // setExpenseTypeId(getValue(expenseType, `data.data[${0}].id`, ""));
        getFormByExpenseTypeAfterScan(
          getValue(expenseType, `data.data[${0}].id`, ""),
          docqueryRequest,
          expenseType
        );

        setScanLoading(false);
      } else {
        setScanLoading(false);
      }
    } catch (error) {
      setScanLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Upload Section Old End                       */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                               Upload Section new                           */
  /* -------------------------------------------------------------------------- */

  const getFormByExpenseTypeAfterScan = async (
    id: string,
    docqueryRequest: any,
    expenseType: any
  ) => {
    if (id) {
      setForm([]);
      try {
        let payload = {
          expense_type_id: id,
        };
        let queryRequest = QueryRequestHelper(payload);
        let resp;
        setFormLoading(true);
        if (getValue(params, `id`, "")) {
          resp = await getAllExpenseEntryFormEditExpenseType(
            getValue(params, `id`, ""),
            queryRequest
          );
        } else {
          resp = await getExpenseFormByExpenseType(queryRequest);
        }
        if (resp) {
          setMainInfo(getValue(resp, `data`, {}));
          if (getValue(params, `id`, "")) {
            setExpenseReportId(
              getValue(resp, `data.expense.expense_report.id`, "")
            );
            setExpenseTypeId(
              getValue(urlParams, `expense_type_id`, "")
                ? getValue(urlParams, `expense_type_id`, "")
                : getValue(resp, `data.expense.expense_type.id`, "")
            );

            if (
              getValue(resp, `data.mileage.mileage_expense_type.id`, "") ==
                (getValue(urlParams, `expense_type_id`, "")
                  ? getValue(urlParams, `expense_type_id`, "")
                  : getValue(resp, `data.expense.expense_type.id`, "")) ||
              getValue(resp, `data.expense.expense_type.id`, "") ==
                (getValue(urlParams, `expense_type_id`, "")
                  ? getValue(urlParams, `expense_type_id`, "")
                  : getValue(resp, `data.expense.expense_type.id`, ""))
            ) {
              setDistance(
                getValue(
                  resp,
                  `data.expense.expense_entry_mileage.distance`,
                  ""
                )
              );
              setVehicle(
                getValue(
                  resp,
                  `data.expense.expense_entry_mileage.vehicle.id`,
                  ""
                )
              );

              setLocationPerDiem(
                getValue(resp, `data.expense.expense_entry_per_diem.name`, "")
              );

              setFromTime(
                convertTimeStringToDate(
                  getValue(
                    resp,
                    `data.expense.expense_entry_per_diem.start_time`,
                    ""
                  )
                )
              );

              setToTime(
                convertTimeStringToDate(
                  getValue(
                    resp,
                    `data.expense.expense_entry_per_diem.end_time`,
                    ""
                  )
                )
              );

              setDuration(
                getValue(
                  resp,
                  `data.expense.expense_entry_per_diem.duration`,
                  ""
                )
              );
              getAmountFromMileage(
                getValue(
                  resp,
                  `data.expense.expense_entry_mileage.distance`,
                  ""
                ),
                getValue(
                  resp,
                  `data.expense.expense_entry_mileage.vehicle.id`,
                  ""
                ),
                getValue(resp, `data.expense.expense_date`, "")
              );
            } else {
              setVehicle("");
              setDistance("");
              setLocationPerDiem("");
              setFromTime("");
              setToTime("");
              setDuration("");
            }

            // ... existing edit mode code ...

            let length = getValue(resp, `data.form_layout.length`, 0);
            let array = sortJSONObjectArray(
              getValue(resp, `data.form_layout`, []),
              "seq_no"
            );
            setDocuments(getValue(resp, `data.expense.receipts`, []));
            let expense = getValue(resp, `data.expense`, {});
            let payload = {
              currency_code: getValue(
                resp,
                `data.expense.claimed_currency`,
                ""
              ),
            };
            if (getValue(resp, `data.expense.claimed_currency`, "")) {
              let queryRequest = QueryRequestHelper(payload);
              let currency = await getAllCurrency(queryRequest);
              if (currency) {
                expense["currency"] = {
                  id: getValue(currency, `data.data[${0}].id`, ""),
                };
              }
            }
            for (let i = 0; i < length; i++) {
              FormAppendDataRequestHelper(array[i], expense);
            }

            // Modify the array to disable amount and currency fields if params has id
            const modifiedArray = array.map((item: any) => {
              const fieldName = getValue(item, `field_name`, "");
              if (
                getValue(params, `id`, "") &&
                expense_type &&
                expense_type ==
                  getValue(formInfo, `per_diem.per_diem_expense_type.id`, "") &&
                (fieldName === "amount" || fieldName === "currency_id")
              ) {
                return {
                  ...item,
                  disabled: true,
                };
              }
              return item;
            });

            if (!getValue(params, `id`, "")) {
              const filteredFields = ["expense_type"];
              if (
                expense_type &&
                expense_type ==
                  getValue(formInfo, `per_diem.per_diem_expense_type.id`, "")
              ) {
                filteredFields.push("amount", "expense_date", "currency_id");
              }
              scanDoc(
                docqueryRequest,
                expenseType,
                modifiedArray.filter(
                  (item: object) =>
                    !filteredFields.includes(getValue(item, `field_name`, ""))
                )
              );

              // setForm(
              //   modifiedArray.filter(
              //     (item: object) =>
              //       !filteredFields.includes(getValue(item, `field_name`, ""))
              //   )
              // );
            } else {
              scanDoc(
                docqueryRequest,
                expenseType,
                modifiedArray.filter(
                  (item: object) =>
                    getValue(item, `field_name`, "") !== "expense_type"
                )
              );
              // setForm(
              //   modifiedArray.filter(
              //     (item: object) =>
              //       getValue(item, `field_name`, "") !== "expense_type"
              //   )
              // );
            }
            setFormLoading(false);
          } else {
            let builder =
              getValue(resp, `data.form_layout.length`, 0) > 0 &&
              sortJSONObjectArray(
                getValue(resp, `data.form_layout`, []),
                "seq_no"
              ).map((item: any) => ({
                ...item,
                field_value: checkBoolean(getValue(item, `field_value`, "")),
              }));

            // Modify the builder to disable amount and currency fields if params has id
            const modifiedBuilder = builder.map((item: any) => {
              const fieldName = getValue(item, `field_name`, "");
              const expenseTypeId = id; // Directly using the id from the function argument

              if (
                getValue(params, `id`, "") &&
                expense_type &&
                expense_type ==
                  getValue(formInfo, `per_diem.per_diem_expense_type.id`, "") &&
                (fieldName === "amount" || fieldName === "currency_id")
              ) {
                return {
                  ...item,
                  disabled: true,
                };
              } else {
                return {
                  ...item,
                  disabled: false,
                };
              }
            });

            if (!getValue(params, `id`, "")) {
              // alert(3)
              // Initialize filtered fields with base field
              const filteredFields: string[] = ["expense_type"];
              // Check if expense type matches per diem expense type
              const perDiemExpenseTypeId = getValue(
                formInfo,
                "per_diem.per_diem_expense_type.id",
                ""
              );

              if (expense_type && expense_type === perDiemExpenseTypeId) {
                // Add additional fields to filter if expense type matches
                filteredFields.push("amount", "expense_date", "currency_id");
              }

              // Filter out specified fields from the builder
              const filteredBuilder = modifiedBuilder.filter(
                (item: any) => !filteredFields.includes(item.field_name)
              );
              // Scan document with filtered builder
              scanDoc(docqueryRequest, expenseType, filteredBuilder);
            } else {
              // setForm(
              //   modifiedBuilder.filter(
              //     (item: object) =>
              //       getValue(item, `field_name`, "") !== "expense_type"
              //   )
              // );
              scanDoc(
                docqueryRequest,
                expenseType,
                modifiedBuilder.filter(
                  (item: object) =>
                    getValue(item, `field_name`, "") !== "expense_type"
                )
              );
            }
            setFormLoading(false);
          }
        }
      } catch (error) {
        setFormLoading(false);
      }
    }
  };

  const scanDoc = async (docqueryRequest: any, expenseType: any, form: any) => {
    try {
      let resp = await autoScanDocument(docqueryRequest);
      if (resp) {
        //----------- Currency --------------//
        let payloadReq = {
          currency_code: getValue(resp, `data.currency_code`, ""),
        };
        let queryRequestCurrency = QueryRequestHelper(payloadReq);
        let currency = await getAllCurrency(queryRequestCurrency);

        //----------- Finding Index --------------//
        const amountIndex = form.findIndex(
          (obj: any) => getValue(obj, `field_name`, "") === "amount"
        );
        const expenseindex = form.findIndex(
          (obj: any) => getValue(obj, `field_name`, "") === "expense_date"
        );
        const expenseTypeindex = form.findIndex(
          (obj: any) => getValue(obj, `field_name`, "") === "expense_type"
        );
        const currencyIndex = form.findIndex(
          (obj: any) => getValue(obj, `field_name`, "") === "currency_id"
        );
        setValue(
          form,
          `[${amountIndex}].field_value`,
          getValue(resp, `data.total_amount`, null)
        );
        setValue(
          form,
          `[${expenseindex}].field_value`,
          getValue(resp, `data.invoice_date`, null)
            ? getValue(resp, `data.invoice_date`, null)
            : null
        );
        setValue(
          form,
          `[${expenseTypeindex}].field_value`,
          getValue(expenseType, `data.data.[${0}].id`, "")
        );
        setValue(
          form,
          `[${currencyIndex}].field_value`,
          getValue(currency, `data.data.[${0}].id`, "")
        );
        setForm([...form]);
      }
    } catch (error) {}
  };

  const handleExpenseType = (id: string) => {
    let payload: any = {
      ...urlParams,
      expense_type_id: id,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const [mainInfo, setMainInfo] = useState({});
  const [formLoading, setFormLoading] = useState(false);
  const getFormByExpenseTypeOld = async (id: string) => {
    if (id)
      try {
        let payload = {
          expense_type_id: id,
        };
        let queryRequest = QueryRequestHelper(payload);
        let resp;
        setFormLoading(true);
        if (getValue(params, `id`, "")) {
          resp = await getAllExpenseEntryFormEditExpenseType(
            getValue(params, `id`, ""),
            queryRequest
          );
        } else {
          resp = await getExpenseFormByExpenseType(queryRequest);
        }

        if (resp) {
          setMainInfo(getValue(resp, `data`, {}));
          if (getValue(params, `id`, "")) {
            setExpenseReportId(
              getValue(resp, `data.expense.expense_report.id`, "")
            );
            setExpenseTypeId(
              getValue(urlParams, `expense_type_id`, "")
                ? getValue(urlParams, `expense_type_id`, "")
                : getValue(resp, `data.expense.expense_type.id`, "")
            );

            if (
              getValue(resp, `data.mileage.mileage_expense_type.id`, "") ==
                (getValue(urlParams, `expense_type_id`, "")
                  ? getValue(urlParams, `expense_type_id`, "")
                  : getValue(resp, `data.expense.expense_type.id`, "")) ||
              getValue(resp, `data.expense.expense_type.id`, "") ==
                (getValue(urlParams, `expense_type_id`, "")
                  ? getValue(urlParams, `expense_type_id`, "")
                  : getValue(resp, `data.expense.expense_type.id`, ""))
            ) {
              setDistance(
                getValue(
                  resp,
                  `data.expense.expense_entry_mileage.distance`,
                  ""
                )
              );
              setVehicle(
                getValue(
                  resp,
                  `data.expense.expense_entry_mileage.vehicle.id`,
                  ""
                )
              );

              setLocationPerDiem(
                getValue(resp, `data.expense.expense_entry_per_diem.name`, "")
              );

              setFromTime(
                convertTimeStringToDate(
                  getValue(
                    resp,
                    `data.expense.expense_entry_per_diem.start_time`,
                    ""
                  )
                )
              );

              setToTime(
                convertTimeStringToDate(
                  getValue(
                    resp,
                    `data.expense.expense_entry_per_diem.end_time`,
                    ""
                  )
                )
              );

              setDuration(
                getValue(
                  resp,
                  `data.expense.expense_entry_per_diem.duration`,
                  ""
                )
              );
              getAmountFromMileage(
                getValue(
                  resp,
                  `data.expense.expense_entry_mileage.distance`,
                  ""
                ),
                getValue(
                  resp,
                  `data.expense.expense_entry_mileage.vehicle.id`,
                  ""
                ),
                getValue(resp, `data.expense.expense_date`, "")
              );
            } else {
              setVehicle("");
              setDistance("");
              setLocationPerDiem("");
              setFromTime("");
              setToTime("");
              setDuration("");
            }

            // setExpenseTypeId(getValue(resp, `data.expense.expense_type.id`, ""));
            // setForm(
            //   getValue(resp, `data.form_layout`, []).filter(
            //     (item: object) =>
            //       getValue(item, `field_name`, "") !== "expense_type"
            //   )
            // );
            let length = getValue(resp, `data.form_layout.length`, 0);
            let array = sortJSONObjectArray(
              getValue(resp, `data.form_layout`, []),
              "seq_no"
            );
            setDocuments(getValue(resp, `data.expense.receipts`, []));
            let expense = getValue(resp, `data.expense`, {});
            let payload = {
              currency_code: getValue(
                resp,
                `data.expense.claimed_currency`,
                ""
              ),
            };
            if (getValue(resp, `data.expense.claimed_currency`, "")) {
              let queryRequest = QueryRequestHelper(payload);
              let currency = await getAllCurrency(queryRequest);
              if (currency) {
                expense["currency"] = {
                  id: getValue(currency, `data.data[${0}].id`, ""),
                };
              }
            }
            for (let i = 0; i < length; i++) {
              FormAppendDataRequestHelper(array[i], expense);
            }

            if (!getValue(params, `id`, "")) {
              const filteredFields = ["expense_type"];
              if (
                expense_type &&
                expense_type ==
                  getValue(formInfo, `per_diem.per_diem_expense_type.id`, "")
              ) {
                // For Per Diem, also remove amount, expense_date, and currency
                filteredFields.push("amount", "expense_date", "currency_id");
              }

              setForm(
                array.filter(
                  (item: object) =>
                    !filteredFields.includes(getValue(item, `field_name`, ""))
                )
              );
            } else {
              setForm(
                array.filter(
                  (item: object) =>
                    getValue(item, `field_name`, "") !== "expense_type"
                )
              );
            }

            // setForm(
            //   array.filter(
            //     (item: object) =>
            //       getValue(item, `field_name`, "") !== "expense_type"
            //   )
            // );
            setFormLoading(false);
          } else {
            let builder =
              getValue(resp, `data.form_layout.length`, 0) > 0 &&
              sortJSONObjectArray(
                getValue(resp, `data.form_layout`, []),
                "seq_no"
              ).map((item: any) => ({
                ...item,
                field_value: checkBoolean(getValue(item, `field_value`, "")),
              }));

            if (!getValue(params, `id`, "")) {
              const filteredFields = ["expense_type"];
              if (
                expense_type &&
                expense_type ==
                  getValue(formInfo, `per_diem.per_diem_expense_type.id`, "")
              ) {
                // For Per Diem, also remove amount, expense_date, and currency
                filteredFields.push("amount", "expense_date", "currency_id");
              }

              setForm(
                builder.filter(
                  (item: object) =>
                    !filteredFields.includes(getValue(item, `field_name`, ""))
                )
              );
            } else {
              setForm(
                builder.filter(
                  (item: object) =>
                    getValue(item, `field_name`, "") !== "expense_type"
                )
              );
            }

            // setForm(
            //   builder.filter(
            //     (item: object) =>
            //       getValue(item, `field_name`, "") !== "expense_type"
            //   )
            // );
            setFormLoading(false);
          }
        }
      } catch (error) {
        setFormLoading(false);
      }
  };

  const getFormByExpenseType = async (id: any) => {
    if (id)
      try {
        let payload = {
          expense_type_id: id,
        };
        let queryRequest = QueryRequestHelper(payload);
        let resp;
        setFormLoading(true);
        if (getValue(params, `id`, "")) {
          resp = await getAllExpenseEntryFormEditExpenseType(
            getValue(params, `id`, ""),
            queryRequest
          );
        } else {
          resp = await getExpenseFormByExpenseType(queryRequest);
        }

        if (resp) {
          setMainInfo(getValue(resp, `data`, {}));
          if (getValue(params, `id`, "")) {
            setExpenseReportId(
              getValue(resp, `data.expense.expense_report.id`, "")
            );
            setExpenseTypeId(
              getValue(urlParams, `expense_type_id`, "")
                ? getValue(urlParams, `expense_type_id`, "")
                : getValue(resp, `data.expense.expense_type.id`, "")
            );

            if (
              getValue(resp, `data.mileage.mileage_expense_type.id`, "") ==
                (getValue(urlParams, `expense_type_id`, "")
                  ? getValue(urlParams, `expense_type_id`, "")
                  : getValue(resp, `data.expense.expense_type.id`, "")) ||
              getValue(resp, `data.expense.expense_type.id`, "") ==
                (getValue(urlParams, `expense_type_id`, "")
                  ? getValue(urlParams, `expense_type_id`, "")
                  : getValue(resp, `data.expense.expense_type.id`, ""))
            ) {
              setDistance(
                getValue(
                  resp,
                  `data.expense.expense_entry_mileage.distance`,
                  ""
                )
              );
              setVehicle(
                getValue(
                  resp,
                  `data.expense.expense_entry_mileage.vehicle.id`,
                  ""
                )
              );

              setLocationPerDiem(
                getValue(resp, `data.expense.expense_entry_per_diem.name`, "")
              );

              setFromTime(
                convertTimeStringToDate(
                  getValue(
                    resp,
                    `data.expense.expense_entry_per_diem.start_time`,
                    ""
                  )
                )
              );

              setToTime(
                convertTimeStringToDate(
                  getValue(
                    resp,
                    `data.expense.expense_entry_per_diem.end_time`,
                    ""
                  )
                )
              );

              setDuration(
                getValue(
                  resp,
                  `data.expense.expense_entry_per_diem.duration`,
                  ""
                )
              );
              getAmountFromMileage(
                getValue(
                  resp,
                  `data.expense.expense_entry_mileage.distance`,
                  ""
                ),
                getValue(
                  resp,
                  `data.expense.expense_entry_mileage.vehicle.id`,
                  ""
                ),
                getValue(resp, `data.expense.expense_date`, "")
              );
            } else {
              setVehicle("");
              setDistance("");
              setLocationPerDiem("");
              setFromTime("");
              setToTime("");
              setDuration("");
            }

            // ... existing edit mode code ...
            let length = getValue(resp, `data.form_layout.length`, 0);
            let array = sortJSONObjectArray(
              getValue(resp, `data.form_layout`, []),
              "seq_no"
            );
            setDocuments(getValue(resp, `data.expense.receipts`, []));
            let expense = getValue(resp, `data.expense`, {});
            let payload = {
              currency_code: getValue(
                resp,
                `data.expense.claimed_currency`,
                ""
              ),
            };
            if (getValue(resp, `data.expense.claimed_currency`, "")) {
              let queryRequest = QueryRequestHelper(payload);
              let currency = await getAllCurrency(queryRequest);
              if (currency) {
                expense["currency"] = {
                  id: getValue(currency, `data.data[${0}].id`, ""),
                };
              }
            }
            for (let i = 0; i < length; i++) {
              FormAppendDataRequestHelper(array[i], expense);
            }

            // Modify the array to disable amount and currency fields if params has id
            // const modifiedArray = array.map((item:any) => {
            //   const fieldName = getValue(item, `field_name`, "");
            //   if (getValue(params, `id`, "") && (fieldName === "amount" || fieldName === "currency_id")) {
            //     return {
            //       ...item,
            //       disabled: true
            //     };
            //   }
            //   return item;
            // });
            const modifiedArray = array.map((item: any) => {
              const fieldName = getValue(item, `field_name`, "");
              // Only disable fields if `id` is "9" and the field is either "amount" or "currency_id"
              // if (expenseTypeId === "9" && (fieldName === "amount" || fieldName === "currency_id")) {
              if (
                getValue(params, `id`, "") &&
                id === 9 &&
                (fieldName === "amount" || fieldName === "currency_id")
              ) {
                return {
                  ...item,
                  disabled: true,
                };
              } else {
                return {
                  ...item,
                  disabled: false,
                };
              }
            });

            if (!getValue(params, `id`, "")) {
              const filteredFields = ["expense_type"];
              if (
                expense_type &&
                expense_type ==
                  getValue(formInfo, `per_diem.per_diem_expense_type.id`, "")
              ) {
                filteredFields.push("amount", "expense_date", "currency_id");
              }

              setForm(
                modifiedArray.filter(
                  (item: object) =>
                    !filteredFields.includes(getValue(item, `field_name`, ""))
                )
              );
            } else {
              setForm(
                modifiedArray.filter(
                  (item: object) =>
                    getValue(item, `field_name`, "") !== "expense_type"
                )
              );
            }
            setFormLoading(false);
          } else {
            let builder =
              getValue(resp, `data.form_layout.length`, 0) > 0 &&
              sortJSONObjectArray(
                getValue(resp, `data.form_layout`, []),
                "seq_no"
              ).map((item: any) => ({
                ...item,
                field_value: checkBoolean(getValue(item, `field_value`, "")),
              }));

            // Modify the builder to disable amount and currency fields if params has id
            const modifiedBuilder = builder.map((item: any) => {
              const fieldName = getValue(item, `field_name`, "");
              const expenseTypeId = id; // Directly using the id from the function argument

              if (
                getValue(params, `id`, "") &&
                id === 9 &&
                (fieldName === "amount" || fieldName === "currency_id")
              ) {
                // if ((id === "9" || fieldName === "amount" || fieldName === "currency_id")) {
                return {
                  ...item,
                  disabled: true,
                };
              } else {
                return {
                  ...item,
                  disabled: false,
                };
              }
            });

            if (!getValue(params, `id`, "")) {
              const filteredFields = ["expense_type"];
              if (
                expense_type &&
                expense_type ==
                  getValue(formInfo, `per_diem.per_diem_expense_type.id`, "")
              ) {
                filteredFields.push("amount", "expense_date", "currency_id");
              }

              setForm(
                modifiedBuilder.filter(
                  (item: object) =>
                    !filteredFields.includes(getValue(item, `field_name`, ""))
                )
              );
            } else {
              setForm(
                modifiedBuilder.filter(
                  (item: object) =>
                    getValue(item, `field_name`, "") !== "expense_type"
                )
              );
            }
            setFormLoading(false);
          }
        }
      } catch (error) {
        setFormLoading(false);
      }
  };
  // const getFormByExpenseType = async (id: string) => {
  //   if (id)
  //     try {
  //       let payload = {
  //         expense_type_id: id,
  //       };
  //       let queryRequest = QueryRequestHelper(payload);
  //       let resp;
  //       setFormLoading(true);
  //       if (getValue(params, `id`, "")) {
  //         resp = await getAllExpenseEntryFormEditExpenseType(
  //           getValue(params, `id`, ""),
  //           queryRequest
  //         );
  //       } else {
  //         resp = await getExpenseFormByExpenseType(queryRequest);
  //       }

  //       if (resp) {
  //         console.log(resp, "---Resp---")
  //         setMainInfo(getValue(resp, `data`, {}));
  //         if (getValue(params, `id`, "")) {
  //           // ... existing edit mode code ...

  //           let length = getValue(resp, `data.form_layout.length`, 0);
  //           let array = sortJSONObjectArray(
  //             getValue(resp, `data.form_layout`, []),
  //             "seq_no"
  //           );
  //           setDocuments(getValue(resp, `data.expense.receipts`, []));
  //           let expense = getValue(resp, `data.expense`, {});
  //           let payload = {
  //             currency_code: getValue(
  //               resp,
  //               `data.expense.claimed_currency`,
  //               ""
  //             ),
  //           };
  //           if (getValue(resp, `data.expense.claimed_currency`, "")) {
  //             let queryRequest = QueryRequestHelper(payload);
  //             let currency = await getAllCurrency(queryRequest);
  //             if (currency) {
  //               expense["currency"] = {
  //                 id: getValue(currency, `data.data[${0}].id`, ""),
  //               };
  //             }
  //           }
  //           for (let i = 0; i < length; i++) {
  //             FormAppendDataRequestHelper(array[i], expense);
  //           }

  //           const filteredFields = ["expense_type"];
  //           if (id === "9") {
  //             // For Per Diem, also remove amount, expense_date, and currency
  //             filteredFields.push("amount", "expense_date", "currency_id");
  //           }

  //           setForm(array.filter((item: object) =>
  //             !filteredFields.includes(getValue(item, `field_name`, ""))
  //           ));
  //           setFormLoading(false);
  //         } else {
  //           let builder =
  //             getValue(resp, `data.form_layout.length`, 0) > 0 &&
  //             sortJSONObjectArray(
  //               getValue(resp, `data.form_layout`, []),
  //               "seq_no"
  //             ).map((item: any) => ({
  //               ...item,
  //               field_value: checkBoolean(getValue(item, `field_value`, "")),
  //             }));

  //           const filteredFields = ["expense_type"];
  //           if (id === "9") {
  //             // For Per Diem, also remove amount, expense_date, and currency
  //             filteredFields.push("amount", "expense_date", "currency_id");
  //           }

  //           setForm(builder.filter((item: object) =>
  //             !filteredFields.includes(getValue(item, `field_name`, ""))
  //           ));
  //           setFormLoading(false);
  //         }
  //       }
  //     } catch (error) {
  //       setFormLoading(false);
  //     }
  // };

  let date: any =
    getValue(form, "length", 0) > 0
      ? form.find(
          (item: object) => getValue(item, "field_name", "") === "expense_date"
        )
      : {};
  const [triggerAPI, setTriggerAPI] = useState(false);
  useEffect(() => {
    let date: any =
      getValue(form, "length", 0) > 0
        ? form.find(
            (item: object) =>
              getValue(item, "field_name", "") === "expense_date"
          )
        : {};
    if (
      distance &&
      vehicle &&
      getValue(date, "field_value", "") &&
      !triggerAPI
    ) {
      setTriggerAPI(true);
      getAmountFromMileage(
        distance,
        vehicle,
        getValue(date, "field_value", "")
      );
    }
  }, [distance, vehicle, date, !triggerAPI]);

  const getAmountFromMileage = async (
    distance: any,
    vehicle: string,
    date: any
  ) => {
    if (distance && vehicle && date)
      try {
        let payload = {
          expense_date: date,
          distance: distance,
          vehicle: vehicle,
        };
        let queryRequest = QueryRequestHelper(payload);
        let resp = await getSpecificMileageAmount(queryRequest);
        if (resp) {
          let data: any = form.map((item: object) => {
            if (getValue(item, "field_name", "") === "amount") {
              return {
                ...item,
                field_value: getValue(resp, "data.amount", ""),
                disabled: true,
              };
            }
            if (getValue(item, "field_name", "") === "currency_id") {
              return {
                ...item,
                field_value: getValue(resp, "data.base_currency.id", ""),
                disabled: true,
              };
            }
            return item;
          });
          setForm([...data]); // Create a new array using spread operator
        } else {
        }
      } catch (error) {
        let data: any = form.map((item: object) => {
          if (getValue(item, "field_name", "") === "amount") {
            return {
              ...item,
              field_value: "",
              disabled: false,
            };
          }
          if (getValue(item, "field_name", "") === "currency_id") {
            return {
              ...item,
              field_value: null,
              disabled: false,
            };
          }
          return item;
        });
        setForm([...data]);
      }
  };

  const expenseTypeSearchableDropdown = async (
    id: string,
    search_text: string
  ) => {
    let paylod = {
      search_text: search_text,
      page_no: 1,
      page_size: 50,
      enable_policy: true,
      id: search_text ? "" : id,
      status: "active",
    };
    let queryRequest = QueryRequestHelper(paylod);
    try {
      let resp = await getAllExpenseTypes(queryRequest);
      if (resp) {
        let data =
          getValue(resp, `data.data.length`, 0) > 0
            ? getValue(resp, `data.data`, []).map((item: object) => ({
                ...item,
                value: formatText(getValue(item, `expense_type_name`, "")),
                label: formatText(getValue(item, `expense_type_name`, "")),
                workflow_name: getValue(item, `expense_type_name`, ""),
              }))
            : [];
        return data;
      }
    } catch (error) {}
  };
  const handleChange = (e: any) => {
    const userInput = e.target.value;
    const regex = /^[0-9.]*$/; // Regular expression to allow only digits and a single decimal point
    let input = userInput.slice(0, 6);

    if (regex.test(input)) {
      return input;
    } else {
      return input ? input.replace(/[^0-9.]/g, "") : "";
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Create section                             */
  /* -------------------------------------------------------------------------- */
  const getFormData = async () => {
    let payload: any = {};
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await getAllExpenseFormReport(queryRequest);
      if (resp) {
        let form = getValue(resp, `data.form_layout`, []).map((item: any) => {
          if (getValue(item, `field_name`, "") === "duration_from") {
            const now = new Date();
            const firstDayOfMonth = new Date(
              now.getFullYear(),
              now.getMonth(),
              1
            );
            item["field_value"] = convertDate(firstDayOfMonth);
          } else if (getValue(item, `field_name`, "") === "duration_to") {
            item["field_value"] = convertDate(new Date());
          }
          return item; // Always return the item after making modifications
        });

        setReportFormInfo(getValue(resp, `data`, {}));
        setReportForm(form);
        // setReportForm(getValue(resp, `data.form_layout`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [reportForm, setReportForm] = React.useState<[]>([]);
  const [reportFormInfo, setReportFormInfo] = useState({});
  const [submitLoadingReport, setSubmitLoadingReport] = useState(false);

  // const handleSubmitCreateReport = async () => {
  //   const formValid = simpleValidator.current.allValid();
  //   if (!formValid) {
  //     simpleValidator.current.showMessages();
  //     forceUpdate(1);
  //   } else {
  //     try {
  //       setSubmitLoadingReport(true);
  //       let fields = JSON.parse(JSON.stringify(reportForm));
  //       let payload = FormRequestHelper(fields);
  //       let requestPayload: any = payload;
  //       let resp = await createExpenseReport(
  //         removeNullOrUndefinedProperties(requestPayload)
  //       );

  //       if (resp) {
  //         toast.success(getValue(resp, `message`, ""));
  //         handleOpenPopup();
  //         setReportForm([]);
  //         setReportFormInfo({});
  //         getFormData();
  //         if (getValue(resp, `data.id`, "")) {
  //           navigate(
  //             `/client/reports/detail/${getValue(
  //               resp,
  //               `data.id`,
  //               ""
  //             )}?${mainQueryRequest}`
  //           );
  //         } else {
  //           navigate(`/client/reports?${mainQueryRequest}`);
  //         }
  //         simpleValidator.current.hideMessages();
  //         forceUpdate(0);
  //         setSubmitLoadingReport(false);
  //       } else {
  //         setSubmitLoadingReport(false);
  //       }
  //     } catch (error) {
  //       setSubmitLoadingReport(false);
  //     }
  //   }
  // };

  const handleSubmitSaveReview = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoadingReport(true);
        let fields = JSON.parse(JSON.stringify(form));
        let payload = FormRequestHelper(fields);
        let requestPayload: any = payload;
        requestPayload["expense_report_id"] = expense_report_id;
        requestPayload["expense_type"] = parseInt(expense_type);
        if (vehicle) {
          requestPayload["mileage"] = {
            vehicle: vehicle,
            distance: parseFloat(distance),
            mileage_unit: getValue(formInfo, `mileage.mileage_unit`, ""),
          };
        }

        if (locationPerDiem) {
          requestPayload["per_diem"] = {
            name: locationPerDiem,
            start_time: convertCurrentTimePerDiem(fromTime),
            end_time: convertCurrentTimePerDiem(toTime),
            duration: duration,
          };
        }
        // requestPayload["vehicle"] = vehicle;
        // requestPayload["distance"] = parseFloat(distance);

        requestPayload["amount"] = requestPayload["personal"]
          ? parseFloat(`-${requestPayload["amount"]}`)
          : requestPayload["amount"];
        requestPayload["personal"] =
          requestPayload["personal"] === "false" ? true : false;
        requestPayload["receipt_ids"] =
          getValue(documents, `length`, 0) > 0
            ? documents.map((item: object) => getValue(item, `id`, ""))
            : [];
        if (getValue(documents, `length`, 0) > 0) {
          requestPayload["receipt_proofs"] = documents.reduce((proofs: any[], document: any) => {
            const documentId = getValue(document, 'id', '');
            const associatedProofIds = proofMapping[documentId] || [];
            
            // Create a proof mapping entry for each associated proof
            const documentProofs = associatedProofIds.map(proofId => ({
              receipt_id: documentId,
              proof_id: proofId
            }));
            
            return [...proofs, ...documentProofs];
          }, []);
        } else {
          requestPayload["receipt_proofs"] = [];
        }
        let resp;
        if (getValue(params, `id`, "")) {
          resp = await updateExpenseEntry(
            getValue(params, `id`, ""),
            removeNullOrUndefinedProperties(requestPayload)
          );
        } else {
          resp = await createExpenseEntry(
            removeNullOrUndefinedProperties(requestPayload)
          );
        }

        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          navigate(`/client/reports/detail/${expense_report_id}`);
          setSubmitLoadingReport(false);
        } else {
          setSubmitLoadingReport(false);
        }
      } catch (error) {
        setSubmitLoadingReport(false);
      }
    }
  };

  // const handleSubmitSaveReport = async () => {
  //   const formValid = simpleValidator.current.allValid();
  //   if (!formValid) {
  //     simpleValidator.current.showMessages();
  //     forceUpdate(1);
  //   } else {
  //     try {
  //       setSubmitLoadingReport(true);
  //       let fields = JSON.parse(JSON.stringify(form));
  //       let payload = FormRequestHelper(fields);
  //       let requestPayload: any = payload;
  //       requestPayload["expense_report_id"] = expense_report_id;
  //       requestPayload["expense_type"] = parseInt(expense_type);
  //       if (vehicle) {
  //         requestPayload["mileage"] = {
  //           vehicle: vehicle,
  //           distance: parseFloat(distance),
  //           mileage_unit: getValue(formInfo, `mileage.mileage_unit`, ""),
  //         };
  //       }
  //       // requestPayload["vehicle"] = vehicle;
  //       // requestPayload["distance"] = parseFloat(distance);

  //       requestPayload["amount"] = requestPayload["personal"]
  //         ? parseFloat(`-${requestPayload["amount"]}`)
  //         : requestPayload["amount"];
  //       requestPayload["personal"] =
  //         requestPayload["personal"] === "false" ? true : false;
  //       requestPayload["receipt_ids"] =
  //         getValue(documents, `length`, 0) > 0
  //           ? documents.map((item: object) => getValue(item, `id`, ""))
  //           : [];
  //       let resp;
  //       if (getValue(params, `id`, "")) {
  //         resp = await updateExpenseEntry(
  //           getValue(params, `id`, ""),
  //           removeNullOrUndefinedProperties(requestPayload)
  //         );
  //       } else {
  //         resp = await createExpenseEntry(
  //           removeNullOrUndefinedProperties(requestPayload)
  //         );
  //       }
  //       if (resp) {
  //         handleSubmitCreateReport();
  //         toast.success(getValue(resp, `message`, ""));
  //         setSubmitLoadingReport(false);
  //       } else {
  //         setSubmitLoadingReport(false);
  //       }
  //     } catch (error) {
  //       setSubmitLoadingReport(false);
  //     }
  //   }
  // }

  const handleExpenseSave = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoadingReport(true);
        let fields = JSON.parse(JSON.stringify(form));
        let payload = FormRequestHelper(fields);
        let requestPayload: any = payload;
        requestPayload["expense_report_id"] = expense_report_id;
        requestPayload["expense_type"] = parseInt(expense_type);
        if (vehicle) {
          requestPayload["mileage"] = {
            vehicle: vehicle,
            distance: parseFloat(distance),
            mileage_unit: getValue(formInfo, `mileage.mileage_unit`, ""),
          };
        }
        if (locationPerDiem) {
          requestPayload["per_diem"] = {
            name: locationPerDiem,
            start_time: convertCurrentTimePerDiem(fromTime),
            end_time: convertCurrentTimePerDiem(toTime),
            duration: duration,
          };
        }
        // requestPayload["vehicle"] = vehicle;
        // requestPayload["distance"] = parseFloat(distance);

        requestPayload["amount"] = requestPayload["personal"]
          ? parseFloat(`-${requestPayload["amount"]}`)
          : requestPayload["amount"];
        requestPayload["personal"] =
          requestPayload["personal"] === "false" ? true : false;
        requestPayload["receipt_ids"] =
          getValue(documents, `length`, 0) > 0
            ? documents.map((item: object) => getValue(item, `id`, ""))
            : [];
        if (getValue(documents, `length`, 0) > 0) {
          requestPayload["receipt_proofs"] = documents.reduce((proofs: any[], document: any) => {
            const documentId = getValue(document, 'id', '');
            const associatedProofIds = proofMapping[documentId] || [];
            
            // Create a proof mapping entry for each associated proof
            const documentProofs = associatedProofIds.map(proofId => ({
              receipt_id: documentId,
              proof_id: proofId
            }));
            
            return [...proofs, ...documentProofs];
          }, []);
        } else {
          requestPayload["receipt_proofs"] = [];
        }
        let resp;

        if (getValue(params, `id`, "")) {
          resp = await updateExpenseEntry(
            getValue(params, `id`, ""),
            removeNullOrUndefinedProperties(requestPayload)
          );
        } else {
          resp = await createExpenseEntry(
            removeNullOrUndefinedProperties(requestPayload)
          );
        }

        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          // navigate(
          //   `/client/reports/detail/${expense_report_id}`
          // );
          setSubmitLoadingReport(false);
        } else {
          setSubmitLoadingReport(false);
        }
      } catch (error) {
        setSubmitLoadingReport(false);
      }
    }
  };

  const handleSubmitSaveReport = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoadingReport(true);
        let fields = JSON.parse(JSON.stringify(reportForm));
        let payload = FormRequestHelper(fields);
        let requestPayload: any = payload;
        let resp = await createExpenseReport(
          removeNullOrUndefinedProperties(requestPayload)
        );

        if (resp) {
          // toast.success(getValue(resp, `message`, ""));
          setReportForm([]);
          setReportFormInfo({});
          getFormData();
          setExpenseReportId(getValue(resp, `data.id`, ""));
          // handleExpenseSave();

          /* ---------------- */
          setSubmitLoadingReport(true);
          let fields = JSON.parse(JSON.stringify(form));
          let payload = FormRequestHelper(fields);
          let requestPayload: any = payload;
          requestPayload["expense_report_id"] = getValue(resp, `data.id`, "");
          requestPayload["expense_type"] = parseInt(expense_type);
          if (vehicle) {
            requestPayload["mileage"] = {
              vehicle: vehicle,
              distance: parseFloat(distance),
              mileage_unit: getValue(formInfo, `mileage.mileage_unit`, ""),
            };
          }
          if (locationPerDiem) {
            requestPayload["per_diem"] = {
              name: locationPerDiem,
              start_time: convertCurrentTimePerDiem(fromTime),
              end_time: convertCurrentTimePerDiem(toTime),
              duration: duration,
            };
          }
          // requestPayload["vehicle"] = vehicle;
          // requestPayload["distance"] = parseFloat(distance);

          requestPayload["amount"] = requestPayload["personal"]
            ? parseFloat(`-${requestPayload["amount"]}`)
            : requestPayload["amount"];
          requestPayload["personal"] =
            requestPayload["personal"] === "false" ? true : false;
          requestPayload["receipt_ids"] =
            getValue(documents, `length`, 0) > 0
              ? documents.map((item: object) => getValue(item, `id`, ""))
              : [];
          if (getValue(documents, `length`, 0) > 0) {
            requestPayload["receipt_proofs"] = documents.reduce((proofs: any[], document: any) => {
              const documentId = getValue(document, 'id', '');
              const associatedProofIds = proofMapping[documentId] || [];
              
              // Create a proof mapping entry for each associated proof
              const documentProofs = associatedProofIds.map(proofId => ({
                receipt_id: documentId,
                proof_id: proofId
              }));
              
              return [...proofs, ...documentProofs];
            }, []);
          } else {
            requestPayload["receipt_proofs"] = [];
          }
          let respForm;

          if (getValue(params, `id`, "")) {
            respForm = await updateExpenseEntry(
              getValue(params, `id`, ""),
              removeNullOrUndefinedProperties(requestPayload)
            );
          } else {
            respForm = await createExpenseEntry(
              removeNullOrUndefinedProperties(requestPayload)
            );
          }

          if (respForm) {
            toast.success(getValue(resp, `message`, ""));
            navigate(`/client/reports/detail/${getValue(resp, `data.id`, "")}`);
            setSubmitLoadingReport(false);
          } else {
            setSubmitLoadingReport(false);
          }
          /* ---------------- */

          simpleValidator.current.hideMessages();
          forceUpdate(0);
          setSubmitLoadingReport(false);

          // if (getValue(resp, `data.id`, "")) {
          //   navigate(
          //     `/client/reports/detail/${getValue(
          //       resp,
          //       `data.id`,
          //       ""
          //     )}?${mainQueryRequest}`
          //   );
          // } else {
          //   navigate(`/client/reports?${mainQueryRequest}`);
          // }
        } else {
          setSubmitLoadingReport(false);
        }
      } catch (error) {
        setSubmitLoadingReport(false);
      }
    }
  };

  const [isOpen, setISOpen] = useState(false);
  const isToggle = () => setISOpen(!isOpen);

  const handleSaveSubmit = async () => {
    // isToggle();
    if (getValue(mainInfo, `is_receipt_mandatory`, false)) {
      if (getValue(documents, `length`, 0) > 0) {
        if (expense_report_id) {
          // handleSubmitSaveReview();
          if (getValue(perDiemExpenseRateList, `length`, 0) > 0) {
            handleSubmitPerDiemBulkExpense();
          } else {
            handleSubmitSaveReview();
          }
        } else {
          handleOpenPopup();
        }
      } else {
        toast.error("Document is mandatory");
      }
    } else {
      if (expense_report_id) {
        // handleSubmitSaveReview();
        if (getValue(perDiemExpenseRateList, `length`, 0) > 0) {
          handleSubmitPerDiemBulkExpense();
        } else {
          handleSubmitSaveReview();
        }
      } else {
        handleOpenPopup();
      }
    }
  };

  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const handleOpenPopup = () => {
    setShowUpdatePopup(!showUpdatePopup);
  };

  const [createReportClicked, setCreateReportClicked] = useState(false);
  const onClickAdd = async () => {
    setCreateReportClicked(true);
    handleOpenPopup();
  };

  const createReportNewFromDropdown = async () => {
    try {
      setSubmitLoadingReport(true);
      let fields = JSON.parse(JSON.stringify(reportForm));
      let payload = FormRequestHelper(fields);
      let requestPayload: any = payload;
      let resp = await createExpenseReport(
        removeNullOrUndefinedProperties(requestPayload)
      );
      if (resp) {
        setSubmitLoadingReport(false);
        handleOpenPopup();
        setReportForm([]);
        setReportFormInfo({});
        fetchDropdownData();
        setExpenseReportId(getValue(resp, `data.id`, ""));
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Per Diem section                           */
  /* -------------------------------------------------------------------------- */
  const [locationList, setLocationList] = useState();
  const [locationListLoading, setLocationListLoading] = useState(false);
  const [locationPerDiem, setLocationPerDiem] = useState("");
  const [duration, setDuration] = useState("");

  const [fromDate, setFromDate] = useState<any>(null);
  const [fromTime, setFromTime] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const [toTime, setToTime] = useState<any>(null);

  const getMinTime = () => {
    if (getValue(params, "id", "") && fromTime) {
      // If params has ID, minimum time should be 1 hour after fromTime
      const minTime = new Date(fromTime);
      minTime.setHours(fromTime.getHours() + 1);
      return minTime;
    }
    return null;
  };

  const [perDiemExpenseRateList, setPerDiemExpenseRateList] = useState<any>();
  const [perDiemExpenseRateListInfo, setPerDiemExpenseRateListInfo] =
    useState<any>();
  const getPerDiemExpenseRateList = async () => {
    try {
      let payload = {
        start_date: convertDate(fromDate),
        end_date: convertDate(toDate),
        start_time: convertCurrentTimePerDiem(fromTime),
        end_time: convertCurrentTimePerDiem(toTime),
        name: locationPerDiem,
      };

      let queryRequest = QueryRequestHelper(payload);
      let resp = await getExpensePerDiemRatesList(queryRequest);
      if (resp) {
        setPerDiemExpenseRateList(getValue(resp, `data`, []));
        setPerDiemExpenseRateListInfo(getValue(resp, `data`, []));
      }
    } catch (error) {}
  };

  const [expenseDateEdit, setExpenseDateEdit] = useState("");
  const getSinglePerDiemExpenseRateList = async () => {
    try {
      let payload = {
        date: expenseDateEdit,
        start_time: convertCurrentTimePerDiem(fromTime),
        end_time: convertCurrentTimePerDiem(toTime),
        name: locationPerDiem,
      };

      let queryRequest = QueryRequestHelper(payload);
      let resp = await getSingleExpensePerDiemRatesList(queryRequest);
      if (resp) {
        setDuration(getValue(resp, `data.duration`, ""));
        const amountIndex = form.findIndex(
          (obj) => getValue(obj, `field_name`, "") === "amount"
        );

        setForm((options: any) => {
          return options.map((x: object, index: number) => {
            if (index !== amountIndex) return x;
            return {
              ...x,
              field_value: getValue(resp, `data.amount`, null),
            };
          });
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (locationPerDiem && fromDate && fromTime && toDate && toTime) {
      getPerDiemExpenseRateList();
    }
  }, [locationPerDiem, fromDate, fromTime, toDate, toTime]);

  useEffect(() => {
    if (getValue(params, "id", "") && fromTime && toTime) {
      const minAllowedTime = getMinTime();
      if (minAllowedTime && toTime < minAllowedTime) {
        // If current toTime is less than minimum allowed time, auto-adjust it
        setToTime(minAllowedTime);
      }
    }
  }, [fromTime, params]);

  useEffect(() => {
    if (getValue(params, `id`, "")) {
      if (locationPerDiem && fromTime && toTime) {
        getSinglePerDiemExpenseRateList();
      }
    }
  }, [locationPerDiem, fromTime, toTime]);

  useEffect(() => {
    if (fromDate && toDate && toDate < fromDate) {
      setToDate(null);
    }
  }, [fromDate]);

  const getPerDiemLocationList = async () => {
    try {
      let payload = {
        page_no: 1,
        page_size: 100,
      };
      setLocationListLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllPerDiemLocation(queryRequest);
      if (resp) {
        setLocationList(
          getValue(resp, `data`, []).map((item: object, index: number) => ({
            ...item,
            value: getValue(item, "name", ""),
            name: getValue(item, "name", ""), // Ensure 'name' is extracted properly
            label: getValue(item, "name", ""), // Reuse 'name' for label
          }))
        );

        setLocationListLoading(false);
      } else {
        setLocationListLoading(false);
      }
    } catch (error) {
      setLocationListLoading(false);
    }
  };

  const [isExpesePerDiemRateListOpen, setIsExpesePerDiemRateListOpen] =
    useState(false);
  const handleExpesePerDiemRateListOpen = () => {
    setIsExpesePerDiemRateListOpen(!isExpesePerDiemRateListOpen);
  };

  // console.log(
  //   mainInfo,
  //   getValue(mainInfo, `is_proof_mandatory`, false),
  //   "===mainInfo==>"
  // );

  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes("expenses") ? (
        <NoAccessPage />
      ) : (
        <>
          <div className="expense-create-wrapper">
            <div className="expense-create-wrapper__header d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <NavLink
                  to={
                    getValue(urlParams, `expense_report_id`, "")
                      ? `/client/reports/detail/${getValue(
                          urlParams,
                          `expense_report_id`,
                          ""
                        )}`
                      : getValue(params, `id`, "")
                      ? `/client/expenses/detail/${getValue(params, `id`, "")}`
                      : "/client/expenses"
                  }
                  // className="expense-create-wrapper__header-back-link"
                >
                  <BackSvgComponent />
                </NavLink>
                <a href="#0" className="header_text ">
                  {getValue(params, `id`, "") ? "Update" : "New"} Expense
                </a>
              </div>
              <div className="expense-create-wrapper__cta-btns">
                <NavLink
                  to={
                    getValue(urlParams, `expense_report_id`, "")
                      ? `/client/reports/detail/${getValue(
                          urlParams,
                          `expense_report_id`,
                          ""
                        )}`
                      : getValue(params, `id`, "")
                      ? `/client/expenses/detail/${getValue(params, `id`, "")}`
                      : "/client/expenses"
                  }
                >
                  <button className="ascent-button ascent-button--header-buttons ascent-button--primary">
                    Cancel
                  </button>
                </NavLink>

                {!expense_report_id ? (
                  <>
                    {getValue(props, `permissions`, []).includes("update") &&
                      getValue(params, `id`, "") && (
                        <button
                          className="ascent-button ascent-button--header-buttons ascent-button--secondary me-3"
                          onClick={handleSubmitForm}
                          disabled={submitLoading}
                        >
                          {submitLoading
                            ? "Please wait..."
                            : getValue(params, `id`, "")
                            ? "Update"
                            : "Save & Submit Later"}
                        </button>
                      )}
                    {getValue(props, `permissions`, []).includes("create") &&
                      !getValue(params, `id`, "") && (
                        <button
                          className="ascent-button ascent-button--header-buttons ascent-button--secondary me-3"
                          onClick={handleSubmitForm}
                          disabled={submitLoading}
                        >
                          {submitLoading
                            ? "Please wait..."
                            : getValue(params, `id`, "")
                            ? "Update"
                            : "Save & Submit Later"}
                        </button>
                      )}
                  </>
                ) : null}

                {expense_report_id ? (
                  <>
                    {!getValue(params, `id`, "") && (
                      <button
                        className="ascent-button ascent-button--header-buttons ascent-button--secondary"
                        onClick={handleSaveSubmit}
                        disabled={submitLoadingReport}
                      >
                        {submitLoadingReport
                          ? "Please wait..."
                          : expense_report_id
                          ? "Save & View Report"
                          : " Save & Submit"}
                      </button>
                    )}

                    {getValue(props, `permissions`, []).includes("update") &&
                      getValue(params, `id`, "") && (
                        <button
                          className="ascent-button ascent-button--header-buttons ascent-button--secondary me-3"
                          onClick={handleSubmitForm}
                          disabled={submitLoading}
                        >
                          {submitLoading
                            ? "Please wait..."
                            : getValue(params, `id`, "")
                            ? "Update"
                            : "Save & Submit"}
                        </button>
                      )}
                  </>
                ) : null}
              </div>
            </div>
            {scanLoading ? (
              <Loader />
            ) : (
              <div className="expense-create-wrapper_container">
                <div className="expense-create-wrapper_left">
                  <div className="expense-create-wrapper_left_container">
                    <h6 className="expense-create-wrapper_left_container_header">
                      Attach Report from Computer or Cloud{" "}
                      {getValue(mainInfo, `is_receipt_mandatory`, false) && (
                        <span className="form-label-error">*</span>
                      )}
                    </h6>
                    <DragAndDropFileComponent
                      uploadFile={handleUploadDocuments}
                      name="file"
                      types={fileTypes}
                      multiple
                      classes="bulk-upload_drag-n-drop-wrapper position-absolute"
                      fileName={getValue(props, `importedFile.name`, "")}
                      setImportTedFile={props.setImportTedFile}
                    />
                  </div>
                  <div className="expense-detail-wrapper_report_documentsection w-100">
                    {uploadLoading || uploadStaticLoading ? (
                      <UploadLoader />
                    ) : getValue(documents, `length`, 0) === 0 ? (
                      <p className="mt-5 mb-5 text-center">No document found</p>
                    ) : (
                      // <div
                      //   className="d-flex  mt-3 m-4"
                      //   style={{ flexWrap: "wrap" }}
                      // >
                      //   <DocumentImageList
                      //     documents={documents}
                      //     containerStayle={{ height: "100px", width: "150px" }}
                      //     textContainerStayle={{
                      //       height: "40px",
                      //       width: "150px",
                      //     }}
                      //     textStyle={{ fontSize: "12px" }}
                      //     // handleRemoveDocuments={handleDeleteFun}
                      //     handleRemoveDocuments={handleDeleteDocument}
                      //     uploadLoading={uploadLoading}
                      //     ai
                      //     handleScanDoc={handleScanDoc}
                      //   />
                      // </div>

                      <>
                        <div className="d-flex flex-row gap-4 mt-3 m-4">
                        {documents.map((document: any) => {
                          const documentId = getValue(document, 'id', '');
                          const proofIds = proofMapping[documentId] || [];
                          const currentIndex = currentProofIndex[documentId] || 0;
                          // console.log(proofIds, "===proofIds==");
                          // console.log(documentId, "====documentId===");
                          return(
                            <div key={documentId} className="document-proof-pair border rounded p-4">
                              <div className="document-section">
                                <DocumentImageList
                                  documents={[document]}
                                  containerStayle={{ height: "100px", width: "150px" }}
                                  textContainerStayle={{ height: "40px", width: "150px" }}
                                  textStyle={{ fontSize: "12px" }}
                                  handleRemoveDocuments={handleDeleteDocument}
                                  uploadLoading={uploadLoading}
                                  // ai
                                  // handleScanDoc={handleScanDoc}
                                />

                                <div className="d-flex gap-2 mt-2 auto-scan-proof-wrap">
                                  {/* <div
                                    className="cursor-pointer border border-radius__5 d-flex justify-content-center align-items-center bg-white box_shadow"
                                    onClick={() => handleScanDoc(document)}
                                  >
                                    <div className="d-flex align-items-center justify-content-center p-2">
                                      <ExpenseSvgComponent />
                                      <h6 className="small_text__14  ms-1">Auto Scan</h6>
                                    </div>
                                  </div> */}
                                  {getValue(mainInfo, `is_proof_mandatory`, false) && (
                                  <div className="proof-upload-wrapper">
                                    <DragAndDropFileComponent
                                      uploadFile={(e:any) => handleUploadDocumentsProof(e, documentId)}
                                      name="proof"
                                      types={fileTypes}
                                      multiple={false}
                                      // multiple
                                      classes="bulk-upload_drag-n-drop-wrapper position-relative"
                                      proofText
                                    />
                                  </div> )}
                                </div>
                              </div>
                              {getValue(mainInfo, `is_proof_mandatory`, false) && proofIds.length > 0 && (
                              <div className="proof-carousel mt-4">
                                <h6>Proof for {getValue(document, 'name', '')}</h6>
                                <div className="d-flex align-items-center gap-2">
                                  <button
                                    className="btn btn-caret btn-caret-left"
                                    onClick={() => handlePrevProof(documentId)}
                                    disabled={currentIndex === 0}
                                  >
                                    {/* <ChevronLeft className="w-4 h-4" /> */}
                                    <ArrowDownSvgComponent />
                                  </button>
                                  
                                  <div className="d-flex gap-1 overflow-hidden">
                                    {documentsProof
                                      .filter((proof: any) => proofIds.includes(getValue(proof, 'id', '')))
                                      .map((proof: any, index: number) => (
                                        <div
                                          key={getValue(proof, 'id', '')}
                                          className={`proof-item ${index === currentIndex ? 'active' : ''}`}
                                          style={{
                                            transform: `translateX(-${currentIndex * 100}%)`
                                          }}
                                        >
                                          <ProofImageList
                                            documents={[proof]}
                                            containerStayle={{ height: "80px", width: "120px" }}
                                            textContainerStayle={{ height: "30px", width: "120px" }}
                                            textStyle={{ fontSize: "10px" }}
                                            handleRemoveDocuments={() => handleDeleteProof(documentId, getValue(proof, 'id', ''))}
                                            uploadLoading={uploadLoading}
                                          />
                                        </div>
                                      ))}
                                  </div>
                                  
                                  <button
                                    className="btn btn-caret btn-caret-right"
                                    onClick={() => handleNextProof(documentId)}
                                    disabled={currentIndex === proofIds.length - 1}
                                  >
                                    {/* <ChevronRight className="w-4 h-4" /> */}
                                    <ArrowDownSvgComponent />
                                  </button>
                                </div>
                              </div>
                            )}
                            </div>
                          )
                        })}

                        </div>
                      </>
                    )}

                    {/* {getValue(mainInfo, `is_proof_mandatory`, false) &&
                      getValue(documents, `length`, 0) > 0 && (
                        <>
                          <button className="btn btn-primary">
                            Attach Proof
                          </button>
                          <DragAndDropFileComponent
                            uploadFile={handleUploadDocumentsProof}
                            name="file"
                            types={fileTypesProof}
                            multiple
                            classes="bulk-upload_drag-n-drop-wrapper position-absolute"
                            fileName={getValue(props, `importedFile.name`, "")}
                            setImportTedFile={props.setImportTedFile}
                          />

                          {uploadLoadingProof || uploadStaticLoadingProof ? (
                            <UploadLoader />
                          ) : getValue(documentsProof, `length`, 0) === 0 ? (
                            <p className="mt-5 mb-5 text-center">
                              No document found
                            </p>
                          ) : (
                            <div
                              className="d-flex  mt-3 m-4"
                              style={{ flexWrap: "wrap" }}
                            >
                              <DocumentImageList
                                documents={documentsProof}
                                containerStayle={{
                                  height: "100px",
                                  width: "150px",
                                }}
                                textContainerStayle={{
                                  height: "40px",
                                  width: "150px",
                                }}
                                textStyle={{ fontSize: "12px" }}
                                handleRemoveDocuments={handleDeleteFun}
                                uploadLoading={uploadLoadingProof}
                              />
                            </div>
                          )}
                        </>
                      )} */}
                      
                    {/* Commented Proof for now */}
                  {/* {getValue(mainInfo, `is_proof_mandatory`, false) && getValue(documents, `length`, 0) > 0 && (
                      <>
                          <h6 className="expense-create-wrapper_left_container_header">Attach Proof for Uploaded Documents</h6>
                          {documents.map((doc: any, index: number) => (
                              <div key={index} className="proof-upload-wrapper">
                                  <p>{doc.name}</p>
                                  <DragAndDropFileComponent
                                      // uploadFile={(files:any) => handleUploadDocumentsProof(files, index)}
                                      name={`proof-${index}`}
                                      types={fileTypesProof}
                                      multiple={false} // Assuming one proof per document
                                      classes="bulk-upload_drag-n-drop-wrapper position-absolute"
                                      proofText
                                  />
                                  {documentsProof[index] && (
                                      <DocumentImageList
                                          documents={[documentsProof[index]]}
                                          // handleRemoveDocuments={(id: string) => handleRemoveProof(id, index)}
                                      />
                                  )}
                              </div>
                          ))}
                      </>
                  )} */}
                  </div>
                </div>
                <div className="expense-create-wrapper_right">
                  <div className={`form_wrapper-pipeline`}>
                    <div className="form_builder_field">
                      <SingleSelectSearchDropdownReport
                        data={reportList}
                        label={"label"}
                        name="Reports"
                        value={expense_report_id}
                        onChange={(e: any) =>
                          setExpenseReportId(getValue(e, `id`, ""))
                        }
                        placeholder={"Choose Reports"}
                        width={"100%"}
                        selectKey={"id"}
                        showReportText
                        // lookup_api="expense_reports_submitted"
                        showLabel
                        disabled={getValue(params, `id`, "") || getValue(urlParams, `expense_report_id`, "")}
                        add
                        onClickAdd={onClickAdd}
                      />
                    </div>
                  </div>
                  <div className="border-bottom mb-16"></div>
                  <div className={`form_wrapper-pipeline`}>
                    <div className="form_builder_field">
                      <SingleSelectSearchDropdown
                        label={"label"}
                        name="Expense Type"
                        value={expense_type}
                        onChange={(e: any) => {
                          // getFormByExpenseType(getValue(e, `id`, ""));
                          setForm([]);
                          setVehicle("");
                          setDistance("");
                          setExpenseTypeId(getValue(e, `id`, ""));
                          handleHideValidation();
                          handleExpenseType(getValue(e, `id`, ""));
                          setLocationPerDiem("");
                          setFromTime("");
                          setToTime("");
                          setDuration("");
                        }}
                        placeholder={"Choose Expense Type"}
                        width={"100%"}
                        selectKey={"id"}
                        lookup_api="expense_type"
                        showLabel
                        mandatory
                      />
                    </div>
                    {/* <hr className="mt-4" /> */}
                  </div>
                  {getValue(mainInfo, `expense_type_comments`, "") && (
                    <div className="expense_type_comment">
                      <div
                          dangerouslySetInnerHTML={{
                            __html: RichTextEditor.createValueFromString(
                              getValue(mainInfo, `expense_type_comments`, ""),
                              "html"
                            ).toString("html"), // Specify "html" as the format
                          }}                        
                        />
                      {/* {getValue(mainInfo, `expense_type_comments`, "")} */}
                    </div>
                  )}

                  {formLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {getValue(form, `length`, 0) === 0 ? (
                        <>
                          <div className="expense-load-form-wrapper">
                            <div className="expense-load-form-overlay text-center"></div>
                            <div className="expense-load-form-content-holder">
                              Start by Entering Expense Type
                            </div>

                            <div className="expense-load-dummy-form">
                              <div className="dummy-label-textbox-wrap">
                                <label htmlFor="">Expense Date</label>
                                <input type="text" className="form-control" />
                              </div>

                              <div className="dummy-label-textbox-wrap">
                                <label htmlFor="">Departure</label>
                                <input type="text" className="form-control" />
                              </div>

                              <div className="dummy-label-textbox-wrap">
                                <label htmlFor="">Arrival</label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {expense_type &&
                            expense_type ==
                              getValue(
                                formInfo,
                                `mileage.mileage_expense_type.id`,
                                ""
                              ) && (
                              <>
                                <div className={`form_wrapper-pipeline`}>
                                  <div className="form_builder_field">
                                    <SingleSelectSearchDropdown
                                      label={"label"}
                                      name="Vehicle"
                                      value={vehicle}
                                      onChange={(e: any) => {
                                        setTriggerAPI(false);
                                        setVehicle(getValue(e, `value`, ""));
                                      }}
                                      data={vehicleList}
                                      placeholder={"Choose Vehicle"}
                                      width={"100%"}
                                      selectKey={"id"}
                                      showLabel
                                      mandatory
                                      validator={simpleValidator}
                                    />
                                  </div>
                                </div>
                                <div className={`form_wrapper-pipeline `}>
                                  <div className="form_builder_field w-auto">
                                    <label className="form-label-error">
                                      Distance *
                                    </label>
                                    <div className="position-relative">
                                      <InputField
                                        inputType="DECIMAL"
                                        name="distance"
                                        value={distance}
                                        onChange={(e: any) => {
                                          setTriggerAPI(false);
                                          setDistance(handleChange(e));
                                        }}
                                        options={vehicleList}
                                        placeholder={"Enter distance"}
                                        width={"100%"}
                                        label="Distance"
                                        validator={simpleValidator}
                                      />
                                      <div className="position-absolute mileage_absolute">
                                        <h6>
                                          {getValue(
                                            formInfo,
                                            `mileage.mileage_unit`,
                                            ""
                                          )}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                          {/* Per Diem */}
                          {expense_type &&
                            expense_type ==
                              getValue(
                                formInfo,
                                `per_diem.per_diem_expense_type.id`,
                                ""
                              ) && (
                              <>
                                <div className="form_wrapper-pipeline">
                                  <div className="form_builder_field w-auto">
                                    <SingleSelectSearchDropdown
                                      label={"label"}
                                      name="Location"
                                      value={locationPerDiem}
                                      onChange={(e: any) => {
                                        setTriggerAPI(false);
                                        setLocationPerDiem(
                                          getValue(e, `value`, "")
                                        );
                                      }}
                                      data={locationList}
                                      placeholder={"Choose Location"}
                                      width={"100%"}
                                      selectKey={"value"}
                                      showLabel
                                      mandatory
                                      validator={simpleValidator}
                                    />
                                  </div>
                                </div>

                                {/* {!getValue(params, `id`, "") && (
                                  <div className="form_wrapper-pipeline">
                                    <div className="form_builder_field w-auto">
                                      <label className="form-label-error">
                                        From Date *
                                      </label>
                                      <DatePicker
                                        selected={fromDate}
                                        onChange={(date: Date | null) =>
                                          setFromDate(date)
                                        }
                                        dateFormat="d MMMM, yyyy"
                                        placeholderText="Select From Date"
                                        className="custom-form-control form-control"
                                      />
                                      Old
                                      <DatePicker
                                        selected={startDate}
                                        onChange={(date:any) => setStartDate(date)}
                                        showTimeSelect
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        className="custom-form-control form-control"
                                        placeholderText="Select From Date & Time"
                                      />
                                    </div>
                                  </div>
                                )} */}

                                <div className="form_wrapper-pipeline">
                                  <div className="form_builder_field w-auto">
                                    <label className="form-label-error">
                                      {getValue(params, `id`, "")
                                        ? "From Time"
                                        : "From Date & Time "}{" "}
                                      *{/* From Date & Time  */}
                                    </label>
                                    <div
                                      className={`d-flex date-time-wrap gap-2 ${
                                        getValue(params, `id`, "")
                                          ? "only-time-wrap"
                                          : ""
                                      }`}
                                    >
                                      {!getValue(params, `id`, "") && (
                                        <DatePicker
                                          selected={fromDate}
                                          onChange={(date: Date | null) =>
                                            setFromDate(date)
                                          }
                                          dateFormat="d MMMM, yyyy"
                                          placeholderText="Select From Date"
                                          className="custom-form-control form-control"
                                        />
                                      )}
                                      <DatePicker
                                        selected={fromTime}
                                        onChange={(time: Date | null) =>
                                          setFromTime(time)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={60}
                                        timeCaption="Time"
                                        dateFormat="p"
                                        placeholderText="Select From Time"
                                        className="custom-form-control form-control"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="form_wrapper-pipeline">
                                  <div className="form_builder_field w-auto">
                                    <label className="form-label-error">
                                      {getValue(params, `id`, "")
                                        ? "To Time"
                                        : "To Date & Time "}{" "}
                                      *{/* To Date * */}
                                    </label>
                                    <div
                                      className={`d-flex date-time-wrap gap-2 ${
                                        getValue(params, `id`, "")
                                          ? "only-time-wrap"
                                          : ""
                                      }`}
                                    >
                                      {!getValue(params, `id`, "") && (
                                        <DatePicker
                                          selected={toDate}
                                          onChange={(date: Date | null) =>
                                            setToDate(date)
                                          }
                                          dateFormat="d MMMM, yyyy"
                                          placeholderText="Select To Date"
                                          className="custom-form-control form-control"
                                          minDate={fromDate} // Set minimum date to fromDate
                                          disabled={!fromDate} // Disable if fromDate is not selected
                                        />
                                      )}

                                      {!getValue(params, `id`, "") && (
                                        <DatePicker
                                          selected={toTime}
                                          onChange={(time: Date | null) =>
                                            setToTime(time)
                                          }
                                          showTimeSelect
                                          showTimeSelectOnly
                                          timeIntervals={60}
                                          timeCaption="Time"
                                          dateFormat="p"
                                          placeholderText="Select To Time"
                                          className="custom-form-control form-control"
                                        />
                                      )}

                                      {getValue(params, `id`, "") && (
                                        <DatePicker
                                          selected={toTime}
                                          onChange={(time: Date | null) =>
                                            setToTime(time)
                                          }
                                          showTimeSelect
                                          showTimeSelectOnly
                                          timeIntervals={60}
                                          timeCaption="Time"
                                          dateFormat="p"
                                          placeholderText="Select To Time"
                                          className="custom-form-control form-control"
                                          minTime={getMinTime()}
                                          maxTime={
                                            new Date(
                                              new Date().setHours(23, 59)
                                            )
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {/* To Time Picker */}
                                {/* <div className="form_wrapper-pipeline">
                                  <div className="form_builder_field w-auto">
                                    <label className="form-label-error">
                                      To Time *
                                    </label>
                                    {!getValue(params, `id`, "") && (

                                    <DatePicker
                                      selected={toTime}
                                      onChange={(time: Date | null) =>
                                        setToTime(time)
                                      }
                                      showTimeSelect
                                      showTimeSelectOnly
                                      timeIntervals={60}
                                      timeCaption="Time"
                                      dateFormat="p"
                                      placeholderText="Select To Time"
                                      className="custom-form-control form-control"
                                    />)}

                                    {getValue(params, `id`, "") && (
                                      <DatePicker
                                        selected={toTime}
                                        onChange={(time: Date | null) =>
                                          setToTime(time)
                                        }
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={60}
                                        timeCaption="Time"
                                        dateFormat="p"
                                        placeholderText="Select To Time"
                                        className="custom-form-control form-control"
                                        minTime={getMinTime()}
                                        maxTime={
                                          new Date(new Date().setHours(23, 59))
                                        }
                                      />
                                    )}
                                  </div>
                                </div> */}

                                {getValue(params, `id`, "") && (
                                  <div className={`form_wrapper-pipeline `}>
                                    <div className="form_builder_field w-auto">
                                      <label className="form-label">
                                        Duration
                                      </label>
                                      <div className="position-relative">
                                        <InputField
                                          inputType="TEXT"
                                          name="duration"
                                          value={duration}
                                          onChange={(e: any) => {
                                            setTriggerAPI(false);
                                            setDistance(handleChange(e));
                                          }}
                                          placeholder={"Enter duration"}
                                          width={"100%"}
                                          label="Duration"
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {getValue(perDiemExpenseRateList, `length`, 0) >
                                  0 && (
                                  <>
                                    <div
                                      onClick={() =>
                                        handleExpesePerDiemRateListOpen()
                                      }
                                      className="text-primary mb-2 cursor-pointer"
                                    >
                                      <b>
                                        {getValue(
                                          perDiemExpenseRateList,
                                          `length`,
                                          0
                                        )}{" "}
                                      </b>{" "}
                                      Entries Created
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          <FormBuilder
                            fields={form}
                            setFields={setForm}
                            formOptions={formInfo}
                            simpleValidator={simpleValidator}
                            mainInfo={mainInfo}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          <DeleteModal
            isOpen={isDelete}
            handleModal={handleDelete}
            handleSubmit={handleDeleteFunction}
            deleteValue={deleteValue}
          />

          {/* <CreateReportPopup
            isOpen={isOpen}
            isToggle={isToggle}
            form={reportForm}
            setForm={setReportForm}
            formInfo={reportFormInfo}
            simpleValidator={simpleValidator}
          /> */}

          <ReportsPopup
            showUpdatePopup={showUpdatePopup}
            setShowUpdatePopup={setShowUpdatePopup}
            form={reportForm}
            setForm={setReportForm}
            formInfo={reportFormInfo}
            simpleValidator={simpleValidator1}
            // handleSubmit={handleSubmitSaveReport}
            handleSubmit={
              createReportClicked
                ? createReportNewFromDropdown
                : handleSubmitSaveReport
            }
            submitLoading={submitLoadingReport}
          />

          <ExpensePerDiemRateListPopup
            isOpen={isExpesePerDiemRateListOpen}
            handleModal={handleExpesePerDiemRateListOpen}
            perDiemExpenseRateListInfo={perDiemExpenseRateListInfo}
          />
        </>
      )}
    </DashboardLayout>
  );
}

export default ClientExpenseCreate;
