import React from "react";
import ReportTitleHeader from "./report-title-header";
import ReportTabNavigation from "./report-tab-navigation";
import ReportExpenseHeaderSection from "./report-expense-header";
import { getValue } from "@utils/lodash";
import ReportExpenseListSection from "./report-expense-list";
import { convertCurrentDate } from "@common/date-helpers";
import EyeSvgComponent from "@assets/svg-components/eye";
import ReimbursementTooltipOnHover from "@components/Reports/tooltip-on-hover";
import { Alert } from "reactstrap";

function ReportsLeftSection(props: any) {
  const {
    formInfo,
    params,
    setShowUpdatePopup,
    showUpdatePopup,
    list,
    handleCheckUsers,
    selectedIDs,
    handleNavigate,
    handleDeleteFun,
    handleEditExpense,
    openGallery,
    hideExpenseHeader,
  } = props;
  return (
    <div>
      <div className={"report-detail-wrapper_left_container"}>
        <ReportTitleHeader formInfo={formInfo} />
        {getValue(formInfo, `is_policy_violated`, false) && (
          <>
            <div className="is_policy_violated_wrap" style={{padding: "0 26px"}}>
            <Alert color="primary">
              You have violated the payment policy you! You have exceeded the expense amount 
            </Alert>

                <p></p>
            </div>
          </>
        )}

        {getValue(formInfo, `approval_status`, "") === "reimbursed" && (
          <div className="px-3 mt-3">
            <div className="reimbursement_container">
              <div className="reimbursement_container_header p-3 d-flex align-items-center justify-content-between">
                <h6 className="header_text__16 color_success">
                  Reimbursement details
                </h6>
                <ReimbursementTooltipOnHover
                  header={
                    <div>
                      <h6 className="header_text__16 color_success">
                        View Summary
                      </h6>
                    </div>
                  }
                  show
                  background={"#fff"}
                  width={"400px"}
                  formInfo={formInfo}
                />
              </div>
              <div className="d-flex justify-content-around p-3">
                <div>
                  <p className="small_text__14">Reimbursed Date:</p>
                  <h6 className="header_text__16">
                    {convertCurrentDate(
                      getValue(formInfo, `reimbursement.created_at`, "")
                    )}
                  </h6>
                </div>
                <div>
                  <p className="small_text__14">Reference#:</p>
                  <h6 className="header_text__16">
                    {getValue(formInfo, `reimbursement.reference_number`, "")}
                  </h6>
                </div>
                <div>
                  <p className="small_text__14">Paid Through:</p>
                  <h6 className="header_text__16">
                    {getValue(formInfo, `reimbursement.paid_through`, "")}
                  </h6>
                </div>
                <div>
                  <p className="small_text__14">Notes:</p>
                  <h6 className="header_text__16">
                    {getValue(formInfo, `reimbursement.note`, "")}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        )}
        <ReportTabNavigation length={getValue(list, `length`, 0)} />

        {getValue(props, `permissions`, []).includes("update") &&
          !hideExpenseHeader && (
            <ReportExpenseHeaderSection
              params={params}
              setShowUpdatePopup={setShowUpdatePopup}
              showUpdatePopup={showUpdatePopup}
              formInfo={formInfo}
              disabled={
                getValue(formInfo, `approval_status`, "") === "rejected" ||
                getValue(formInfo, `approval_status`, "") ===
                  "awaiting_approval" ||
                getValue(formInfo, `approval_status`, "") === "approved"
                  ? true
                  : false
              }
            />
          )}
        <div className="mt-2">
          <ReportExpenseListSection
            list={list}
            hideExpenseHeader={hideExpenseHeader}
            handleCheckUsers={handleCheckUsers}
            selectedIDs={selectedIDs}
            handleNavigate={handleNavigate}
            handleDeleteFun={handleDeleteFun}
            formInfo={formInfo}
            handleEditExpense={handleEditExpense}
            openGallery={openGallery}
            getData={props.getData}
            getFormData={props.getFormData}
            getList={props.getList}
            getAssociatedExpenseList={props.getAssociatedExpenseList}
          
          />
        </div>
      </div>
    </div>
  );
}

export default ReportsLeftSection;
