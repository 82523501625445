import { getValue } from "@utils/lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // padding: "10px",
  borderWidth: 1,
  borderRadius: 2,
  borderColor: "#dadce0",
  borderStyle: "dashed",
  backgroundColor: "#F7FBFF",
  color: "#bdbdbd",
  height: "50px",
  justifyContent: "center",
  transition: "border .3s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#adff2f",
};

const rejectStyle = {
  borderColor: "red",
};

function DragAndDropFileComponentModal(props) {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    if (files.length + acceptedFiles.length > 5) {
      toast.error("You can only upload a maximum of 5 files.");
      return;
    }

    props.uploadFile(acceptedFiles);
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      // Handle invalid files and show toast errors
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach((error) => {
          toast.error(`File "${file.name}" was rejected: Format is not allowed`);
        });
      });
  
      // Further handling for accepted files
      if (acceptedFiles.length > 0) {
        // Your logic for handling accepted files
        props.uploadFile(acceptedFiles);
      }
    },
    accept: ".csv, .txt, .xlsx", // Restrict allowed file types 
    validator: (file) => {
      // Custom validation logic for blocking MP4 files
      // if (file.type === "video/mp4") {
      //   return {
      //     code: "file-invalid-type",
      //     // message: "MP4 files are not allowed.",
      //   };
      // }

      if (file.type === "video/mp4" || file.type === "audio/mpeg") {
        return {
          code: "file-invalid-type",
          // message: "MP4/MP3 files are not allowed",
        };
      }

      return null; // Return null for valid files
    },  
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const thumbs = acceptedFiles.map((file) => (
    <div key={file.name}>
      <p>{file.name} </p>
    </div>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map((e) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    );
  });
  // clean up
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  return (
    <div className="m-5" onClick={props.handleClose}>
      <p className="desc_text mb-3">
        {/* You can upload maximum of 5 files */}
        You can upload files 
      </p>
      <section className="cursor-pointer">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <div className="custom-inputfile">
            {/* <img
            src="/images/nofiles.png"
            className="img-fluid mb-2"
            height={25}
            width={25}
          /> */}
            <p className="text-center">Upload File</p>
          </div>
        </div>
      </section>
      <p style={{ color: "red", marginTop: "10px" }}> {fileRejectionItems} </p>

    </div>
  );
}

export default DragAndDropFileComponentModal;
