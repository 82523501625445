import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { getValue } from "@utils/lodash";
import "./LeftNavigation.scss";
import { useLocation } from "react-router-dom";
import DashboardSvgComponent from "@assets/svg-components/sidebar/dashboard";
import ExpenseSvgComponent from "@assets/svg-components/sidebar/expense";
import ReportsSvgComponent from "@assets/svg-components/sidebar/reports";
import ApprovalSvgComponent from "@assets/svg-components/sidebar/approval";
import SettingsSvgComponent from "@assets/svg-components/sidebar/settings";
import OrganisationsSvgComponent from "@assets/svg-components/sidebar/organisation";
import ListmanagementSvgComponent from "@assets/svg-components/sidebar/listmanagement";
import ModulesSvgComponent from "@assets/svg-components/sidebar/modules";
import UsercontrolSvgComponent from "@assets/svg-components/sidebar/usercontrol";
import TripsSvgComponent from "@assets/svg-components/dashboard/trips";
import AnalyticsSvgComponent from "@assets/svg-components/analytics";
import { useNavigationData } from "../LeftNavbar/NavigationContext";

function optimizeNavigationMenus(
  navigationMenu: any,
  permissions: string[],
  subTabPermissionList: string[]
): any {
  return navigationMenu
    .filter((item: any) => permissions.includes(item.name))
    .map((item: any) => {
      const subMenu = (item.subMenu || []).filter((subItem: any) =>
        subTabPermissionList.includes(subItem.name)
      );
      if (subMenu.length > 0) {
        return {
          icon: item.icon,
          name: item.name,
          parentLink: item.parentLink,
          title: item.title,
          subMenu,
        };
      } else {
        return { ...item };
      }
    });
}

export default function LeftNavigation(props: any) {
  const location = useLocation();
  const [clientNavigationMenu] = useState([
    {
      title: "DashBoard",
      icon: "/images/navigation-icons/dashboard.svg",
      link: "/",
      parentLink: "/",
      name: "dashboard",
    },
    {
      title: "Trips",
      icon: "/images/navigation-icons/receipt.svg",
      link: "/client/trips",
      parentLink: "/client/trips",
      name: "trips",
    },
    // {
    //   title: "Expenses",
    //   icon: "/images/navigation-icons/receipt.svg",
    //   link: "/client/expenses",
    //   parentLink: "/client/expenses",
    //   name: "expenses",
    // },
    {
      title: "Expense Reports",
      icon: "/images/navigation-icons/reports.svg",
      link: "/client/reports",
      parentLink: "/client/reports",
      name: "reports",
    },
    {
      title: "Approvals",
      icon: "/images/navigation-icons/reports.svg",
      link: "/client/approval",
      parentLink: "/client/approval",
      name: "approvals",
      subMenu: [
        {
          title: "Trips",
          link: "/client/approval/trips",
          name: "approvals_trips",
        },
        {
          title: "Reports",
          link: "/client/approval/reports",
          name: "approvals_reports",
        },
      ],
    },
    {
      title: "Settings",
      icon: "/images/navigation-icons/settings.svg",
      link: "/client/settings",
      parentLink: "/client/settings",
      name: "settings",
      subMenu: [
        {
          title: "Personal Information",
          link: "/client/settings/personal-information",
          name: "settings_personal_information",
        },
        {
          title: "Travel Profile",
          link: "/client/settings/travel-profile",
          name: "settings_travel_profile",
        },
        {
          title: "Travel Documents",
          link: "/client/settings/travel-documents",
          name: "settings_travel_documents",
        },
      ],
    },
  ]);
  const [adminNavigationMenu] = useState([
    {
      title: "DashBoard",
      icon: "/images/navigation-icons/dashboard.svg",
      link: "/admin/dashboard",
      parentLink: "/admin/dashboard",
      name: "admin_dashboard",
    },
    {
      title: "Trips",
      icon: "/images/navigation-icons/receipt.svg",
      link: "/admin/trips",
      parentLink: "/admin/trips",
      name: "admin_trips",
    },
    {
      title: "Reports",
      icon: "/images/navigation-icons/reports.svg",
      link: "/admin/reports",
      parentLink: "/admin/reports",
      name: "admin_reports",
    },
    {
      title: "Organisations",
      icon: "/images/navigation-icons/org.svg",
      link: "/admin/organisation/profile",
      parentLink: "/admin/organisation",
      name: "admin_organisations",
      subMenu: [
        {
          title: "Org Profile",
          link: "/admin/organisation/profile",
          name: "admin_organisations_org_profile",
        },
        {
          title: "Currencies",
          link: "/admin/organisation/currencies",
          name: "admin_organisations_currencies",
        },
      ],
    },
    {
      title: "User Controls",
      icon: "/images/navigation-icons/user-control.svg",
      link: "/admin/user-controls/users",
      parentLink: "/admin/user-controls",
      name: "admin_user_controls",
      subMenu: [
        {
          title: "Users",
          link: "/admin/user-controls/users",
          name: "admin_user_controls_users",
        },
        {
          title: "Roles & Permissions",
          link: "/admin/user-controls/roles",
          name: "admin_user_controls_roles_permission",
        },
        {
          title: "Departments",
          link: "/admin/user-controls/departments",
          name: "admin_user_controls_departments",
        },
        {
          title: "Policies",
          link: "/admin/user-controls/policies",
          name: "admin_user_controls_policies",
        },
        {
          title: "Email Templates",
          link: "/admin/user-controls/email-templates",
          name: "admin_user_controls_email_templates",
        },
        {
          title: "Reminders",
          link: "/admin/user-controls/reminders",
          name: "admin_user_controls_email_templates",
        },
      ],
    },
    {
      title: "Modules",
      icon: "/images/leftnav/tasks.svg",
      link: "/admin/modules/trips",
      parentLink: "/admin/modules",
      name: "admin_modules",
      subMenu: [
        {
          title: "Trips",
          link: "/admin/modules/trips/fields",
          name: "admin_modules_trips",
        },
        {
          title: "Expenses",
          link: "/admin/modules/expenses/fields",
          name: "admin_modules_expenses",
        },
        {
          title: "Reports",
          link: "/admin/modules/reports/fields",
          name: "admin_modules_reports",
        },
        {
          title: "Users",
          link: "/admin/modules/users/fields",
          name: "admin_modules_users",
        },
        {
          title: "Paid Through Accounts",
          link: "/admin/modules/paid-through-accounts",
          name: "admin_modules_paid_through_accounts",
        },
        // {
        //   title: "Customers",
        //   link: "/admin/modules/customers",
        //   name: "reports",
        // },
        {
          title: "Projects",
          link: "/admin/modules/projects",
          name: "admin_modules_projects",
        },
      ],
    },
    {
      title: "List Management",
      icon: "/images/leftnav/list-management.svg",
      link: "/admin/list-management/choice-list",
      parentLink: "/admin/list-management",
      name: "admin_list_management",
      subMenu: [
        {
          title: "Choice List",
          link: "/admin/list-management/choice-list",
          name: "admin_list_management_choice_list",
        },
      ],
    },
    {
      title: "Settings",
      icon: "/images/navigation-icons/settings.svg",
      link: "/admin/settings/approval",
      parentLink: "/admin/settings",
      name: "admin_settings",
      subMenu: [
        {
          title: "Approval",
          link: "/admin/settings/approval",
          name: "admin_settings_approval_flows",
        },

        // {
        //   title: "Basic Information",
        //   link: "/client/settings/basic-information",
        //   name: "profile",
        // },
        {
          title: "Travel Documents",
          link: "/admin/settings/travel-documents",
          name: "admin_settings_travel_documents",
        },
      ],
    },
    {
      title: "Analytics",
      icon: "/images/navigation-icons/receipt.svg",
      link: "/admin/analytics",
      parentLink: "/admin/analytics",
      name: "expenses",
    },
  ]);

  const {
    clientNavigation,
    setClientNavigation,
    adminNavigation,
    setAdminNavigation,
    isLoading,
    setIsLoading,
  } = useNavigationData();

  const getIcons = (name: string, color: string) => {
    const iconMap: any = {
      dashboard: <DashboardSvgComponent color={color} />,
      admin_dashboard: <DashboardSvgComponent color={color} />,
      expenses: <ExpenseSvgComponent color={color} />,
      admin_reports: <ReportsSvgComponent color={color} />,
      reports: <ReportsSvgComponent color={color} />,
      approvals: <ApprovalSvgComponent />,
      approvals_trips: <ApprovalSvgComponent />,
      settings: <SettingsSvgComponent color={color} size={18} />,
      admin_settings: <SettingsSvgComponent color={color} size={20} />,
      admin_organisations: <OrganisationsSvgComponent color={color} />,
      admin_list_management: <ListmanagementSvgComponent color={color} />,
      admin_modules_trips: <ModulesSvgComponent color={color} />,
      modules: <ModulesSvgComponent color={color} />,
      admin_user_controls: <UsercontrolSvgComponent color={color} />,
      usercontrols: <UsercontrolSvgComponent color={color} />,
      admin_trips: <TripsSvgComponent color={color} size={16} />,
      trips: <TripsSvgComponent color={color} size={18} />,
      analytics: <AnalyticsSvgComponent color={color} size={16} />,
    };

    return iconMap[name] || <DashboardSvgComponent color={color} />;
  };
  // const [clientNavigation, setClientNavigation] = useState<any>([]);
  // const [adminNavigation, setAdminNavigation] = useState<any>([]);
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    const clientNav = optimizeNavigationMenus(
      clientNavigationMenu,
      props.permissions,
      props.subTabPermissionList
    );
    const adminNav = optimizeNavigationMenus(
      adminNavigationMenu,
      props.permissions,
      props.subTabPermissionList
    );
    setClientNavigation(clientNav);
    setAdminNavigation(adminNav);
    setIsLoading(false);
  }, [props.permissions, props.subTabPermissionList]);

  return (
    <nav className="navigation-menu">
      {getValue(localStorage, `view`, "") === "admin" ? (
        <ul>
          {adminNavigation.map((menuObj: any, index: number) => {
            return (
              <>
                <li
                  className={`navigation-menu__menu-item ${
                    !getValue(menuObj, `subMenu`, "")
                      ? "navigation-menu__no-sub-menu-item"
                      : ""
                  }
                 ${
                   !getValue(menuObj, `subMenu`, "") &&
                   window.location.pathname ===
                     getValue(menuObj, `parentLink`, "")
                     ? "active"
                     : ""
                 }`}
                >
                  <label
                    className={`d-flex w-100 align-items-center ${
                      getValue(menuObj, `subMenu`, "") ? "cursor-pointer" : ""
                    }`}
                    htmlFor={`nav-menu-${index}`}
                  >
                    <div className="navigation-menu__menu-icon">
                      {/* <img
                        src={menuObj.icon}
                        className="material-symbols-outlined img-fluid"
                      /> */}
                      {getIcons(
                        getValue(menuObj, `name`, ""),
                        window.location.pathname ===
                          getValue(menuObj, `parentLink`, "")
                          ? "#D7242E"
                          : "#3C4043"
                      )}
                    </div>
                    <div className="d-flex align-items-flex-start justify-content-between navigation-menu__menu-elements">
                      {getValue(menuObj, `subMenu`, "") ? (
                        <a className="nav-link">{menuObj.title}</a>
                      ) : (
                        <NavLink
                          to={getValue(menuObj, `link`, "")}
                          className="nav-link w-100"
                        >
                          <a
                            style={{
                              color:
                                window.location.pathname ===
                                getValue(menuObj, `parentLink`, "")
                                  ? "#D7242E"
                                  : "#3C4043",
                            }}
                          >
                            {menuObj.title}
                          </a>
                        </NavLink>
                      )}

                      {getValue(menuObj, `subMenu`, "") && (
                        <img
                          src="/images/arrow-down.svg"
                          className="img-fluid dropdown-arrow cursor-pointer"
                        />
                      )}
                    </div>
                  </label>
                  {getValue(menuObj, `subMenu`, "") && (
                    <input
                      type="checkbox"
                      id={`nav-menu-${index}`}
                      name="navigation-menu"
                      className="left-navigation__checkbox d-none"
                      defaultChecked={location.pathname.includes(
                        menuObj.parentLink
                      )}
                    />
                  )}
                  {getValue(menuObj, `subMenu`, "") && (
                    <ul className={`navigation-menu__sub-menu`}>
                      {getValue(menuObj, `subMenu`, "")?.map(
                        (subMenuObj: any, index: number) => {
                          let detailRoute = window.location.pathname.split(
                            `${getValue(subMenuObj, `link`, "")}`
                          );
                          return (
                            <NavLink
                              to={getValue(subMenuObj, `link`, "")}
                              key={`navigation-menu__sub-menu-${index}`}
                            >
                              <li
                                className={`${index} navigation-menu__sub-menu-link
                             ${
                               (window.location.pathname ===
                                 getValue(subMenuObj, `link`, "") ||
                                 !getValue(detailRoute, `[${0}]`, "")) &&
                               "active"
                             }`}
                              >
                                <span>{subMenuObj.title}</span>
                              </li>
                            </NavLink>
                          );
                        }
                      )}
                    </ul>
                  )}
                </li>
              </>
            );
          })}
        </ul>
      ) : (
        <ul>
          {clientNavigation.map((menuObj: any, index: any) => {
            return (
              <>
                <li
                  className={`navigation-menu__menu-item ${
                    !getValue(menuObj, `subMenu`, "")
                      ? "navigation-menu__no-sub-menu-item"
                      : ""
                  }
								${
                  !getValue(menuObj, `subMenu`, "") &&
                  window.location.pathname ===
                    getValue(menuObj, `parentLink`, "")
                    ? "active"
                    : ""
                }`}
                >
                  <label
                    className={`d-flex w-100 align-items-center ${
                      getValue(menuObj, `subMenu`, "") ? "cursor-pointer" : ""
                    }`}
                    htmlFor={`nav-menu-${index}`}
                  >
                    <div className="navigation-menu__menu-icon">
                      {/* <img
                        src={menuObj.icon}
                        className="material-symbols-outlined img-fluid"
                      /> */}
                      {getIcons(
                        getValue(menuObj, `name`, ""),
                        window.location.pathname ===
                          getValue(menuObj, `parentLink`, "")
                          ? "#D7242E"
                          : "#3C4043"
                      )}
                    </div>
                    <div className="d-flex align-items-center justify-content-between navigation-menu__menu-elements">
                      {getValue(menuObj, `subMenu`, "") ? (
                        <a className="nav-link">{menuObj.title}</a>
                      ) : (
                        <NavLink
                          to={getValue(menuObj, `link`, "")}
                          className="nav-link w-100"
                        >
                          <a
                            style={{
                              color:
                                window.location.pathname ===
                                getValue(menuObj, `parentLink`, "")
                                  ? "#D7242E"
                                  : "#3C4043",
                            }}
                          >
                            {menuObj.title}
                          </a>
                        </NavLink>
                      )}

                      {getValue(menuObj, `subMenu`, "") && (
                        <img
                          src="/images/arrow-down.svg"
                          className="img-fluid dropdown-arrow cursor-pointer"
                        />
                      )}
                    </div>
                  </label>
                  {getValue(menuObj, `subMenu`, "") && (
                    <input
                      type="checkbox"
                      id={`nav-menu-${index}`}
                      name="navigation-menu"
                      className="left-navigation__checkbox d-none"
                      defaultChecked={location.pathname.includes(
                        menuObj.parentLink
                      )}
                    />
                  )}
                  {getValue(menuObj, `subMenu`, "") && (
                    <ul className={`navigation-menu__sub-menu`}>
                      {getValue(menuObj, `subMenu`, "")?.map(
                        (subMenuObj: any, index: number) => {
                          let detailRoute = window.location.pathname.split(
                            `${getValue(subMenuObj, `link`, "")}`
                          );
                          return (
                            <NavLink
                              to={getValue(subMenuObj, `link`, "")}
                              key={`navigation-menu__sub-menu-${index}`}
                            >
                              <li
                                className={`${index} navigation-menu__sub-menu-link
                                ${
                                  (window.location.pathname ===
                                    getValue(subMenuObj, `link`, "") ||
                                    !getValue(detailRoute, `[${0}]`, "")) &&
                                  "active"
                                }`}
                              >
                                <span>{subMenuObj.title}</span>
                              </li>
                            </NavLink>
                          );
                        }
                      )}
                    </ul>
                  )}
                </li>
              </>
            );
          })}
        </ul>
      )}
    </nav>
  );
}
