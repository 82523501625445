import { convertCurrentDate } from "@common/date-helpers";
import Loader from "@components/common/Loader";
import { getValue } from "@utils/lodash";
import DocumentSection from "./components/DocumentSection";
import { getFirstLetter } from "@common/text-helpers";
import ProfileCircle from "@components/ProfileCircle";
import ProfileCircleReport from "@components/ProfileCircleReport";

function ReportsRightSection(props: any) {
  const {
    formInfo,
    setShowApprovalPopup,
    isLoading,
    documents,
    handleUploadDocuments,
    uploadLoading,
    handleDeleteFun1,
    openGallery,
    hideUpload
  } = props;
  return (
    <div>
      <div className="right_detail_container_wrap">
        <p className="small_text right_detail_container_created_by">
          Created By
        </p>
        <div className="d-flex align-items-center">
          <ProfileCircle
            name="created_by"
            item={formInfo}
            value={getValue(formInfo, `created_by.first_name`, "")}
          />
          <div className="right_detail_container_profile-wrap">
            <p className="header_text">
              {getValue(formInfo, `created_by.first_name`, "")}{" "}
              {getValue(formInfo, `created_by.middle_name`, "")}{" "}
              {getValue(formInfo, `created_by.last_name`, "")}
            </p>
            <p className="small_text profile-wrap-email">
              {getValue(formInfo, `created_by.email`, "")}
            </p>
            <p className="small_text">
              Created on :{" "}
              {convertCurrentDate(getValue(props, `formInfo.created_at`, ""))}
            </p>
          </div>
        </div>
        {getValue(formInfo, `current_approver.email`, "") && (
          <>
            <div className="border_bottom report-right-details-border-bottom-spacing"></div>

            <p className="small_text right_detail_container_created_by">
              Current Approver
            </p>
            <div className="d-flex align-items-center">
              <ProfileCircle
                name="current_approver"
                item={formInfo}
                value={getValue(formInfo, `current_approver.first_name`, "")}
              />
              <div className="">
                <p className="header_text">
                  {getValue(formInfo, `current_approver.first_name`, "")}{" "}
                  {getValue(formInfo, `current_approver.middle_name`, "")}{" "}
                  {getValue(formInfo, `current_approver.last_name`, "")}
                </p>
                <p className="small_text">
                  {getValue(formInfo, `current_approver.email`, "")}
                </p>
              </div>
            </div>
          </>
        )}
        {getValue(formInfo, `approvers.length`, 0) > 0 ? (
          <div
            className="cursor-pointer view-approver-flow-wrap-reports"
            onClick={() => setShowApprovalPopup(true)}
          >
            {/* <h6 className="small_text__14 text-center color_black border border-radius__5 p-2 box_shadow">
              View approver flow
            </h6> */}
            <h6 className="small_text__14 color_primary">View approver flow</h6>
          </div>
        ) : null}

        {getValue(formInfo, `trip.trip_id`, "") ? (
          <>
            <div className="border_bottom report-right-details-border-bottom-spacing"></div>
            <div className="reports-right-label-value-holder">
              <p className="desc_text">Trips Associated</p>
              <div className="d-flex align-items-center">
                {/* <OrganisationsSvgComponent size={22} color={"#408dfb"} /> */}
                <p
                  className="header_text cursor-pointer color_primary"
                  onClick={props.toggleTripDetail}
                >
                  {getValue(formInfo, `trip.trip_id`, "")}
                </p>
              </div>
            </div>
          </>
        ) : null}

        {getValue(formInfo, `business_purpose`, "") ? (
          <>
            <div className="border_bottom report-right-details-border-bottom-spacing"></div>

            <div className="reports-right-label-value-holder">
              <p className="desc_text">Business Purpose</p>
              <div className="d-flex align-items-center">
                {/* <ModulesSvgComponent size={22} color={"#408dfb"} /> */}
                <p className="header_text">
                  {getValue(formInfo, `business_purpose`, "")}
                </p>
              </div>
            </div>
          </>
        ) : null}

        {getValue(formInfo, `project.project_name`, "") ? (
          <>
            <div className="border_bottom report-right-details-border-bottom-spacing"></div>

            <div className="reports-right-label-value-holder">
              <p className="desc_text">Project</p>
              <div className="d-flex align-items-center">
                {/* <OrganisationsSvgComponent size={22} color={"#408dfb"} /> */}
                <p className="header_text">
                  {getValue(formInfo, `project.project_name`, "")}
                </p>
              </div>
            </div>
          </>
        ) : null}

        {getValue(formInfo, `created_by.policy.policy_name`, "") ? (
          <>
            <div className="border_bottom report-right-details-border-bottom-spacing"></div>
            <div className="reports-right-label-value-holder">
              <p className="desc_text">Policy</p>
              <div className="d-flex align-items-center">
                {/* <OrganisationsSvgComponent size={22} color={"#408dfb"} /> */}
                <p className="header_text">
                  {getValue(formInfo, `created_by.policy.policy_name`, "")}
                </p>
              </div>
            </div>
          </>
        ) : null}

        {/* <div className="mt-4"></div> */}

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="border_bottom report-right-details-border-bottom-spacing"></div>
            <DocumentSection
              documents={documents}
              handleUploadDocuments={handleUploadDocuments}
              uploadLoading={uploadLoading}
              handleRemoveDocuments={handleDeleteFun1}
              openGallery={openGallery}
              permissions={getValue(props, `permissions`, [])}
              reports={props.reports}
              hideUpload={hideUpload}
              formInfo={formInfo}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default ReportsRightSection;
