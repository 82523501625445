import { getValue } from "@utils/lodash";
import TripsArrowSvgComponent from "@assets/svg-components/trips/tripsarrow";
import Notfound from "@components/Not-Found";
import {
  convertCurrentDate,
  convertCurrentDateWithTime,
  convertCurrentTime,
} from "@common/date-helpers";
import LocationSvgComponent from "@assets/svg-components/trips/location";
import BusSvgComponent from "@assets/svg-components/trips/bus";
import NotfoundSmall from "@components/Not-Found/not-found-small";
import { sortJSONObjectArray } from "@common/text-helpers";

function TripsBusItinary(props: any) {
  return (
    <div className="itenary_main_section">
      <div className="itenary_main_section_cards_holder">
        {getValue(props, `list.length`, 0) > 0 ? (
          sortJSONObjectArray(getValue(props, `list`, []), "order").map(
            (item: object, index: number) => {
              return (
                <div className="flight_trip_itenary border border-radius__5 position-relative" key={index}>
                  <div className="d-flex align-items-center justify-content-center flight_trip_itenary_tripname_wrapper">
                  <div className="itenary-flight-icon-wrapper">
                    <BusSvgComponent size={24} />
                  </div>
                    <div className="d-flex align-items-center justify-content-center">
                      <h5 className="header_text__16 flight_trip_itenary_tripname text_danger">
                        {convertCurrentDate(
                          getValue(item, `bus.departure_date`, "")
                        )}
                      </h5>
                    </div>
                  </div>

                  <div className="dashed-border"></div>
                  
                  <div className="d-flex align-items-center justify-content-center train_trip_itenary_trip_detail">
                    <div className="train_trip_departure text-right">
                      <div className="d-flex align-items-center justify-content-end">
                        <h6 className="header_text__16 train_trip_departure_header">
                          {getValue(item, `bus.departure_city.name`, "")}
                        </h6>
                      </div>
                      <p className="small_text__12 train_itenary_details_label">
                        Departure
                      </p>
                    </div>

                    <div className="train_trip_itenary_centerarrow_holder">
                      <TripsArrowSvgComponent />
                    </div>

                    <div className="train_trip_arrival text-left">
                      <div className="d-flex align-items-center justify-content-start">
                        <h6 className="header_text__16 train_trip_departure_header">
                          {getValue(item, `bus.arrival_city.name`, "")}
                        </h6>
                      </div>
                      <p className="small_text__12 train_itenary_details_label">
                        Arrival
                      </p>
                    </div>
                  </div>
                  {getValue(item, `description`, "") && (
                    <div
                      style={{ background: "#E8F0FE" }}
                      className="p-2 mt-2 border-radius__5"
                    >
                      <p style={{ color: "#204891" }} className="small_text">
                        {getValue(item, `description`, "")}
                      </p>
                    </div>
                  )}
                </div>
              );
            }
          )
        ) : (
          <NotfoundSmall />
        )}
      </div>
    </div>
  );
}

export default TripsBusItinary;
