import DocumentImageList from "@common/FileUpload/document-image-list";
import UploadLoader from "@components/common/UploadLoader";
import ExpenseUpload from "@pages/Client/Expense/Detail/DetailContainer/ExpenseUpload";
import { getValue } from "@utils/lodash";
import React from "react";

function DocumentSection(props: any) {
  const {
    documents,
    handleUploadDocuments,
    uploadLoading,
    handleRemoveDocuments,
    hideUpload, 
    formInfo
  } = props;


  return (
    <div>
      <div className="d-flex align-items-center w-100">
        {getValue(documents, `length`, 0) < 5 && (
          <ExpenseUpload
            handleUploadDocuments={handleUploadDocuments}
            permissions={getValue(props, `permissions`, [])}
            reports={props.reports}
            hideUpload={hideUpload}
            formInfo={formInfo}
          />
        )}
      </div>
      {uploadLoading ? (
        // <p className="text-center mt-5 mb-3">Uploading....</p>
        <div className="mt-3">
          <UploadLoader />
        </div>
      ) : getValue(documents, `length`, 0) === 0 ? (
        <p className="mt-5 text-center">No document found</p>
      ) : (
        <div className="report-detail-wrapper_report_documentsection">
          <div className="d-flex  mt-3" style={{ flexWrap: "wrap" }}>
            <DocumentImageList
              documents={documents}
              containerStayle={{ height: "100px", width: "150px" }}
              textContainerStayle={{ height: "40px", width: "150px" }}
              textStyle={{ fontSize: "12px" }}
              length={10}
              handleRemoveDocuments={handleRemoveDocuments}
              hideUpload={hideUpload}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default DocumentSection;
