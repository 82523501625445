import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import ModulesNavigation from "../common/sub-header";
import React, { useEffect, useRef, useState } from "react";
import SearchHeader from "@components/common/SearchHeader";
import { useNavigate } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  createTraveler,
  deleteTravelDocument,
  getAllTravelDocuments,
  getAllTraveler,
  updateTraveler,
} from "@services/settings.service";
import { toast } from "react-toastify";
import {
  getlocationManagementCities,
  getlocationManagementCountries,
  getlocationManagementStates,
} from "@services/list-management.service";
import SimpleReactValidator from "simple-react-validator";
import InputField from "@common/InputRuleFeild";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import {
  convertElementaryArrayToJSONArray,
  formatText,
  removeEmptyProperties,
  removeNullOrUndefinedPropertiesKeepArray,
} from "@common/text-helpers";
import "./index.scss";
import { StylesConfig } from "react-select";
import {
  handleSelectArrayChange,
  handleTextArrayChange,
} from "@common/handleChange";
import StickyHeader from "./StickyHeader";
import { todayDate } from "@common/date-helpers";
import Loader from "@components/common/Loader";
import OrganisationsSvgComponent from "@assets/svg-components/sidebar/organisation";
import NoAccessPage from "@components/common/NoAccess";
function SettingsTravelProfile(props: any) {
  let navigate = useNavigate();

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = React.useState(true);
  const [list, setList] = React.useState<[]>([]);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const handleOpenPopup = () => {
    setShowUpdatePopup(!showUpdatePopup);
  };
  const [countryList, setCountryList] = useState([]);
  const [states, setStates] = useState([]);
  const [citiesList, setCityList] = useState([]);
  const intialState = {
    salutation: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    nominee_name_as_per_govt_id: "",
    relationship_with_employee: "",
    gender: "",
    date_of_birth: "",
    mobile_number: "",
    email: "",
    redress_number: "",
    known_traveler_number: "",
    fax: "",
    home_airport: "",
    home_phone: "",
    work_phone: "",
    addresses: [
      {
        address_type: "home",
        address1: "",
        address2: "",
        country: "",
        state: "",
        city: "",
        pin_code: "",
      },
      {
        address_type: "work",
        address1: "",
        address2: "",
        country: "",
        state: "",
        city: "",
        pin_code: "",
      },
    ],
  };
  const [request, setRequest] = useState<any>(intialState);
  const [id, setId] = useState("");
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length === 0 && props.permissionAPITriggered) {
      getData();
      getCountriesList();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(params).length > 0 && props.permissionAPITriggered) {
      getData();
      getCountriesList();
    }
  }, [window.location.href, props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getCountriesList = async () => {
    try {
      let resp = await getlocationManagementCountries("");
      if (resp) {
        setCountryList(
          getValue(resp, `data.countries`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `name`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };
  const getData = async () => {
    let payload: any = {};
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await getAllTraveler(queryRequest);
      if (resp) {
        console.log(resp, "---Resp---")
        setId(getValue(resp, `data.id`, ""));
        setRequest({
          ...request,
          salutation: getValue(resp, `data.salutation`, ""),
          first_name: getValue(resp, `data.first_name`, ""),
          middle_name: getValue(resp, `data.middle_name`, ""),
          last_name: getValue(resp, `data.last_name`, ""),
          nominee_name_as_per_govt_id: getValue(resp, `data.nominee_name_as_per_govt_id`,""),
          relationship_with_employee: getValue(resp, `data.relationship_with_employee`, ""),
          gender: getValue(resp, `data.gender`, ""),
          date_of_birth: todayDate(getValue(resp, `data.date_of_birth`, "")),
          mobile_number: getValue(resp, `data.mobile_number`, ""),
          email: getValue(resp, `data.email`, ""),
          redress_number: getValue(resp, `data.redress_number`, ""),
          known_traveler_number: getValue(
            resp,
            `data.known_traveler_number`,
            ""
          ),
          fax: getValue(resp, `data.fax`, ""),
          home_airport: getValue(resp, `data.home_airport`, ""),
          home_phone: getValue(resp, `data.home_phone`, ""),
          work_phone: getValue(resp, `data.work_phone`, ""),
          addresses:
            getValue(resp, `data.addresses.length`, 0) > 0
              ? getValue(resp, `data.addresses`, [])
              : [
                  {
                    address_type: "home",
                    address1: "",
                    address2: "",
                    country: "",
                    state: "",
                    city: "",
                    pin_code: "",
                  },
                  {
                    address_type: "work",
                    address1: "",
                    address2: "",
                    country: "",
                    state: "",
                    city: "",
                    pin_code: "",
                  },
                ],
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      let payload = JSON.parse(JSON.stringify(request));
      // payload.pincode = parseInt(payload.pincode);
      // payload.country = payload.country;
      // payload.state = payload.state;
      payload.addresses = removeEmptyProperties(payload.addresses);
      Object.keys(payload).forEach((key) => {
        if (!payload[key] || payload[key] === undefined) {
          delete payload[key];
        }
      });
      try {
        setSubmitLoading(true);
        let resp;
        if (id) {
          resp = await updateTraveler(id, payload);
        } else {
          resp = await createTraveler(payload);
        }
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          getData();
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  const getStatesList = async (countryId: any) => {
    let payload = {
      country_name: countryId,
    };
    let queryRequest = QueryRequestHelper(payload);
    if (countryId)
      try {
        let resp = await getlocationManagementStates(queryRequest);
        if (resp) {
          setStates(
            getValue(resp, `data.states`, []).map((item: object) => ({
              ...item,
              value: getValue(item, `name`, ""),
              label: getValue(item, `name`, ""),
            }))
          );
        } else {
        }
      } catch (error) {}
  };
  const getCitiesList = async (stateId: any) => {
    let payload = {
      state_name: stateId,
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      let resp = await getlocationManagementCities(queryRequest);
      if (resp) {
        setCityList(
          getValue(resp, `data.cities`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `name`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
      } else {
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const [selectedIDs, setSelectedIDs] = useState<any>([]);
  const handleCheckUsers = (id: string) => {
    let userIDs =
      getValue(selectedIDs, `length`, 0) > 0
        ? selectedIDs.map((item: string) => item)
        : [];
    if (userIDs.includes(id)) {
      let filterIDs = selectedIDs.filter((item: string) => item !== id);
      setSelectedIDs(filterIDs);
    } else {
      selectedIDs.push(id);
      setSelectedIDs([...selectedIDs]);
    }
  };
  const handleCheckAll = (status: string) => {
    if (status === "All") {
      setSelectedIDs([]);
    } else {
      let userIDs =
        getValue(list, `length`, 0) > 0
          ? list.map((item: object) => getValue(item, `id`, ""))
          : [];
      setSelectedIDs(userIDs);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };
  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteTravelDocument(deleteId);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChangeText = (e: any) => {
    const { name, value } = e.target;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  const handleChangeSelect = (
    index: number,
    e: any,
    name: string,
    key: string
  ) => {
    if (name === "country") {
      getStatesList(getValue(e, `id`, ""));
      handleSelectArrayChange(index, e, name, key, setRequest);
    }
    if (name === "state") {
      getCitiesList(getValue(e, `id`, ""));
      handleSelectArrayChange(index, e, name, key, setRequest);
    }
    if (name === "city") {
      handleSelectArrayChange(index, e, name, key, setRequest);
    }
  };
  const salutation = convertElementaryArrayToJSONArray([
    "Mr",
    "Mrs",
    "Ms",
    "Miss",
    "Dr",
  ]);
  const gender = convertElementaryArrayToJSONArray(["male", "female"]);
  const relationship_with_employee_list = convertElementaryArrayToJSONArray(["spouse", "father", "mother", "son", "daughter", "brother", "sister", "guardian"]);
  const customStyles: StylesConfig = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#dadce0",
      };
    },
    control: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      height: 43,
      border: state.isFocused ? "1px solid #dadce0" : "1px solid #dadce0",
      boxShadow: state.isFocused ? "0px 0px 0px #dadce0" : "none",
      "&:hover": {
        // border: "1px solid #ff8b67",
        // boxShadow: "0px 0px 6px #ff8b67"
      },
    }),
  };
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "settings_travel_profile"
      ) ? (
        <NoAccessPage />
      ) : (
        <>
          <StickyHeader
            title={"Travel Profile"}
            handleSubmit={handleSubmit}
            submitLoading={submitLoading}
            id={id}
            permissions={getValue(props, `permissions`, [])}
          />
          {isLoading || !props.permissionAPITriggered ? (
            <Loader />
          ) : (
            <div className="basic-information_container client-travel-profile-wrap">
              <div className="checkpoint-active-details-wrapper__form-wrapper">
                <div className="checkpoint-active-details-wrapper__form-content">
                  <h3 className="client-travel-profile_main-title">
                    Personal Details
                  </h3>
                  <div className="info-alert">
                    {/* Please provide your full legal name exactly as it appears on a government-issued ID that you would use for travel. This name will be used for booking your travel arrangements */}
                    Provide your full legal name as it appears on your government-issued ID for travel booking.
                  </div>
                  <div className="user-details-wrapper__form  user-details-wrapper__form--org-profile client-travel-profile-form-wrapper">
                    <div className="client-travel-profile-labelfield-wrap">
                      <label className="form-label-error">
                        Salutation <span>*</span>
                      </label>
                      <SingleSelectSearchDropdown
                        label={"label"}
                        name="salutation"
                        value={getValue(request, `salutation`, "")}
                        onChange={(e: any) =>
                          setRequest({
                            ...request,
                            salutation: getValue(e, `value`, ""),
                          })
                        }
                        data={salutation}
                        placeholder={"Choose Salutation"}
                        selectKey={"value"}
                        width={"358px"}
                        validator={simpleValidator}
                      />
                    </div>

                    <div className="client-travel-profile-labelfield-wrap">
                      <label className="form-label-error">
                        First Name <span>*</span>
                      </label>
                      <InputField
                        inputType="TEXT"
                        placeholder="Enter First Name"
                        className="form-control w-100"
                        name="first_name"
                        id="first_name"
                        value={getValue(request, `first_name`, "")}
                        onChange={(e: any) => handleChangeText(e)}
                        label="First Name"
                        validator={simpleValidator}
                      />
                    </div>
                    <div className="client-travel-profile-labelfield-wrap">
                      <label className="form-label__14">
                        Middle Name <span></span>
                      </label>
                      <InputField
                        inputType="TEXT"
                        placeholder="Enter Middle Name"
                        className="form-control w-100"
                        name="middle_name"
                        id="middle_name"
                        value={getValue(request, `middle_name`, "")}
                        onChange={(e: any) => handleChangeText(e)}
                        label="Middle Name"
                        // validator={simpleValidator}
                      />
                    </div>
                    <div className="client-travel-profile-labelfield-wrap">
                      <label className="form-label-error">
                        Last Name <span>*</span>
                      </label>
                      <InputField
                        inputType="TEXT"
                        placeholder="Enter Last Name"
                        className="form-control w-100"
                        name="last_name"
                        id="last_name"
                        value={getValue(request, `last_name`, "")}
                        onChange={(e: any) => handleChangeText(e)}
                        label="Last Name"
                        validator={simpleValidator}
                      />
                    </div>
                    <div className="client-travel-profile-labelfield-wrap">
                      <label className="form-label-error">
                        Name of the Nominee as per Govt ID  <span>*</span>
                      </label>
                      <InputField
                        inputType="TEXT"
                        placeholder="Enter Name"
                        className="form-control w-100"
                        name="nominee_name_as_per_govt_id"
                        id="nominee_name_as_per_govt_id"
                        value={getValue(request, `nominee_name_as_per_govt_id`, "")}
                        onChange={(e: any) => handleChangeText(e)}
                        label="Enter Name"
                        validator={simpleValidator}
                      />
                    </div>
                    <div className="client-travel-profile-labelfield-wrap">
                    <label className="form-label-error">
                        Relationship with Employee <span>*</span>
                      </label>
                      {/* <InputField
                        inputType="TEXT"
                        placeholder="Enter Relationship"
                        className="form-control w-100"
                        name="relationship_with_employee"
                        id="relationship_with_employee"
                        value={getValue(request, `relationship_with_employee`, "")}
                        onChange={(e: any) => handleChangeText(e)}
                        label="Enter Relationship"
                        validator={simpleValidator}
                      /> */}
                      <SingleSelectSearchDropdown
                        label={"label"}
                        name="relationship_with_employee"
                        value={getValue(request, `relationship_with_employee`, "")}
                        onChange={(e: any) =>
                          setRequest({
                            ...request,
                            relationship_with_employee: getValue(e, `value`, ""),
                          })
                        }
                        data={relationship_with_employee_list}
                        placeholder={"Choose Relationship"}
                        selectKey={"value"}
                        width={"358px"}
                      />
                    </div>
                    <div className="client-travel-profile-labelfield-wrap">
                      <label className="form-label__14">
                        Gender <span></span>
                      </label>
                      <SingleSelectSearchDropdown
                        label={"label"}
                        name="gender"
                        value={getValue(request, `gender`, "")}
                        onChange={(e: any) =>
                          setRequest({
                            ...request,
                            gender: getValue(e, `value`, ""),
                          })
                        }
                        data={gender}
                        placeholder={"Choose Gender"}
                        selectKey={"value"}
                        width={"358px"}
                      />
                    </div>
                    <div className="client-travel-profile-labelfield-wrap  client-travel-profile-labelfield-wrap_dob">
                      <label className="form-label-error">
                        Date Of Birth <span>*</span>{" "}
                      </label>
                      <InputField
                        inputType="DATETYPE-DOB"
                        placeholder="Enter Date Of Birth"
                        className="form-control release-date-wrapper__vendor-form-control--calendar"
                        name="date_of_birth"
                        id="date_of_birth"
                        value={getValue(request, `date_of_birth`, "")}
                        selected={getValue(request, `date_of_birth`, "")}
                        onChange={(e: any) =>
                          setRequest({
                            ...request,
                            date_of_birth: e,
                          })
                        }
                        label="Date Of Birth"
                        validator={simpleValidator}
                        maxDate={new Date()}
                      />
                    </div>
                    <div className="client-travel-profile-labelfield-wrap">
                      <label className="form-label__14">
                        Mobile Number<span></span>
                      </label>
                      <InputField
                        inputType="PHONE"
                        placeholder="Enter Mobile Number"
                        className="form-control w-100"
                        name="mobile_number"
                        id="mobile_number"
                        label="Mobile Number"
                        value={getValue(request, `mobile_number`, "")}
                        onChange={(e: any) => handleChangeText(e)}
                      />
                    </div>
                    <div className="client-travel-profile-labelfield-wrap">
                      <label className="form-label-error">
                        Email Address<span>*</span>
                      </label>
                      <InputField
                        inputType="EMAIL"
                        placeholder="Enter Email Address"
                        className="form-control w-100"
                        name="email"
                        id="email"
                        value={getValue(request, `email`, "")}
                        onChange={(e: any) => handleChangeText(e)}
                        label="Email"
                        validator={simpleValidator}
                      />
                    </div>

                    {/* <div className="user-details-wrapper__form-group form-group">
                  <label className="form-label__14">
                    Redress Number<span></span>
                  </label>
                  <InputField
                    inputType="TEXT"
                    placeholder="Enter Redress Number"
                    className="form-control w-100"
                    name="redress_number"
                    id="redress_number"
                    label="redress_number"
                    value={getValue(request, `redress_number`, "")}
                    onChange={(e: any) => handleChangeText(e)}
                  />
                </div>
                <div className="user-details-wrapper__form-group form-group">
                  <label className="form-label__14">
                    Known Travel Number<span></span>
                  </label>
                  <InputField
                    inputType="TEXT"
                    placeholder="Enter Known Travel Number"
                    className="form-control w-100"
                    name="known_traveler_number"
                    id="known_traveler_number"
                    label="known_traveler_number"
                    value={getValue(request, `known_traveler_number`, "")}
                    onChange={(e: any) => handleChangeText(e)}
                  />
                </div>
                <div className="user-details-wrapper__form-group form-group">
                  <label className="form-label__14">
                    Fax<span></span>
                  </label>
                  <InputField
                    inputType="TEXT"
                    placeholder="Enter Fax"
                    className="form-control w-100"
                    name="fax"
                    id="fax"
                    label="fax"
                    value={getValue(request, `fax`, "")}
                    onChange={(e: any) => handleChangeText(e)}
                  />
                </div>
                <div className="user-details-wrapper__form-group form-group client-travel-profile-labelfield-wrap">
                  <label className="form-label__14">
                    Home Airport <span></span>
                  </label>
                  <SingleSelectSearchDropdown
                    label={"label"}
                    name="Home Airport"
                    value={getValue(request, `home_airport`, "")}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        home_airport: getValue(e, `value`, ""),
                      })
                    }
                    placeholder={"Choose Base Currency"}
                    lookup_api={"airports"}
                    width={"358px"}
                  />
                </div>

                <div className="user-details-wrapper__form-group form-group">
                  <label className="form-label__14">
                    Home Phone<span></span>
                  </label>
                  <InputField
                    inputType="PHONE"
                    placeholder="Enter Home Phone"
                    className="form-control w-100"
                    name="home_phone"
                    id="home_phone"
                    label="Home Phone"
                    value={getValue(request, `home_phone`, "")}
                    onChange={(e: any) => handleChangeText(e)}
                  />
                </div>
                <div className="user-details-wrapper__form-group form-group">
                  <label className="form-label__14">
                    Work Phone<span></span>
                  </label>
                  <InputField
                    inputType="PHONE"
                    placeholder="Enter Work Phone"
                    className="form-control w-100"
                    name="work_phone"
                    id="work_phone"
                    label="Work Phone"
                    value={getValue(request, `work_phone`, "")}
                    onChange={(e: any) => handleChangeText(e)}
                  />
                </div> */}
                  </div>
                </div>
                <div className="d-flex justify-content-center w-100">
                  <div className="border_bottom-dark  client-travel-profile-border-spacing w-100"></div>
                </div>
                <div className="checkpoint-active-details-wrapper__form-content">
                  {/* <div className="d-flex align-items-center mb-4">
                    <OrganisationsSvgComponent />
                    <h6 className="header_text color_Primary ms-2">Address</h6>
                  </div> */}

                  {/* <h3 className="client-travel-profile_main-title">Address</h3> */}
                  <div className="user-details-wrapper__form  user-details-wrapper__form--org-profile client-travel-profile-form-wrapper client-travel-profile-form-address_wrapper ">
                    {getValue(request, `addresses`, []).map(
                      (item: object, index: number) => {
                        return (
                          <div
                            className="w-100 address-home-work-wrapper"
                            key={index}
                          >
                            <h6 className="client-travel-profile_main-title">
                              Address (
                              {formatText(getValue(item, `address_type`, ""))})
                            </h6>
                            <div className="basic_information_grid">
                              <div className="user-details-wrapper__form-group form-group  client-travel-profile-labelfield-wrap">
                                <label className="form-label__14">
                                  Address 1<span></span>
                                </label>
                                <InputField
                                  inputType="TEXTAREA"
                                  placeholder="Enter Address 1"
                                  className="form-control w-100"
                                  name="address1"
                                  id="address1"
                                  label="Address 1"
                                  value={getValue(item, `address1`, "")}
                                  onChange={(e: any) =>
                                    handleTextArrayChange(
                                      index,
                                      e,
                                      "addresses",
                                      setRequest
                                    )
                                  }
                                />
                              </div>
                              <div className="user-details-wrapper__form-group form-group client-travel-profile-labelfield-wrap">
                                <label className="form-label__14">
                                  Address 2<span></span>
                                </label>
                                <InputField
                                  inputType="TEXTAREA"
                                  placeholder="Enter Address 2"
                                  className="form-control w-100"
                                  name="address2"
                                  id="address2"
                                  label="Address 2"
                                  value={getValue(item, `address2`, "")}
                                  onChange={(e: any) =>
                                    handleTextArrayChange(
                                      index,
                                      e,
                                      "addresses",
                                      setRequest
                                    )
                                  }
                                />
                              </div>
                              {/* <div className="user-details-wrapper__form-group form-group w-100 ">
                          <label className="form-label__14">
                            Country <span>*</span>
                          </label>
                          <InputField
                            inputType="INPUT_REACT_SELECT"
                            placeholder="Choose Country"
                            className="form-control w-100"
                            name="country"
                            id="country"
                            options={countryList}
                            value={
                              getValue(countryList, `length`, 0) > 0
                                ? countryList.filter(
                                    (item: object) =>
                                      getValue(item, `name`, "") ==
                                      getValue(item, `country`, "")
                                  )[0]
                                : {}
                            }
                            onChange={(e: any) =>
                              handleChangeSelect(
                                index,
                                e,
                                "country",
                                "addresses"
                              )
                            }
                            label="Country"
                            validator={simpleValidator}
                            styles={customStyles}
                            isClear
                          />
                        </div>
                        <div className="user-details-wrapper__form-group form-group w-100 ">
                          <label className="form-label__14">
                            State <span>*</span>
                          </label>
                          <InputField
                            inputType="INPUT_REACT_SELECT"
                            placeholder="Choose State"
                            className="form-control w-100"
                            name="state"
                            id="state"
                            label="Last Name"
                            options={states}
                            value={states.filter(
                              (item: object) =>
                                getValue(item, `name`, "") ==
                                getValue(request, `state`, "")
                            )}
                            onChange={(e: any) =>
                              handleChangeSelect(index, e, "state", "addresses")
                            }
                            styles={customStyles}
                            isClear
                          />
                        </div>
                        <div className="user-details-wrapper__form-group form-group w-100 ">
                          <label className="form-label__14">
                            City <span>*</span>
                          </label>
                          <InputField
                            inputType="INPUT_REACT_SELECT"
                            placeholder="Choose State"
                            className="form-control w-100"
                            name="city"
                            id="city"
                            label="City"
                            options={citiesList}
                            value={citiesList.filter(
                              (item: object) =>
                                getValue(item, `name`, "") ==
                                getValue(request, `city`, "")
                            )}
                            onChange={(e: any) =>
                              handleChangeSelect(index, e, "city", "addresses")
                            }
                            styles={customStyles}
                            isClear
                          />
                        </div> */}
                              <div className="user-details-wrapper__form-group form-group client-travel-profile-labelfield-wrap">
                                <label className="form-label__14">
                                  Pincode<span></span>
                                </label>
                                <InputField
                                  inputType="PHONE"
                                  placeholder="Enter Pincode"
                                  className="form-control w-50"
                                  name="pin_code"
                                  id="pin_code"
                                  label="Pincode"
                                  value={getValue(item, `pin_code`, "")}
                                  onChange={(e: any) =>
                                    handleTextArrayChange(
                                      index,
                                      e,
                                      "addresses",
                                      setRequest
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </DashboardLayout>
  );
}

export default SettingsTravelProfile;
