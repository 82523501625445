import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import _ from "lodash";
import StickyHeader from "./components/StickyHeader";
// import ProjectForm from "./projectForm";
import {
  createProject,
  getSpecificProject,
  updateProject,
} from "@services/projects.service";
import PolicyForm from "./components/PolicyForm";
import NavViews from "./components/NavViews";
import { mediaUpload } from "@services/upload.service";
import {
  createPolicy,
  createPolicyExpenseLimit,
  deletePolicyExpenseLimit,
  getSpecificPolicy,
  getSpecificPolicyExpenseLimit,
  updatePolicy,
  updatePolicyExpenseLimit,
} from "@services/policy.service";
import ExpenseLimitPage from "./components/ExpenseLimitPage";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getAllExpenseTypes } from "@services/expense-type.service";
import GeneralLimitsPopup from "./popup/general-limits";
import { getAllPolicyExpenseLimit } from "@services/policy.service";
import {
  formatText,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import ExpenseLimitsPopup from "./popup/expense-limits";
import DeleteModal from "@common/popup/DeleteModal";
import "./index.scss";
import MileagePage from "./components/MileagePage";
import NoAccessPage from "@components/common/NoAccess";
import GearLoader from "@components/common/GearLoader";
import CloseSmallSvgComponent from "@assets/svg-components/close-small";
import PolicySettingSvgComponent from "@assets/svg-components/policy-setting";
import PolicyLimitSvgComponent from "@assets/svg-components/policy-limit";
import PolicyMileageSvgComponent from "@assets/svg-components/policy-mileage";
import TripLimitPage from "./components/TripLimitPage";
import TripLimitSvgComponent from "@assets/svg-components/trip-limit";
import ExpenseLimitsCommentPopup from "./popup/expense-comment";
import PerDiem from "./components/PerDiem";
import RichTextEditor from "react-rte";
import base64 from "base-64";


function PolicyDetailsPage(props: any) {
  const navigate = useNavigate();
  const params = useParams();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());

  /* -------------------------------------------------------------------------- */
  /*                               UseState section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const [documents, setDocuments] = useState<any>([]);
  const [request, setRequest] = useState({
    policy_name: "",
    description: "",
    // policy_document: "",
    policy_admins: [
      // {
      //   action: "ADD",
      //   user: "",
      // },
    ],
  });
  const [request1, setRequest1] = useState<any>({
    is_general_limit: true,
    expense_amount_limit: "",
    receipt_required_limit: "",
    is_description_required: false,
    expense_type: "",
    id: "",
  });
  const [request2, setRequest2] = useState<any>({
    is_general_limit: false,
    expense_amount_limit: "",
    receipt_required_limit: "",
    is_description_required: false,
    expense_type: "",
    id: "",
  });
  const [commentRequest, setCommentRequest] = useState<any>({
    comments: "",
    expense_type: "",
    id: "",
  });

  const [comments, setComments] = useState("");

  const [mileageRequest, setMileageRequest] = useState({
    policy: "",
    mileage_expense_type: "",
    mileage_unit: "km",
  });

  const [tripRequest, setTripRequest] = useState({
    is_active: false,
    is_international: false,
    is_domestic: false,
    is_budget_limit: false,
    budget_limit: "",
    is_hard_stop: true,
  });

  const [internaltionFlightRequest, setInernationalFlightRequest] = useState({
    policy_trip_limit: "",
    travel_type: "",
    economy: false,
    premium_economy: false,
    business: false,
    first: false,
    id:"",
  });

  const [domesticFlightRequest, setDomesticFlightRequest] = useState({
    policy_trip_limit: "",
    travel_type: "",
    economy: false,
    premium_economy: false,
    business: false,
    first: false,
    id:"",
  });


  const [views] = useState([
    {
      name: "Policy Settings",
      icon: <PolicySettingSvgComponent size={24} />,
    },
    {
      name: "Expense Limits",
      icon: <PolicyLimitSvgComponent size={24} />,
    },
    {
      name: "Trips Limits",
      icon: <TripLimitSvgComponent size={24} />,
    },
    {
      name: "Mileage",
      icon: <PolicyMileageSvgComponent size={24} />,
    },
    {
      name: "Per Diem",
      icon: <PolicyMileageSvgComponent size={24} />,
    },
  ]);
  const [activeTab, setActiveTab] = useState(0);

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (props.permissionAPITriggered) {
      if (getValue(urlParams, `activeTab`, 1)) {
        setActiveTab(parseInt(getValue(urlParams, `activeTab`, 1)));
      }
    }
  }, [window.location.href, props.permissionAPITriggered]);

  useEffect(() => {
    if (props.permissionAPITriggered) {
      if (activeTab == 1 && getValue(params, `id`, "")) {
        getData();
      }
      if (activeTab == 2) {
        // if (!getValue(params, `id`, "")) {
        //   getExpenseTypeList([]);
        // }
        // if (getValue(params, `id`, "")) {
        //   getExpenseLimit();
        // }
        getExpenseLimit();
      }
      if (activeTab == 3) {
        getAllExpenseTypeList();
      }
      if (activeTab == 4) {
        getAllExpenseTypeList();
      }
      if (activeTab == 5) {
        getAllExpenseTypeListPerDiem();
      }
    }
  }, [activeTab, props.permissionAPITriggered]);
  /* -------------------------------------------------------------------------- */
  /*                                 API section                                */
  /* -------------------------------------------------------------------------- */
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getSpecificPolicy(getValue(params, `id`, ""));
      if (resp) {
        setInfo(getValue(resp, `data`, {}));
        let users =
          getValue(resp, `data.policy_admins.length`, 0) > 0
            ? getValue(resp, `data.policy_admins`, []).map((item: object) => ({
                ...item,
                user: getValue(item, `user.id`, ""),
                action: "UPDATE",
              }))
            : [];
        let obj = {
          policy_name: getValue(resp, `data.policy_name`, ""),
          description: getValue(resp, `data.description`, ""),
          policy_admins: users,
        };
        setRequest({ ...request, ...obj });
        setDocuments(
          getValue(resp, `data.policy_document`, "")
            ? [getValue(resp, `data.policy_document`, "")]
            : []
        );
        setIsLoading(false);
        // setComments(getValue(resp, `data.comments`, ""));
        // let content = base64.decode(getValue(resp, `data.comments`, ""));
        let content = getValue(resp, `data.comments`, "");
        setEditorState(RichTextEditor.createValueFromString(content, "html"));
        console.log(editorState, "234 EditorState");
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getExpenseLimit = async () => {
    try {
      let policy = getValue(urlParams, `policy_id`, "")
        ? getValue(urlParams, `policy_id`, "")
        : getValue(params, `id`, "");
      let payload = {
        page_no: 1,
        page_size: 100,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getSpecificPolicyExpenseLimit(policy, queryRequest);
      if (resp) {
        let filterGlobal = getValue(
          resp,
          `data.data`,
          []
        ).filter((item: object) => getValue(item, `is_general_limit`, false));
        let filteredExpenseTypes = getValue(resp, `data.data`, []).filter(
          (item: object) => !getValue(item, `is_general_limit`, false)
        );
        getExpenseTypeList(
          filteredExpenseTypes.map((item: object) => ({
            ...item,
            main_id: getValue(item, `id`, ""),
            expense_type_id: getValue(item, `expense_type.id`, ""),
          }))
        );
        if (getValue(filterGlobal, `length`, 0) > 0) {
          let obj = {
            is_general_limit: getValue(
              filterGlobal,
              `[${0}].is_general_limit`,
              ""
            ),
            expense_amount_limit: getValue(
              filterGlobal,
              `[${0}].expense_amount_limit`,
              ""
            ),
            receipt_required_limit: getValue(
              filterGlobal,
              `[${0}].receipt_required_limit`,
              ""
            ),
            is_description_required: getValue(
              filterGlobal,
              `[${0}].is_description_required`,
              ""
            ),
            id: getValue(filterGlobal, `[${0}].id`, ""),

            expense_type: getValue(filterGlobal, `[${0}].expense_type.id`, ""),
          };
          setRequest1({ ...request1, ...obj });
        }
      }
    } catch (error) {}
  };
  const [isLoading1, setIsLoading1] = React.useState(true);
  const [list, setList] = useState([]);
  const getExpenseTypeList = async (arr: any) => {
    try {
      let payload = {
        page_no: 1,
        page_size: 100,
        search_text: params.search_text,
      };
      let queryRequest = QueryRequestHelper(payload);
      setIsLoading1(true);
      let resp = await getAllExpenseTypes(queryRequest);
      if (resp) {
        let arr1 = getValue(resp, `data.data`, []).map((item: object) => ({
          id: getValue(item, `id`, ""),
          expense_type_name: getValue(item, `expense_type_name`, ""),
          expense_amount_limit: "",
          receipt_required_limit: "",
          is_description_required: null,
          main_id: "",
        }));
        let array = mergeArrays(arr1, arr);
        setList(array);
        // setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading1(false);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };
  function mergeArrays(arr1: any, arr2: any) {
    // Iterate over the first array
    for (let i = 0; i < arr1.length; i++) {
      const obj1 = arr1[i];

      // Find the matching object in the second array
      const obj2 = arr2.find((item: any) => item.expense_type_id === obj1.id);

      // If a match is found, append the values from the second object to the first object
      if (obj2) {
        Object.assign(obj1, obj2);
      }
    }

    return arr1;
  }

  const getAllExpenseTypeList = async () => {
    try {
      let payload = {
        page_no: 1,
        page_size: 100,
        search_text: params.search_text,
        is_mileage: true,
      };
      let queryRequest = QueryRequestHelper(payload);
      setIsLoading1(true);
      let resp = await getAllExpenseTypes(queryRequest);
      if (resp) {
        let arr1 = getValue(resp, `data.data`, []).map((item: object) => ({
          id: getValue(item, `id`, ""),
          expense_type_name: getValue(item, `expense_type_name`, ""),
          value: getValue(item, `id`, ""),
          label: formatText(getValue(item, `expense_type_name`, "")),
        }));
        setList(arr1);
        // setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading1(false);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };

  const [expenseListPerDiem, setExpenseListPerDiem] = useState([]);
  const getAllExpenseTypeListPerDiem = async () => {
    try {
      let payload = {
        page_no: 1,
        page_size: 100,
        search_text: params.search_text,
        is_per_diem: true,
      };
      let queryRequest = QueryRequestHelper(payload);
      setIsLoading1(true);
      let resp = await getAllExpenseTypes(queryRequest);
      if (resp) {
        let arr1 = getValue(resp, `data.data`, []).map((item: object) => ({
          id: getValue(item, `id`, ""),
          expense_type_name: getValue(item, `expense_type_name`, ""),
          value: getValue(item, `id`, ""),
          label: formatText(getValue(item, `expense_type_name`, "")),
        }));
        setExpenseListPerDiem(arr1);
        // setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading1(false);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                                 Submit section                             */
  /* -------------------------------------------------------------------------- */
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    //**----- Step 1 Request----------- */
    let payload = {
      ...request,
      policy_document: getValue(documents, `[${0}].id`, ""),
    };

    //**----- Step 2 Request----------- */

    let payload1 = {
      ...request1,
      policy: getValue(urlParams, `policy_id`, "")
        ? getValue(urlParams, `policy_id`, "")
        : getValue(params, `id`, ""),
    };

    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        if (getValue(params, `id`, "")) {
          if (activeTab === 1) {
            let resp = await updatePolicy(
              getValue(params, `id`, ""),
              removeNullOrUndefinedProperties(payload)
            );
            if (resp) {
              toast.success(getValue(resp, `message`, ""));
              setSubmitLoading(false);
              getData();
              // navigate(`/admin/user-controls/policies`);
            } else {
              setSubmitLoading(false);
            }
          } else {
            let resp;
            if (getValue(payload1, `id`, "")) {
              resp = await updatePolicyExpenseLimit(
                getValue(payload1, `id`, ""),
                {
                  ...removeNullOrUndefinedProperties(payload1),
                  is_general_limit: getValue(
                    payload1,
                    `is_general_limit`,
                    false
                  ),
                }
              );
            } else {
              resp = await createPolicyExpenseLimit({
                ...removeNullOrUndefinedProperties(payload1),
                is_general_limit: getValue(payload1, `is_general_limit`, false),
              });
            }
            if (resp) {
              toast.success(getValue(resp, `message`, ""));
              handleModal();
              getExpenseLimit();
              setSubmitLoading(false);
            } else {
              setSubmitLoading(false);
            }
          }
        } else {
          if (activeTab === 1) {
            let resp = await createPolicy(
              removeNullOrUndefinedProperties(payload)
            );
            if (resp) {
              toast.success(getValue(resp, `message`, ""));
              setSubmitLoading(false);
              setActiveTab(2);
              navigate(
                `/admin/user-controls/policies/add?policy_id=${getValue(
                  resp,
                  `data.id`,
                  ""
                )}&activeTab=${2}`
              );
            } else {
              setSubmitLoading(false);
            }
          } else {
            let resp = await createPolicyExpenseLimit({
              ...removeNullOrUndefinedProperties(payload1),
              is_general_limit: getValue(payload1, `is_general_limit`, false),
            });
            if (resp) {
              toast.success(getValue(resp, `message`, ""));
              setSubmitLoading(false);
              // navigate(`/admin/user-controls/policies`);
            } else {
              setSubmitLoading(false);
            }
          }
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };
  const handleSubmitExpenseType = async () => {
    if (getValue(request2, `expense_type`, "")) {
      let payload1 = {
        ...request2,
        policy: getValue(urlParams, `policy_id`, "")
          ? getValue(urlParams, `policy_id`, "")
          : getValue(params, `id`, ""),
      };
      try {
        let resp;
        if (!getValue(payload1, `id`, "")) {
          resp = await createPolicyExpenseLimit({
            ...removeNullOrUndefinedProperties(payload1),
            is_general_limit: false,
          });
        } else {
          resp = await updatePolicyExpenseLimit(getValue(payload1, `id`, ""), {
            ...removeNullOrUndefinedProperties(payload1),
            is_general_limit: false,
          });
        }
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          setSubmitLoading(false);
          getExpenseLimit();
          handleModal1();
          // navigate(`/admin/user-controls/policies`);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {}
    } else {
      toast.error("Something went wrong");
    }
  };

  
  const handleSubmitExpenseTypeComment = async () => {

    if (getValue(commentRequest, `expense_type`, "")) {
      let data = editorState.toString("html");
      let payload1 = {
        ...request2,
        // ...commentRequest,
        // comments: base64.encode(data),
        comments: data,
        id: getValue(commentRequest, `id`, ""),
        expense_type: getValue(commentRequest, `expense_type`, ""),
        policy: getValue(urlParams, `policy_id`, "")
          ? getValue(urlParams, `policy_id`, "")
          : getValue(params, `id`, ""),
      };
      try {
        let resp;
        if (!getValue(payload1, `id`, "")) {
          resp = await createPolicyExpenseLimit({
            ...removeNullOrUndefinedProperties(payload1),
            is_general_limit: false,
          });
        } else {
          resp = await updatePolicyExpenseLimit(getValue(payload1, `id`, ""), {
            ...removeNullOrUndefinedProperties(payload1),
            is_general_limit: false,
          });
        }
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          setSubmitLoading(false);
          getExpenseLimit();
          handleModalComment();
          setComments("");
          // navigate(`/admin/user-controls/policies`);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {}
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleSubmitDocument = async (
    status: boolean,
    type: string,
    amount: any,
    receipt: any,
    id: string,
    is_hard_stop: boolean,
    comments: string,
    is_proof_mandatory: boolean,
  ) => {
    let payload1 = {
      ...request2,
      id: id,
      is_receipt_mandatory: status,
      expense_type: type,
      policy: getValue(urlParams, `policy_id`, "")
        ? getValue(urlParams, `policy_id`, "")
        : getValue(params, `id`, ""),
      expense_amount_limit: amount,
      receipt_required_limit: receipt,
      is_hard_stop: is_hard_stop,
      comments: comments,
      is_proof_mandatory: is_proof_mandatory,
    };
    try {
      let resp;
      if (!getValue(payload1, `id`, "")) {
        resp = await createPolicyExpenseLimit({
          ...removeNullOrUndefinedProperties(payload1),
          is_general_limit: false,
        });
      } else {
        resp = await updatePolicyExpenseLimit(getValue(payload1, `id`, ""), {
          ...removeNullOrUndefinedProperties(payload1),
          is_general_limit: false,
        });
      }
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        setSubmitLoading(false);
        getExpenseLimit();
        // navigate(`/admin/user-controls/policies`);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                              OnChange section                              */
  /* -------------------------------------------------------------------------- */

  const [selectedIDs, setSelectedIDs] = useState<any>([]);
  const handleCheckUsers = (id: string) => {
    let userIDs =
      getValue(selectedIDs, `length`, 0) > 0
        ? selectedIDs.map((item: string) => item)
        : [];
    if (userIDs.includes(id)) {
      let filterIDs = selectedIDs.filter((item: string) => item !== id);
      setSelectedIDs(filterIDs);
    } else {
      selectedIDs.push(id);
      setSelectedIDs([...selectedIDs]);
    }
  };
  const handleCheckAll = (status: string) => {
    if (status === "All") {
      setSelectedIDs([]);
    } else {
      let userIDs =
        getValue(list, `length`, 0) > 0
          ? list.map((item: object) => getValue(item, `id`, ""))
          : [];
      setSelectedIDs(userIDs);
    }
  };
  const handleChangeActiveTab = (tab: any) => {
    let payload = {
      ...urlParams,
      activeTab: tab,
    };
    let queryRequest = QueryRequestHelper(payload);
    if (getValue(params, `id`, "")) {
      navigate(
        `/admin/user-controls/policies/detail/${getValue(
          params,
          `id`,
          ""
        )}?${queryRequest}`
      );
    } else {
      navigate(`/admin/user-controls/policies/add?${queryRequest}`);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Upload Section                                */
  /* -------------------------------------------------------------------------- */
  const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];
  const handleUploadDocuments = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      try {
        let resp = await mediaUpload(formData);
        if (resp) {
          setDocuments((prevDocuments: any) => [
            ...prevDocuments,
            getValue(resp, `data`, {}),
          ]);
          e = null;
        } else {
          e = null;
        }
      } catch (error) {
        e = null;
      }
    });
  };

  const handleRemoveDocuments = async (i: number) => {
    let list = documents.filter((item: any, index: number) => i !== index);
    setDocuments(list);
  };
  /* -------------------------------------------------------------------------- */
  /*                               Modal Section                                */
  /* -------------------------------------------------------------------------- */
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const [isOpen1, setIsOpen1] = useState(false);
  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };

  const [isOpenComment, setIsOpenComment] = useState(false);
  const handleModalComment = () => {
    setIsOpenComment(!isOpenComment);
  };
  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteEntity = (id: string, value: string) => {
    setDeleteId(id);
    setDeleteValue(value);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleDeleteFunction = async () => {
    try {
      let resp = await deletePolicyExpenseLimit(deleteId);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        getExpenseLimit();
        handleDelete();
      }
    } catch (error) {}
  };

  const [editorState, setEditorState] = useState(
    RichTextEditor.createEmptyValue()
  );
  const onEditorStateChange = (editorState: any) => {
    // console.log(editorState.toString('html'));
    setEditorState(editorState);
  };

  const reset = () => {
    setCommentRequest({
      ...commentRequest, 
      comments: "",
      expense_type: "",
      id: "",
    });
    setEditorState(RichTextEditor.createEmptyValue());
  };
  
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      <StickyHeader
        isChanged={false}
        handleSubmit={handleSubmit}
        submitLoading={submitLoading}
        id={params.id}
        route={`/admin/user-controls/policies`}
        title={`${getValue(params, `id`, "") ? "Update" : "New"}${" "}Policy`}
        hideSubmit={activeTab != 1 ? true : false}
        permissions={getValue(props, `permissions`, [])}
      />
      <div className="policy-details-wrapper">
        {/* policy-details-wrapper */}
        <div className="policy-tabs-wrapper">
          <NavViews
            views={views}
            activeTab={activeTab}
            handleChangeActiveTab={handleChangeActiveTab}
            params={params}
          />
        </div>

        {!isLoading &&
        !getValue(props, `subTabPermissionList`, []).includes(
          "admin_user_controls_policies"
        ) ? (
          <NoAccessPage />
        ) : (
          <>
            <div className="policies_mainbody w-100">
              {activeTab === 1 && (
                <PolicyForm
                  request={request}
                  setRequest={setRequest}
                  handleUploadDocuments={handleUploadDocuments}
                  fileTypes={fileTypes}
                  documents={documents}
                  setDocuments={setDocuments}
                  handleRemoveDocuments={handleRemoveDocuments}
                  simpleValidator={simpleValidator}
                  permissions={getValue(props, `permissions`, [])}
                />
              )}
              {activeTab === 2 && (
                <ExpenseLimitPage
                  request={request1}
                  setRequest={setRequest1}
                  request2={request2}
                  setRequest2={setRequest2}
                  list={list}
                  setList={setList}
                  selectedIDs={selectedIDs}
                  handleCheckUsers={handleCheckUsers}
                  handleCheckAll={handleCheckAll}
                  handleModal={handleModal}
                  params={params}
                  handleModal1={handleModal1}
                  handleDeleteEntity={handleDeleteEntity}
                  handleSubmitDocument={handleSubmitDocument}
                  permissions={getValue(props, `permissions`, [])}
                  commentRequest={commentRequest}
                  setCommentRequest={setCommentRequest}
                  handleModalComment={handleModalComment}
                  editorState={editorState}
                  setEditorState={setEditorState}
                />
              )}
              {activeTab === 3 && (
                <TripLimitPage
                  tripRequest={tripRequest}
                  setTripRequest={setTripRequest}
                  internaltionFlightRequest={internaltionFlightRequest}
                  setInernationalFlightRequest={setInernationalFlightRequest}
                  domesticFlightRequest={domesticFlightRequest}
                  setDomesticFlightRequest={setDomesticFlightRequest}
                />
              )}
              {activeTab === 4 && (
                <MileagePage
                  request={mileageRequest}
                  setRequest={setMileageRequest}
                  list={list}
                  params={params}
                  permissions={getValue(props, `permissions`, [])}
                />
              )}

              {activeTab === 5 && (
                <PerDiem
                  expenseListPerDiem={expenseListPerDiem}
                />
              )}
            </div>
            <GeneralLimitsPopup
              isOpen={isOpen}
              handleModal={handleModal}
              request={request1}
              setRequest={setRequest1}
              handleSubmit={handleSubmit}
              isLoading={submitLoading}
              params={params}
            />
            <ExpenseLimitsPopup
              isOpen={isOpen1}
              handleModal={handleModal1}
              request={request2}
              setRequest={setRequest2}
              handleSubmit={handleSubmitExpenseType}
              isLoading={submitLoading}
              params={params}
            />

            <ExpenseLimitsCommentPopup
              isOpen={isOpenComment}
              handleModal={handleModalComment}
              request={commentRequest}
              setRequest={setCommentRequest}
              handleSubmit={handleSubmitExpenseTypeComment}
              isLoading={submitLoading}
              params={params}
              comment={comments}
              setComment={setComments}
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}      
              reset={reset}
            />

            <DeleteModal
              isOpen={isDelete}
              handleModal={handleDelete}
              handleSubmit={handleDeleteFunction}
              deleteValue={deleteValue}
            />
          </>
        )}
      </div>
    </DashboardLayout>
  );
}

export default PolicyDetailsPage;
