import React, { useEffect, useState } from "react";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import { useNavigate } from "react-router-dom";
import { QueryRequestHelper } from "@common/query-request-helper";
import { deleteSpecificExpenseEntry } from "@services/expense-entry.service";
import { getValue } from "@utils/lodash";
import { toast } from "react-toastify";
import DeleteModal from "@common/popup/DeleteModal";
import PaginationPage from "@components/pagination/pagination";
import NavigationBar from "@components/NotificationBar";
import SearchHeader from "@components/common/SearchHeader";
import Loader from "@components/common/Loader";
import Notfound from "@components/Not-Found";
import { formatText } from "@common/text-helpers";
import { getAllTrips } from "@services/trips.service";
import TripsTable from "@pages/Client/Trips/table";
import NoAccessPage from "@components/common/NoAccess";
import ApprovalTripsNotfound from "@components/Not-Found/approval-trips-not-found";
import SkeletonTable from "@components/common/Skeleton/skeleton-table";

function MyViewsTripsApproval(props: any) {
  let navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  let mainQueryRequest = QueryRequestHelper(urlParams);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [isLoading, setIsLoading] = React.useState(true);
  const [list, setList] = React.useState<[]>([]);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(urlParams).length === 0 && props.permissionAPITriggered) {
      getData();
    }
  }, [props.permissionAPITriggered]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());
    if (Object.keys(urlParams).length > 0 && props.permissionAPITriggered) {
      getData();
      if (urlParams.page_no) {
        setPage_no(parseInt(urlParams.page_no));
      }
      if (urlParams.search_text) {
        setSearchInput(urlParams.search_text);
        setShowSearchTextbox(true);
      }
      if (urlParams.limit) {
        setLimit(parseInt(urlParams.limit));
      }
    }
  }, [window.location.href, props.permissionAPITriggered]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getData = async () => {
    let payload: any = {
      page_no: urlParams.page_no ? parseInt(urlParams.page_no) : 1,
      page_size: urlParams.limit ? parseInt(urlParams.limit) : limit,
      search_text: urlParams.search_text,
      sort_by: urlParams.sort_by,
      travel_mode: "flight",
      filter_type: "my_approval",
      approval_status:
        getValue(urlParams, `approval_status`, "") !== "is_pending_my_approval"
          ? getValue(urlParams, `approval_status`, "")
          : "",
      is_pending_my_approval: !getValue(urlParams, `is_pending_my_approval`, "")
        ? true
        : false,
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await getAllTrips(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.trip`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search_text, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    if (urlParams.search_text) {
      delete urlParams.search_text;
      let payload: any = { ...urlParams };
      let queryRequest = QueryRequestHelper(payload);

      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };

  const handleChangeSearch = async (e: any) => {
    setSearchInput(e.target.value);

    let payload: any = {
      ...urlParams,
      page_no: 1,
      search_text: e.target.value,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    if (!e.target.value) {
      getData();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const [selectedIDs, setSelectedIDs] = useState<any>([]);

  const handleCheckUsers = (id: string) => {
    let userIDs =
      getValue(selectedIDs, `length`, 0) > 0
        ? selectedIDs.map((item: string) => item)
        : [];
    if (userIDs.includes(id)) {
      let filterIDs = selectedIDs.filter((item: string) => item !== id);
      setSelectedIDs(filterIDs);
    } else {
      selectedIDs.push(id);
      setSelectedIDs([...selectedIDs]);
    }
  };
  const handleCheckAll = (status: string) => {
    if (status === "All") {
      setSelectedIDs([]);
    } else {
      let userIDs =
        getValue(list, `length`, 0) > 0
          ? list.map((item: object) => getValue(item, `id`, ""))
          : [];
      setSelectedIDs(userIDs);
    }
  };

  const handleNavigateURL = (key: string, value: string) => {
    if (value) {
      let payload = {
        ...urlParams,
        [key]: value,
        is_pending_my_approval: false,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    } else {
      delete urlParams[key];
      delete urlParams["is_pending_my_approval"];
      let payload = {
        ...urlParams,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      getData();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Delete section                             */
  /* -------------------------------------------------------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    setDeleteValue(value);
    setDeleteId(id);
    handleDelete();
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };

  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteSpecificExpenseEntry(deleteId);
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());
    let payload = {
      ...urlParams,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeLimit = (limit: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());
    let payload: any = {
      ...urlParams,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeActiveStatus = async (value: string) => {
    // try {
    //   let resp = await adminBulkUpdateStatus({
    //     ids: selectedIDs,
    //     is_active: value === "active" ? true : false,
    //   });
    //   if (resp) {
    //     toast.success(getValue(resp, `message`, ""));
    //     setSelectedIDs([]);
    //     getData();
    //   } else {
    //     getData();
    //   }
    // } catch (error) {}
  };
  const [check, setCheck] = useState(false);
  const handleRemoveNavItems = () => {
    setCheck(!check);
    setSelectedIDs([]);
  };
  const handleNavigateUserEntity = () => {
    if (getValue(selectedIDs, `[${0}]`, "")) {
      navigate(
        `/admin/user/entity-mapping/${getValue(selectedIDs, `[${0}]`, "")}`
      );
    } else {
      toast.error("Please select User");
    }
  };
  const handleNavigate = (id: string) => {
    navigate(`/client/approval/trips/detail/${id}?${mainQueryRequest}`);
  };

  const [filterOptions] = useState([
    // { value: "unsubmitted", label: formatText("unsubmitted") },
    // { value: "is_pending_my_approval", label: "My Approval" },
    { value: "awaiting_approval", label: formatText("awaiting_approval") },
    { value: "approved", label: formatText("approved") },
    { value: "rejected", label: formatText("rejected") },
    { value: "reimbursed", label: formatText("reimbursed") },
    // { value: "expired", label: formatText("expired") },
    // { value: "archived", label: formatText("archived") },
  ]);

  const handleChangeMyApproval = () => {
    if (getValue(urlParams, `is_pending_my_approval`, "")) {
      delete urlParams.is_pending_my_approval;
      let payload = {
        ...urlParams,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    } else {
      let payload = {
        ...urlParams,
        is_pending_my_approval: false,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    }
  };
  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes(
        "approvals_trips"
      ) ? (
        <NoAccessPage />
      )  : (
        <>
          <div className="dashboard-wrapper__header d-flex align-items-center">
            <h4 className="dashbaord-wrapper__header-title">Trips</h4>
            <div className="ms-3 d-flex align-items-center">
              <h6 className="small_text__14 color_primary mt-1">
                {" "}
                Pending My Approval
              </h6>
              <div className="choicelist-wrapper custom-switch-wrapper ms-2">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={
                      !getValue(urlParams, `is_pending_my_approval`, "")
                        ? true
                        : false
                    }
                    onChange={() => handleChangeMyApproval()}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          {getValue(selectedIDs, `length`, 0) > 0 && (
            <NavigationBar
              selectedIDs={selectedIDs}
              handleChangeActiveStatus={handleChangeActiveStatus}
              handleRemoveNavItems={handleRemoveNavItems}
              multi={
                getValue(props, `permissions`, []).includes("update")
                  ? true
                  : false
              }
              permissions={getValue(props, `permissions`, [])}
              entityMapping={
                getValue(props, `products`, []).includes("SELFCOMP")
                  ? true
                  : false
              }
              handleNavigateUserEntity={handleNavigateUserEntity}
            />
          )}
          <div className="admin-wrapper position-relative">
            <div className="admin-wrapper position-relative">
              <SearchHeader
                route={"/client/trips/add"}
                searchInput={search_text}
                showSearchTextbox={showSearchTextbox}
                toggleSearch={toggleSearch}
                toggleSearchClose={toggleSearchClose}
                handleChangeSearch={handleChangeSearch}
                reportFilter
                filterOptions={filterOptions}
                handleNavigateURL={handleNavigateURL}
                add={"false"}
                filter_key="approval_status"
              />
            </div>
          </div>
          <div
            className={
              getValue(selectedIDs, `length`, 0) > 0
                ? "datatable_main_container_notifiction"
                : "datatable_main_container"
            }
            style={{ overflowY: isLoading ? "hidden" : "auto" }}
          >
            {isLoading || !props.permissionAPITriggered ? (
              // <Loader />
              <SkeletonTable />
            ) : getValue(list, `length`, 0) > 0 ? (
              <TripsTable
                list={list}
                selectedIDs={selectedIDs}
                handleCheckUsers={handleCheckUsers}
                handleCheckAll={handleCheckAll}
                isLoading={isLoading}
                handleDeleteFun={handleDeleteFun}
                handleNavigate={handleNavigate}
              />
            ) : (
              <>
                <ApprovalTripsNotfound />
              </>
            )}
          </div>
          <div className="fixed_footer_wrapper d-flex justify-content-between">
            {list.length > 0 && (
              <img
                src="/images/building-illustration.svg"
                className="img-fluid"
              />
            )}
            {totalCount > 10 ? (
              <PaginationPage
                totalCount={totalCount}
                limit={limit}
                pageNumber={page_no}
                handleChangePagination={handleChangePagination}
                handleChangeLimit={handleChangeLimit}
              />
            ) : (
              <p className="show-rows-count mt-3 d-flex">
                Total Count:&nbsp;&nbsp;{" "}
                <p style={{ color: "rgb(13, 110, 253)" }}>{totalCount}</p>
              </p>
            )}
          </div>
          <DeleteModal
            isOpen={isDelete}
            handleModal={handleDelete}
            handleSubmit={handleDeleteFunction}
            deleteValue={deleteValue}
          />
        </>
      )}
    </DashboardLayout>
  );
}

export default MyViewsTripsApproval;
