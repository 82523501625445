import AddSvgComponent from "@assets/svg-components/add";
import CloseSvgComponent from "@assets/svg-components/close";
import DeleteSvgComponent from "@assets/svg-components/delete";
import FileLogSvgComponent from "@assets/svg-components/file-log";
import DragAndDropFileComponent from "@common/FileUpload/file-drag-drop";
import DragAndDropFileComponentModal from "@common/FileUpload/file-drag-drop-modal";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  concatString,
  convertElementaryArrayToJSONArray,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import { tripUpdateAttachmentDocuments } from "@services/trips.service";
import {
  tripAddAttachment,
  tripTravelModeKeys,
  tripUpdateAttachment,
} from "@services/trips.service";
import { mediaUpload } from "@services/upload.service";
import { getValue } from "@utils/lodash";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

function TripsUploadComponent(props: any) {
  const params = useParams();
  const { request, setRequest, reset } = props;
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const tripEnums = [
    { value: "flight", label: "Flight" },
    { value: "hotel", label: "Hotel" },
    { value: "train", label: "Train" },
    { value: "bus", label: "Bus" },
    { value: "car", label: "Car" },
  ];

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (
      getValue(request, `travel_mode`, "") &&
      getValue(tripModeKeys, `length`, 0) === 0
    ) {
      getTripKeys(getValue(request, `travel_mode`, ""));
    }
  }, [getValue(request, `travel_mode`, "")]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [tripModeKeys, setTripModeKeys] = useState([]);
  const getTripKeys = async (key: string) => {
    try {
      let payload = {
        travel_mode: key,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await tripTravelModeKeys(queryRequest);
      if (resp) {
        setTripModeKeys(
          getValue(resp, `data.keys.length`, 0) > 0
            ? convertElementaryArrayToJSONArray(getValue(resp, `data.keys`, []))
            : []
        );
      }
    } catch (error) {}
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let resp;
      if (getValue(request, `id`, "")) {
        resp = await tripUpdateAttachmentDocuments(
          getValue(request, `id`, ""),
          {
            document_ids: documents.map((item: object) =>
              getValue(item, `id`, "")
            ),
          }
        );
      } else {
        resp = await tripAddAttachment(
          getValue(params, `id`, ""),
          removeNullOrUndefinedProperties({
            id: getValue(request, `id`, ""),
            travel_mode: getValue(request, `travel_mode`, ""),
            type: getValue(request, `type`, ""),
            document_ids: documents.map((item: object) =>
              getValue(item, `id`, "")
            ),
          })
        );
      }

      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        props.handleModal();
        props.getData(false);
        reset();
        setDocuments([]);
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Upload Section                                */
  /* -------------------------------------------------------------------------- */
  const [documents, setDocuments] = useState<any>([]);
  const handleUploadDocuments = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      try {
        let resp = await mediaUpload(formData);
        if (resp) {
          // setDocuments((prevDocuments: any) => [
          //   ...prevDocuments,
          //   getValue(resp, `data`, {}),
          // ]);
          // e = null;
          setDocuments((prevDocuments: any) => {
            if (prevDocuments.length >= 5) {
              toast.error("You can only upload a maximum of 5 files.");
              return prevDocuments;
            }
            return [...prevDocuments, getValue(resp, `data`, {})];
          });
          e = null;
  
        } else {
          e = null;
        }
      } catch (error) {
        e = null;
      }
    });
  };
  const handleRemoveDocuments = (index: number) => {
    let list = documents.filter((_item: object, i: number) => index !== i);
    setDocuments(list);
  };
  const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];
  return (
    <Modal isOpen={getValue(props, `isOpen`, "")}>
      <div className="d-flex justify-content-between align-items-center px-4 pt-3 pb-3">
        <h6>Trips Upload</h6>
        <div
          onClick={() => {
            reset();
            setDocuments([]);
            props.handleModal();
          }}
        >
          <CloseSvgComponent />
        </div>
      </div>
      <div className="border-bottom"></div>
      <ModalBody>
        <div className="">
          <label className="form-label">Trip Mode</label>
          <SingleSelectSearchDropdown
            data={tripEnums}
            label={"label"}
            selectKey={"value"}
            value={getValue(request, `travel_mode`, "")}
            width={"100%"}
            placeholder="Choose Trip Mode"
            onChange={(e: object) => {
              setRequest({
                ...request,
                travel_mode: getValue(e, `value`, ""),
              });
              getTripKeys(getValue(e, `value`, ""));
            }}
            disabled={getValue(request, `id`, "") ? true : false}
          />
        </div>
        <div className="mt-3">
          <label className="form-label">Document Type</label>
          <SingleSelectSearchDropdown
            data={tripModeKeys}
            label={"label"}
            selectKey={"value"}
            value={getValue(request, `type`, "")}
            width={"100%"}
            placeholder="Choose Trip Type"
            onChange={(e: object) => {
              setRequest({
                ...request,
                type: getValue(e, `value`, ""),
              });
            }}
            disabled={getValue(request, `id`, "") ? true : false}
          />
        </div>
        <div className="mt-3">
          <DragAndDropFileComponentModal
            uploadFile={handleUploadDocuments}
            name="file"
            types={fileTypes}
            multiple
            classes="bulk-upload_drag-n-drop-wrapper position-absolute"
            fileName={getValue(props, `importedFile.name`, "")}
            setImportTedFile={props.setImportTedFile}
          />
        </div>

        {getValue(documents, `length`, 0) > 0 &&
          documents.map((item: object, index: number) => {
            return (
              <div className="d-flex align-items-center ">
                <div className="p-2 border border-radius__5 d-flex align-items-center w-100 mb-2">
                  <div>
                    <FileLogSvgComponent />
                  </div>
                  <p className="small_text__16 ms-2">
                    {concatString(getValue(item, `name`, ""), 30)}
                    {getValue(item, `extension`, "")}
                  </p>
                </div>
                <div
                  className="mx-2"
                  onClick={() => handleRemoveDocuments(index)}
                >
                  <DeleteSvgComponent />
                </div>
              </div>
            );
          })}
      </ModalBody>
      <ModalFooter>
        <div className="delete-popup-wrapper__cta-wrapper">
          <button
            onClick={() => {
              reset();
              setDocuments([]);
              props.handleModal();
            }}
            className="delete-popup-wrapper__cta-btn delete-popup-wrapper__cta-btn--secondary"
          >
            Cancel
          </button>
          <button
            className="delete-popup-wrapper__cta-btn delete-popup-wrapper__cta-btn--primary"
            onClick={handleSubmit}
            disabled={submitLoading}
          >
            {submitLoading
              ? "Please wait..."
              : getValue(request, `id`, "")
              ? "Update"
              : "Submit"}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default TripsUploadComponent;
